import { Model } from '@vuex-orm/core'
import uuid from 'uuid'
import Frage from '@/store/modules/api/models/Frage'

export default class Antwort extends Model {
  static entity = 'antworten'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      frage_id: this.uid(() => uuid()),
      massnahme_id: this.string(null).nullable(),
      kontrolle_id: this.uid(() => uuid()),
      tier_id: this.attr(null),
      json: this.attr(null),
      benutzer_erstellt_am: this.string(null).nullable(),
      benutzer_bearbeitet_am: this.string(null).nullable(),
      benutzer_synchronisiert_am: this.string(null).nullable(),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      frage: this.belongsTo(Frage, 'frage_id')
    }
  }
}
