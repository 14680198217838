<template>
  <div class="massnahmenplan">
    <b-row>
      <b-col>
        <h5>{{ messages.massnahmenplan.headline }}</h5>
        <p class="d-none d-print-block mb-0">{{ getHerdeName(kontrolle) }} / {{ kontrolle.name }} ({{ getLocalDate(kontrolle.benutzer_erstellt_am) }})</p>
        <p class="d-none d-print-block" v-if="profile && profile.company">{{ messages.settings.company }} {{ profile.company }}</p>
      </b-col>
    </b-row>

    <div class="accordion-container d-print-none">
      <b-row>
        <b-col>
          <div class="accordion">
            <b-button class="text-left mb-3" v-b-toggle.collapse-info variant="primary" block>
              {{ messages.massnahmenplan.subline }}
              <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
            </b-button>
            <b-collapse id="collapse-info" class="mb-3">
              <b-card>
                <div v-for="(description, index) in getDescriptions()" :key="index">
                  <div v-html="description.text"></div>
                  <div v-for="(image, index) in description.images" :key="index">
                    <b-img :src="image.file" fluid center></b-img>
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
    </div>

    <p v-if="massnahmenData.length === 0">{{ this.messages.global.positiveResults }}</p>

    <b-row class="massnahme-container" v-for="data in massnahmenData" :key="data.massnahme.id">
      <b-col>
        <b-button class="mt-3 text-left" block v-b-toggle="data.massnahme.id" variant="light">
          <b-badge>{{ data.kontrolpunktbereich.name }}</b-badge> {{ data.header }}
          <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
        </b-button>
        <b-collapse :id="data.massnahme.id" class="mt-2">
          <b-card class="mb-3">
            <b-card-text>
              <p v-html="data.massnahme.text"></p>

              <div v-if="data.massnahme.begruendung" class="d-print-none">
                <b-button class="my-3 text-left" block v-b-toggle="'justification-' + data.massnahme.id" variant="light">
                  {{ messages.massnahmenvorschlaege.justification }}
                  <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
                </b-button>
                <b-collapse :id="'justification-' + data.massnahme.id" class="mt-2">
                  <p v-html="data.massnahme.begruendung"></p>
                </b-collapse>
              </div>

              <p><b>{{ messages.massnahmenplan.implementationPlanning }}</b></p>
              <p>{{ messages.massnahmenplan.phase }}: {{ data.vorschlaegeData.phase }}</p>
              <p>{{ messages.massnahmenplan.notes }}: {{ data.vorschlaegeData.notizen }}</p>
              <p>{{ messages.massnahmenplan.realizationUntil }}: {{ getFormattedDate(data.vorschlaegeData.umsetzung_bis) }}</p>
              <p>{{ messages.massnahmenplan.recheckControlUntil }}: {{ getFormattedDate(data.vorschlaegeData.nachkontrolle_bis) }}</p>
              <antwort :data="getMassnahmeFrage()" :massnahmeId="data.massnahme.id" ref="editors"></antwort>
            </b-card-text>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <div class="btn-sync mb-2 text-right  mt-5">
      <b-btn size="sm" @click="send" block variant="success">
        {{ this.messages.results.save }} <b-icon icon="upload" class="ml-2"></b-icon>
      </b-btn>
    </div>
    <div v-if="massnahmenData.length > 0 && !isOnDevice()" class="btn-print mb-2 text-right mb-5">
      <b-btn size="sm" @click="print" block variant="light">
        {{ this.messages.global.print }} <b-icon icon="download"></b-icon>
      </b-btn>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters, mapState } from 'vuex'
import Info from '@/store/modules/api/models/Info'
import FrageModel from '../store/modules/api/models/Frage'
import AntwortModel from '../store/modules/api/models/Antwort'
import Antwort from '@/components/kontrolle/Antwort'
import {
  FRAGE_UUID_MASSNAHMENPLAN,
  INFO_UUID_MASSNAHMENPLAN,
  FRAGE_UUID_MASSNAHMENVORSCHLAEGE,
  STATUS_SYNC_MASSNAHMENPLAN_FINISHED
} from '@/store/utils'

export default {
  name: 'Massnahmenplan',
  mixins: [mixins],
  components: {
    Antwort
  },
  data () {
    return {
      massnahmenData: []
    }
  },
  mounted () {
    this.massnahmenData = this.getMassnahmenData()
  },
  computed: {
    /**
     * Map base state
     */
    ...mapState('api', [
      'profile'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'getMassnahmeByFrageId',
      'getUrsachenanalyseAntworten'
    ])
  },
  methods: {
    getVorschlaegeDataByMassnahmeId (massnahmeId) {
      const antwort = AntwortModel.query()
        .where('massnahme_id', massnahmeId)
        .where('frage_id', FRAGE_UUID_MASSNAHMENVORSCHLAEGE)
        .where('kontrolle_id', this.kontrolle.id)
        .first()

      if (!antwort) {
        return false
      }

      return JSON.parse(antwort.json)
    },
    /**
     * returns an array of massnahmen data objects based on the ursachenanalyse Antworten.
     * Only shows Ursachenanalyse Fragen that have Antwort "inkorrekt" whos Massnahmen fragen phase !== "0"
     * @return {Array}
     */
    getMassnahmenData () {
      const unsorted = []
      this.getUrsachenanalyseAntworten.forEach((antwort) => {
        const kontrolpunktbereich = antwort.frage.indikator

        const frageIndikatoren = JSON.parse(antwort.frage.meta_data).ind
        const indikatoren = []

        frageIndikatoren.forEach((indikatorId) => {
          if (!indikatoren.includes(indikatorId) && this.indikatorIsDangerOrWarning(indikatorId)) {
            indikatoren.push(indikatorId)
          }
        })

        const massnahme = this.getMassnahmeByFrageId(antwort.frage.id)
        if (massnahme) {
          const model = AntwortModel.query()
            .where('massnahme_id', massnahme.id)
            .where('kontrolle_id', this.kontrolle.id)
            .first()

          if (model) {
            const modelAntwort = JSON.parse(model.json)
            if (modelAntwort.phase !== '0') {
              unsorted.push({
                massnahme: massnahme,
                header: antwort.frage.text,
                vorschlaegeData: this.getVorschlaegeDataByMassnahmeId(massnahme.id),
                kontrolpunktbereich: kontrolpunktbereich,
                rang: this.getUrsachenanalyseRang(kontrolpunktbereich.id, indikatoren)
              })
            }
          }
        }
      })

      // first group by Ursachenanalyse Rang
      const sorted = unsorted.sort((a, b) => {
        return a.rang - b.rang
      })

      // then group by Kontrolpuntbereich
      const grouped = {}
      sorted.forEach((item) => {
        if (typeof grouped[item.kontrolpunktbereich.id] === 'undefined') {
          grouped[item.kontrolpunktbereich.id] = []
        }
        grouped[item.kontrolpunktbereich.id].push(item)
      })

      // then distribute the grouped data in an output array to be used in the view.
      const output = []
      Object.keys(grouped).forEach((key) => {
        const group = grouped[key]
        group.forEach((data) => {
          output.push(data)
        })
      })

      return output
    },
    /**
     * get Frage with ID FRAGE_UUID_MASSNAHMENPLAN. To avoid same json-editor form ids
     * id different forms we overwrite the Frage id with the Massnahme id
     */
    getMassnahmeFrage () {
      const frage = FrageModel.query().whereId(FRAGE_UUID_MASSNAHMENPLAN).first()
      if (!frage) {
        return false
      }
      return frage
    },
    /**
     * Get info descriptions
     * @return {Array|null}
     */
    getDescriptions () {
      const info = Info.query().whereId(INFO_UUID_MASSNAHMENPLAN).first()
      return (info && info.json) ? this.parseAndMapDescriptions(info.json) : null
    },
    /**
     * Send data to server via vuex api module action
     */
    send () {
      if (this.isValid()) {
        this.sendDataToServer(
          {
            kontrolle: this.kontrolle
          },
          STATUS_SYNC_MASSNAHMENPLAN_FINISHED
        )
      }
    },
    isValid () {
      let output = true
      const refEditors = this.$refs['editors']
      for (const ref in refEditors) {
        if (refEditors.hasOwnProperty(ref)) {
          const editor = refEditors[ref]
          if (!editor.jsonEditor) {
            console.log('editor not initialized jet')
            output = false
            break
          }
          if (!editor.isValid()) {
            console.log('editor is not valid')
            output = false
            break
          }
        }
      }
      console.log('isValid', output)
      return output
    }
  }
}
</script>
