<template>
  <b-jumbotron :header="messages.controlInfo.header"
               v-show="showInfo && showInfoAgain"
               class="info"
               fluid>

    <ul>
      <li> {{ messages.controlInfo.recommend }}</li>
      <li> {{ messages.controlInfo.indikator }}</li>
      <li> {{ messages.controlInfo.info }}</li>
    </ul>

    <p class="px-5">
      {{ messages.controlInfo.lead }}
    </p>

    <ul class="blue-bg">
      <li v-for="(text, index) in list" :key="index">
        {{ text }}
      </li>
    </ul>

    <b-form-checkbox v-model="remember"
                     :value="true"
                     :unchecked-value="false"
                     class="m-4 px-5">
      {{ messages.controlInfo.remember }}
    </b-form-checkbox>

    <p class="px-5 text-center">
      <b-btn @click="startControl"
             variant="primary"
             block>
        {{ messages.controlInfo.startControl }}
      </b-btn>
    </p>
  </b-jumbotron>
</template>

<script>
import { SET_INFO_STATE } from '@/store/modules/api/mutation-types'
import mixins from '@/mixins'

export default {
  name: 'Info',
  mixins: [mixins],
  data () {
    return {
      remember: false,
      showInfo: true
    }
  },
  computed: {
    showInfoAgain () {
      return this.$store.state.api.showInfoAgain !== false
    },
    list () {
      return this.messages.controlInfo.list.split(',')
    }
  },
  methods: {
    /**
     * Start control event handler
     * @desc
     * - Set "showInfo" state
     * - commit "SET_INFO_STATE" mutation if "remember" property is true
     * - emit "start-control" event
     */
    startControl () {
      this.showInfo = false
      if (this.remember) {
        this.$store.commit(`api/${SET_INFO_STATE}`, this.showInfo)
      }
      this.$emit('start-control')
    }
  }
}
</script>
