import Vue from 'vue'
import Vuex from 'vuex'

import VuexORM from '@vuex-orm/core'
import database from '@/store/modules/api/database'

import mutations from './mutations'
import actions from './actions'

import api from './modules/api'
import help from './modules/help'

import Configuration from '../configuration'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [VuexORM.install(database, { namespace: 'database' })],
  state: {
    appMode: Configuration.value('appMode'),
    versionCode: process.env.VUE_APP_BUILD,
    versionNumber: process.env.VUE_APP_VERSION,
    connectionType: null,
    connectionState: 'ok'
  },
  mutations: mutations,
  actions: actions,
  getters: {},
  modules: {
    api,
    help
  }
})
