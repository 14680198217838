<template>
  <div>
    <b-btn size="sm"
           @click="startTimer(modalId)"
           class="d-flex align-items-center px-1 py-0">
      <b-icon class="mr-1"
              font-scale="0.9"
              icon="clock-history">
      </b-icon>
      {{ label }}
    </b-btn>
    <timer-modal :id="modalId"
                 :title="modalTitle"
                 :desc="modalDesc"
                 :duration="15"/>
  </div>
</template>

<script>
import TimerModal from '@/components/kontrolle/TimerModal'
import { FRAGE_UUID_HITZESTRESS, FRAGE_UUID_WASSERVERSORGUNG } from '@/store/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'TimerButton',
  components: {
    TimerModal
  },
  props: {
    label: {
      type: String,
      default: 'Start Timer'
    },
    frage: {
      type: Object
    }
  },
  computed: {
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * Modal id
     * @return {string}
     */
    modalId () {
      return 'modal-' + this.frage.id
    },
    /**
     * Modal title
     * @return {string}
     */
    modalTitle () {
      switch (this.frage.id) {
        case FRAGE_UUID_WASSERVERSORGUNG:
          return this.messages.timer.wasserversorgung
        case FRAGE_UUID_HITZESTRESS:
          return this.messages.timer.hitzestress
        default:
          return 'Timer'
      }
    },
    /**
     * Modal description
     * @return {string}
     */
    modalDesc () {
      switch (this.frage.id) {
        case FRAGE_UUID_HITZESTRESS:
          return 'Kuh'
        default:
          return ''
      }
    }
  },
  methods: {
    /**
     * Open timer modal
     * @param id
     */
    startTimer (id) {
      this.$bvModal.show(id)
    }
  }
}
</script>
