<template>
  <b-form @submit.prevent="onLogin">
    <b-form-group id="login-input-group-1">
      <legend class="col-form-label pt-0">
        {{ messages.forms.email }}:
      </legend>
      <b-form-input v-model="loginForm.email"
                    type="email"
                    :state="validateState('email')"
                    trim>
      </b-form-input>
      <b-form-invalid-feedback>
        {{ messages.forms.requiredEmail }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="login-input-group-2">
      <legend class="col-form-label pt-0">
        {{ messages.forms.password }}: <small>(<b-btn @click="goToUserForgot" variant="link" size="sm" class="px-0">{{ messages.forms.userForgot }}</b-btn>)</small>
      </legend>
      <b-form-input v-model="loginForm.password"
                    type="password"
                    :state="validateState('password')"
                    trim>
      </b-form-input>
      <b-form-invalid-feedback>
        {{ messages.forms.required }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="login-input-group-3">
      <b-form-checkbox :value="true"
                       :unchecked-value="false"
                       v-model="stayLoggedIn">
        {{ messages.forms.stayLoggedIn }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-invalid-feedback class="mb-3 auth-error" :state="!authError">
      {{ authError }}
    </b-form-invalid-feedback>

    <b-button type="submit" variant="primary" block>
      {{ messages.forms.login }}
    </b-button>

    <small class="d-block mt-2">
      <b-btn @click="goToUserResend" variant="link" size="sm" class="px-0">{{ messages.forms.userResend }}</b-btn>
    </small>

  </b-form>
</template>

<script>
import mixins from '@/mixins'

import { email, required } from 'vuelidate/lib/validators'

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginForm',
  mixins: [mixins],
  data () {
    return {
      loginForm: {
        email: null,
        password: null
      }
    }
  },
  validations: {
    loginForm: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    /**
     * Map api state
     */
    ...mapState('api', [
      'isLoggedIn',
      'authError'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'userForgotUrl',
      'userResendUrl'
    ]),
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * Login state
     */
    stayLoggedIn: {
      get () {
        return this.$store.state.api.stayLoggedIn
      },
      set (value) {
        this.$store.commit('api/SET_STAY_LOGGED_IN_STATE', value)
      }
    }
  },
  methods: {
    /**
     * Map api actions
     */
    ...mapActions('api', [
      'login',
      'getProfile'
    ]),
    /**
     * Login handler
     */
    onLogin () {
      this.$v.loginForm.$touch()
      if (this.$v.loginForm.$anyError) {
        return
      }

      this.login(this.loginForm)
        .then(response => {
          if (response && this.isLoggedIn) {
            this.getProfile()
              .then((profile) => {
                return this.fetchUserData()
              })
              .then(() => {
                this.$router.push('/kontrollen')
              })
          }
        })
    },
    /**
     * Validation handler
     * @param attr
     * @return {boolean|null}
     */
    validateState (attr) {
      const { $dirty, $error } = this.$v.loginForm[attr]
      return $dirty ? !$error : null
    },
    /**
     * Open user forgot password page in system browser
     */
    goToUserForgot () {
      this.openInAppBrowser(this.userForgotUrl, '_system')
    },
    /**
     * Open user resend page in system browser
     */
    goToUserResend () {
      this.openInAppBrowser(this.userResendUrl, '_system')
    }
  }
}
</script>
