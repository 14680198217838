<template>
  <div class="login">
    <b-row>
      <b-col cols="12">
        <img class="logo" src="../assets/logo.svg" alt="">
      </b-col>
    </b-row>

    <b-card no-body>
      <b-tabs active-nav-item-class="font-weight-bold"
              active-tab-class="text-default"
              content-class="mt-3 p-5"
              v-model="tabIndex"
              justified>
        <b-tab title="Login" card>
          <login-form/>
        </b-tab>
        <b-tab title="Registrierung" card>
          <register-form/>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-row v-if="isAppMode">
      <b-col cols="12">
        <b-btn variant="link" @click="goto" block>
          {{ messages.login.withoutRegister }}
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoginForm from '@/components/login/LoginForm'
import RegisterForm from '@/components/login/RegisterForm'
import mixins from '@/mixins'

export default {
  name: 'Login',
  mixins: [mixins],
  components: {
    LoginForm,
    RegisterForm
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  mounted () {
    this.setFocus('#login-input-group-1 input')
  },
  watch: {
    tabIndex (value) {
      const selector = (value) ? '#register-input-group-1 input' : '#login-input-group-1 input'
      this.setFocus(selector)
    }
  },
  methods: {
    /**
     * Open confirm for unregistered users
     */
    goto () {
      this.showConfirmModal(this.messages.login.confirmTitle, this.messages.login.confirmText, this.messages.login.confirmOk, this.messages.login.confirmRegister)
        .then(response => {
          if (response) {
            this.$router.push('/kontrollen')
          } else {
            this.tabIndex = 1
            setTimeout(() => {
              document.querySelector('.card').scrollIntoView({ block: 'start', behavior: 'smooth' })
            }, 100)
          }
        })
    },
    /**
     * Set focus by selector param
     * @param selector
     */
    setFocus (selector) {
      setTimeout(() => {
        const element = document.querySelector(selector)
        element.focus()
      }, 100)
    }
  }
}
</script>
