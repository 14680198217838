<template>
  <div class="herde">
    <b-row v-if="kontrolle">
      <b-col class="pt-3">
        <b-form-group :label="messages.herde.type" class="type">
          <b-form-radio v-for="type in types"
                        :key="type.value"
                        v-model="kontrolle.typ"
                        :value="type.value"
                        @change="updateKontrolle"
                        :disabled="type.disabled"
                        name="type">
            <h6>{{ type.headline }}</h6>
            <p v-html="type.text"></p>
            <small class="text-danger" v-if="type.disabled">{{ messages.herde.register }}</small>
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h3>{{ messages.herde.headline }}</h3>
        <p>{{ messages.herde.subline }}</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-3">
        <h6 class="d-inline" v-if="model">{{ messages.herde.label }} {{ model.name }}</h6>
        <b-btn size="sm"
               class="ml-2"
               variant="outline-primary"
               @click="openHerdeModal">
          {{ (model) ? messages.herde.change : messages.herde.addNew }}
        </b-btn>
        <br>
        <i class="small" v-if="model">{{ messages.herde.hint }}</i>
      </b-col>
    </b-row>

    <herde-modal @save-preset="saveHerdePreset" @set-preset="setHerdePreset" />

    <b-row v-if="model">
      <b-col class="mt-3">
        <b-form-group class="systems">
          <h6 class="d-inline">{{ messages.herde.system }}</h6>
          <i class="small float-right">{{ messages.herde.systemHint }}</i>
          <b-form-radio-group v-model="model.haltungssystem"
                              :options="systems"
                              button-variant="outline-primary"
                              buttons>
          </b-form-radio-group>
          <b-form-invalid-feedback :state="$v.model.haltungssystem.required">
            {{ messages.herde.systemRequiredError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="model">
      <b-col cols="12" class="mb-3">
        <b-form-checkbox :value="1"
                         :unchecked-value="0"
                         v-model="model.leistungsgruppe">
          {{ messages.herde.leistungsgruppe }}
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row v-if="model">
      <b-col cols="6" class="mb-3">
        <label for="gesamt">
          {{ messages.herde.gesamt }}
          <b-btn variant="link" @click="openInfoModal(gesamtInfoUuid)">
            <i class="icon-circle-info"></i>
          </b-btn>
        </label>
        <b-form-input id="gesamt"
                      v-model.number="model.gesamt"
                      @blur="updateStichprobe"
                      type="number">
        </b-form-input>
        <b-form-invalid-feedback :state="$v.model.gesamt.required">
          {{ messages.herde.gesamtError }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.model.gesamt.minValue">
          {{ messages.herde.gesamtError }}
        </b-form-invalid-feedback>
      </b-col>
      <b-col cols="6" class="mb-3">
        <label for="laktierend">
          {{ messages.herde.laktierend }}
          <b-btn variant="link" @click="openInfoModal(laktierendInfoUuid)">
            <i class="icon-circle-info"></i>
          </b-btn>
        </label>
        <b-form-input id="laktierend"
                      v-model.number="model.laktierend"
                      @blur="updateStichprobe"
                      type="number">
        </b-form-input>
        <b-form-invalid-feedback :state="$v.model.laktierend.required" >
          {{ messages.herde.laktierendRequiredError }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.model.laktierend.maxValue" >
          {{ messages.herde.laktierendMaxError }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.model.laktierend.minValue" >
          {{ messages.herde.laktierendMinError }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row v-if="showFokusgruppe">
      <b-col cols="6" class="mb-3">
        <label for="fokusgruppe">
          {{ messages.herde.fokusgruppe }}
          <b-btn variant="link" @click="openInfoModal(fokusgruppeInfoUuid)">
            <i class="icon-circle-info"></i>
          </b-btn>
        </label>
        <b-form-input id="fokusgruppe"
                      v-model.number="model.fokusgruppe"
                      @blur="updateStichprobe"
                      type="number">
        </b-form-input>
        <b-form-invalid-feedback :state="$v.model.fokusgruppe.required">
          {{ messages.herde.fokusgruppeRequiredError }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.model.fokusgruppe.maxValue">
          {{ messages.herde.fokusgruppeMaxError }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.model.fokusgruppe.minValue" >
          {{ messages.herde.fokusgruppeMinError }}
        </b-form-invalid-feedback>
      </b-col>
      <b-col cols="6" class="mb-3">
        <label for="name">
          {{ messages.herde.fokusgruppeName }}
          <b-btn variant="link" @click="openInfoModal(fokusgruppeNameInfoUuid)">
            <i class="icon-circle-info"></i>
          </b-btn>
        </label>
        <b-form-input id="name"
                      v-model="model.fokusgruppe_name"
                      type="text">
        </b-form-input>
        <b-form-invalid-feedback :state="$v.model.fokusgruppe_name.required">
          {{ messages.herde.fokusgruppeNameError }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row v-if="model">
      <b-col cols="6" class="mb-3">
        <label for="stichprobe">
          {{ messages.herde.stichprobe }}
          <b-btn variant="link" @click="openInfoModal(stichprobeInfoUuid)">
            <i class="icon-circle-info"></i>
          </b-btn>
        </label>
        <b-form-input id="stichprobe"
                      v-model.number="model.stichprobe"
                      type="number"
                      readonly>
        </b-form-input>
        <i class="small text-primary">{{ messages.herde.calcHint }}</i>
      </b-col>
    </b-row>

    <b-row v-if="model">
      <b-col cols="12">
        <b-form-checkbox :value="1"
                         :unchecked-value="0"
                         v-model="model.horntragend">
          {{ messages.herde.horntragend }}
        </b-form-checkbox>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Herde from '@/store/modules/api/models/Herde'
import HerdeModal from '@/components/kontrolle/HerdeModal'
import { mapGetters } from 'vuex'
import mixins from '@/mixins'
import uuid from 'uuid'

import {
  CONTROL_TYPE_FLEXIBLE,
  CONTROL_TYPE_STATIC,
  HALTUNGSSYSTEM_ANBINDESTALL,
  HALTUNGSSYSTEM_LAUFSTALL_FREIE_LIEGEFLAECHE,
  HALTUNGSSYSTEM_LAUFSTALL_LIEGEBOXEN,
  INFO_UUID_GROESSE_STICHPROBE,
  INFO_UUID_HERDENDATEN_FOKUSGRUPPE,
  INFO_UUID_HERDENDATEN_LAKTIERENDE_TIERE,
  INFO_UUID_HERDENDATEN_MILCHKUEHE_GESAMT,
  INFO_UUID_NAME_DER_FOKUSGRUPPE
} from '@/store/utils'

import {
  integer,
  maxValue,
  minValue,
  required,
  requiredIf
} from 'vuelidate/lib/validators'

export default {
  name: 'Herde',
  mixins: [mixins],
  props: {
    data: {
      type: Object
    }
  },
  components: {
    HerdeModal
  },
  data () {
    return {
      model: null,
      gesamtInfoUuid: INFO_UUID_HERDENDATEN_MILCHKUEHE_GESAMT,
      laktierendInfoUuid: INFO_UUID_HERDENDATEN_LAKTIERENDE_TIERE,
      fokusgruppeInfoUuid: INFO_UUID_HERDENDATEN_FOKUSGRUPPE,
      fokusgruppeNameInfoUuid: INFO_UUID_NAME_DER_FOKUSGRUPPE,
      stichprobeInfoUuid: INFO_UUID_GROESSE_STICHPROBE
    }
  },
  validations () {
    return {
      model: {
        name: {
          required
        },
        haltungssystem: {
          required
        },
        gesamt: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(9999),
          integer
        },
        laktierend: {
          required: requiredIf(() => {
            return (this.model && this.model.leistungsgruppe) || true
          }),
          minValue: minValue(1),
          maxValue: (value, vm) => {
            return (vm && value <= vm.gesamt)
          },
          integer
        },
        fokusgruppe: {
          required: requiredIf(() => {
            return this.showFokusgruppe
          }),
          minValue: (value) => {
            return !this.showFokusgruppe || value !== 0
          },
          maxValue: (value, vm) => {
            return (vm && value <= vm.gesamt)
          },
          integer
        },
        fokusgruppe_name: {
          required: requiredIf(() => {
            return this.showFokusgruppe
          })
        }
      }
    }
  },
  created () {
    this.$watch(vm => [vm.kontrolle, vm.model], this.updateStichprobe, { deep: true })
  },
  deactivated () {
    if (this.isValid()) {
      this.dispatch()
    }
  },
  computed: {
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'isAuthenticated'
    ]),
    /**
     * Herd management systems => "Haltungssystem"
     * @return {({text: string, state: boolean, value: string}|{text: string, state: boolean, value: string}|{text: string, state: boolean, value: string})[]}
     */
    systems () {
      return [
        { value: HALTUNGSSYSTEM_LAUFSTALL_LIEGEBOXEN, text: this.messages.herde.systemBox, state: false },
        { value: HALTUNGSSYSTEM_LAUFSTALL_FREIE_LIEGEFLAECHE, text: this.messages.herde.systemFree, state: false },
        { value: HALTUNGSSYSTEM_ANBINDESTALL, text: this.messages.herde.systemLashed, state: false }
      ]
    },
    /**
     * Control types
     * @desc
     * - "Eigenkontrolle mit Tierschutzindikatoren" => CONTROL_TYPE_STATIC
     * - "Eigenkontrolle mit Ursachenanalyse" => CONTROL_TYPE_FLEXIBLE
     * @return {({text: string, value: string, headline: string}|{text: string, value: string, headline: string})[]}
     */
    types () {
      return [
        { value: CONTROL_TYPE_STATIC, headline: this.messages.herde.typeStaticHeadline, text: this.messages.herde.typeStatic },
        { value: CONTROL_TYPE_FLEXIBLE, headline: this.messages.herde.typeFlexibleHeadline, text: this.messages.herde.typeFlexible, disabled: !this.isAuthenticated }
      ]
    },
    /**
     * Show "Fokusgruppe" inputs
     * @return {boolean}
     */
    showFokusgruppe () {
      if (!this.model || !this.kontrolle) {
        return false
      }

      return (this.model.leistungsgruppe && this.kontrolle.typ !== CONTROL_TYPE_STATIC)
    }
  },
  methods: {
    /**
     * Dispatch Herde & Kontrolle updates
     */
    dispatch () {
      if (!this.model || !this.kontrolle) {
        return false
      }

      this.$store.dispatch('api/insertOrUpdateHerdePreset', this.model)

      const model = new Herde(this.model)
      model.id = uuid()

      this.$store.dispatch('api/insertOrUpdateHerde', model)

      this.kontrolle.herde_id = model.id
      this.$store.dispatch('api/updateKontrolle', this.kontrolle)
    },
    /**
     * On form input blur & change handler
     */
    updateStichprobe () {
      if (!this.kontrolle || !this.model) {
        return false
      }
      this.isValid()
      this.model.stichprobe = this.getStichprobe()
    },
    /**
     * Updated kontrolle if typ was changed
     */
    updateKontrolle () {
      this.$store.dispatch('api/updateKontrolle', this.kontrolle)
    },
    /**
     * Die Stichprobe ist der Teil der Tiere, an denen die Erhebung durchgeführt wird.
     * Wie groß die Stichprobe ist, hängt von der Herdengröße ab und ob es eine "Eigenkontrolle mit Tierschutzindikatoren" oder "Eigenkontrolle mit Ursachenanalyse" ist.
     * - Bis 30 Kühe: alle Kühe
     * - Zwischen 30 - 100 Kühe: 30 Kühe
     * - Über 100 Kühe: 30% der Herde
     * @return {Number}
     */
    getStichprobe () {
      if (this.model.gesamt <= 30) {
        if (this.kontrolle.typ === CONTROL_TYPE_FLEXIBLE) {
          return (this.showFokusgruppe)
            ? this.model.fokusgruppe
            : this.model.laktierend
        }
        return this.model.gesamt
      }

      const gesamt = (this.showFokusgruppe)
        ? this.model.fokusgruppe
        : (this.kontrolle.typ === CONTROL_TYPE_STATIC) ? this.model.gesamt : this.model.laktierend

      if (gesamt > 30 && gesamt <= 100) {
        return 30
      } else if (gesamt > 100) {
        return Math.round(gesamt * 0.3)
      }

      return gesamt
    },
    /**
     * Is view valid
     * @return {boolean}
     */
    isValid () {
      this.$v.$touch()
      // console.log(JSON.stringify(this.$v.model, null, 2))
      return !this.$v.$error
    },
    /**
     * Save "HerdePreset" event handler
     * @desc Save the selected "HerdePreset" model
     */
    saveHerdePreset (herde) {
      this.model = herde
      this.$store.dispatch('api/insertOrUpdateHerdePreset', herde)
      this.$bvModal.hide('herde-modal')
    },
    /**
     * Set "HerdePreset" event handler
     * @desc Set the selected "HerdePreset" as new "Herde"
     */
    setHerdePreset (herde) {
      this.model = herde
    },
    /**
     * Open "Herde" modal
     */
    openHerdeModal () {
      this.$bvModal.show('herde-modal')
    }
  }
}
</script>
