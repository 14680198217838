<template>
  <div class="square" :class="'bg-' + variant"></div>
</template>

<script>
export default {
  name: 'Square',
  props: {
    variant: {
      type: String,
      default: 'success'
    }
  }
}
</script>
