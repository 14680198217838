import { Model } from '@vuex-orm/core'
import uuid from 'uuid'
import Antwort from '@/store/modules/api/models/Antwort'

export default class Tier extends Model {
  static entity = 'tiere'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      name: this.string(''),
      kontrolle_id: this.string(''),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      antworten: this.hasMany(Antwort, 'tier_id')
    }
  }
}
