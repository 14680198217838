<template>
  <b-nav :justified="true"
         class="navigation fixed-bottom"
         v-if="showNav">
    <b-nav-item :to="route.path"
                v-for="route in routes"
                :key="route.name"
                :exact="route.path === '/'"
                active-class="active"
                v-show="isPublicRoute(route.name)">
      <span :class="route.meta.icon"></span>
    </b-nav-item>
  </b-nav>
</template>

<script>
import { mapGetters } from 'vuex'
import mixins from '@/mixins'

export default {
  name: 'Navigation',
  mixins: [mixins],
  data () {
    return {}
  },
  computed: {
    ...mapGetters('api', [
      'isPublicRoute',
      'isAuthenticated'
    ]),
    routes () {
      return this.$router.options.routes.filter(route =>
        route.name === 'kontrollen' ||
        route.name === 'ergebnisse' ||
        (route.name === 'massnahmenplan' && this.isAuthenticated) ||
        (route.name === 'ursachenanalyse' && this.isAuthenticated) ||
        (route.name === 'produktionssystem' && this.isAuthenticated && this.isDesktopMode) ||
        (route.name === 'massnahmenvorschlaege' && this.isAuthenticated && this.isDesktopMode)
      )
    },
    showNav () {
      return (this.isDesktopMode && this.$route.name !== 'login') ||
             (!this.isDesktopMode && this.$route.name !== 'login' && this.$route.name !== 'kontrolle')
    }
  }
}
</script>
