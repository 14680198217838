import dotenv from 'dotenv'

dotenv.config()

/**
 * App mode constants
 * @type {string}
 */
export const APP_MODE_APP = 'app'
export const APP_MODE_DESKTOP = 'desktop'

export default class Configuration {
  static get CONFIG () {
    return {
      appMode: '$VUE_APP_APP_MODE',
      apiBaseUrl: '$VUE_APP_API_BASE_URL',
      fileflyApiEndpoint: '$VUE_APP_FILEFLY_API_ENDPOINT',
      privacyPolicyUrl: '$VUE_APP_PRIVACY_POLICY_URL',
      userForgotUrl: '$VUE_APP_USER_FORGOT_URL',
      userResendUrl: '$VUE_APP_USER_RESEND_URL',
      userProfileUrl: '$VUE_APP_USER_PROFILE_URL'
    }
  }

  static value (name) {
    if (!(name in this.CONFIG)) {
      console.log(`Configuration: There is no key named "${name}"`)
      return
    }

    const value = this.CONFIG[name]

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`)
      return
    }

    if (value.startsWith('$VUE_APP_')) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1)
      const envValue = process.env[envName]
      if (envValue) {
        // console.log(`Configuration: Value for "${envName}" is "${envValue}`)
        return envValue
      } else {
        console.log(`Configuration: Environment variable "${envName}" is not defined`)
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value
    }
  }
}
