<template>
  <div class="massnahmenvorschlaege">
    <b-row>
      <b-col>
        <h5>{{ messages.massnahmenvorschlaege.headline }}</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="accordion">
          <b-button class="text-left mb-3" v-b-toggle.collapse-info variant="primary" block>
            {{ messages.massnahmenvorschlaege.subline }}
            <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
          </b-button>
          <b-collapse id="collapse-info" class="mb-3">
            <b-card>
              <div v-for="(description, index) in getDescriptions()" :key="index">
                <div v-html="description.text"></div>
                <div v-for="(image, index) in description.images" :key="index">
                  <b-img :src="image.file" fluid center></b-img>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </b-col>
    </b-row>

    <p v-if="massnahmenData.length === 0">{{ this.messages.global.positiveResults }}</p>

    <b-row v-for="data in massnahmenData" :key="data.massnahme.id">
      <b-col>
        <b-button class="mt-3 text-left" block v-b-toggle="data.massnahme.id" variant="light">
          <b-badge>{{ data.kontrolpunktbereich.name }}</b-badge> {{ data.header }}
          <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
        </b-button>
        <b-collapse :id="data.massnahme.id" class="mt-2">
          <b-card>
            <b-card-text>
              <p v-html="data.massnahme.text"></p>
              <div v-if="data.massnahme.begruendung">
                <b-button class="my-3 text-left" block v-b-toggle="'justification-' + data.massnahme.id" variant="light">
                  {{ messages.massnahmenvorschlaege.justification }}
                  <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
                </b-button>
                <b-collapse :id="'justification-' + data.massnahme.id" class="mt-2">
                  <p v-html="data.massnahme.begruendung"></p>
                </b-collapse>
              </div>
              <p class="mt-2"><b>{{ messages.massnahmenvorschlaege.planning }}</b></p>
              <antwort :data="getMassnahmeFrage()" :massnahmeId="data.massnahme.id" :disabled="isSynced" ref="editors"></antwort>
            </b-card-text>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <b-btn size="sm" @click="send" v-if="showSyncButton" class="my-5" block variant="success">
          {{ this.messages.results.transferToServer }} <b-icon icon="upload" class="ml-2"></b-icon>
        </b-btn>
        <b-btn size="sm" @click="next" v-if="!showSyncButton" class="my-5" block>
          {{ this.messages.results.nextStep }} <b-icon icon="arrow-right"></b-icon>
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters } from 'vuex'
import Info from '@/store/modules/api/models/Info'
import FrageModel from '../store/modules/api/models/Frage'
import Antwort from '@/components/kontrolle/Antwort'
import {
  FRAGE_UUID_MASSNAHMENVORSCHLAEGE,
  INFO_UUID_MASSNAHMENVORSCHLAEGE,
  STATUS_SYNC_MASSNAHMENVORSCHLAEGE_FINISHED
} from '@/store/utils'

export default {
  name: 'Massnahmenplan',
  mixins: [mixins],
  components: {
    Antwort
  },
  data () {
    return {
      massnahmenData: []
    }
  },
  mounted () {
    this.massnahmenData = this.getMassnahmenData()
  },
  computed: {
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'getMassnahmeByFrageId',
      'getUrsachenanalyseAntworten'
    ]),
    /**
     * Show / hide sync button
     * @return {boolean}
     */
    isSynced () {
      return (this.kontrolle.status_sync >= STATUS_SYNC_MASSNAHMENVORSCHLAEGE_FINISHED)
    },
    showSyncButton () {
      return !this.isSynced
    }
  },
  methods: {
    /**
     * returns an array of massnahmen data objects based on the ursachenanalyse Antworten that have Antwort "inkorrekt".
     * @return {Array}
     */
    getMassnahmenData () {
      const unsorted = []
      this.getUrsachenanalyseAntworten.forEach((antwort) => {
        const kontrolpunktbereich = antwort.frage.indikator

        const frageIndikatoren = JSON.parse(antwort.frage.meta_data).ind
        const indikatoren = []

        frageIndikatoren.forEach((indikatorId) => {
          if (!indikatoren.includes(indikatorId) && this.indikatorIsDangerOrWarning(indikatorId)) {
            indikatoren.push(indikatorId)
          }
        })

        const massnahme = this.getMassnahmeByFrageId(antwort.frage.id)
        if (massnahme) {
          unsorted.push({
            massnahme: massnahme,
            header: antwort.frage.text,
            kontrolpunktbereich: kontrolpunktbereich,
            rang: this.getUrsachenanalyseRang(kontrolpunktbereich.id, indikatoren)
          })
        }
      })

      // first group by Ursachenanalyse Rang
      const sorted = unsorted.sort((a, b) => {
        return a.rang - b.rang
      })

      // then group by Kontrolpuntbereich
      const grouped = {}
      sorted.forEach((item) => {
        if (typeof grouped[item.kontrolpunktbereich.id] === 'undefined') {
          grouped[item.kontrolpunktbereich.id] = []
        }
        grouped[item.kontrolpunktbereich.id].push(item)
      })

      // then distribute the grouped data in an output array to be used in the view.
      const output = []
      Object.keys(grouped).forEach((key) => {
        const group = grouped[key]
        group.forEach((data) => {
          output.push(data)
        })
      })

      return output
    },
    getMassnahmeFrage () {
      const frage = FrageModel.query().whereId(FRAGE_UUID_MASSNAHMENVORSCHLAEGE).first()
      if (!frage) {
        return false
      }
      return frage
    },
    /**
     * Get info descriptions
     * @return {Array|null}
     */
    getDescriptions () {
      const info = Info.query().whereId(INFO_UUID_MASSNAHMENVORSCHLAEGE).first()
      return (info && info.json) ? this.parseAndMapDescriptions(info.json) : null
    },
    /**
     * Send data to server via vuex api module action
     */
    send () {
      if (this.isValid()) {
        this.sendDataToServer(
          {
            kontrolle: this.kontrolle
          },
          STATUS_SYNC_MASSNAHMENVORSCHLAEGE_FINISHED
        )
      } else {
        this.showAlertModal(this.messages.alerts.error, this.messages.alerts.fieldsToFill, this.messages.alerts.correction)
      }
    },
    next () {
      this.$router.push('/massnahmenplan')
    },
    isValid () {
      let output = true
      const refEditors = this.$refs['editors']
      for (const ref in refEditors) {
        if (refEditors.hasOwnProperty(ref)) {
          const editor = refEditors[ref]
          if (!editor.jsonEditor) {
            console.log('editor not initialized jet')
            output = false
            break
          }
          if (!editor.isValid()) {
            console.log('editor is not valid')
            output = false
            break
          }
        }
      }
      console.log('isValid', output)
      return output
    }
  }
}
</script>
