import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class Ergebnis extends Model {
  static entity = 'ergebnisse'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      frage_id: this.string(''),
      funktion: this.string(''),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable()
    }
  }
}
