import { Model } from '@vuex-orm/core'
import Indikator from './Indikator'
import uuid from 'uuid'

export default class IndikatorGruppe extends Model {
  static entity = 'indikator-gruppen'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      name: this.string(''),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      indikatoren: this.hasMany(Indikator, 'indikator_gruppe_id')
    }
  }
}
