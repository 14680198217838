import * as types from './mutation-types'

/* global cordova */
const actions = {
  /**
   * Get the version code from config.xml, in browser environment VUE_APP_BUILD (git hash) will be used see vue.config.js
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async setVersionCode ({ commit, state }) {
    try {
      const code = await cordova.getAppVersion.getVersionCode()
      commit(types.SET_VERSION_CODE, code)
    } catch (error) {
      if (error === 'Missing Command Error') {
        const code = (process.env.VUE_APP_BUILD) ? process.env.VUE_APP_BUILD : '1'
        commit(types.SET_VERSION_CODE, code)
      }
    }
  },
  /**
   * Get the version number from config.xml, in browser environment VUE_APP_VERSION (git tag) will be used see vue.config.js
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async setVersionNumber ({ commit, state }) {
    try {
      const number = await cordova.getAppVersion.getVersionNumber()
      commit(types.SET_VERSION_NUMBER, number)
    } catch (error) {
      if (error === 'Missing Command Error') {
        const number = state.versionNumber = (process.env.VUE_APP_VERSION) ? process.env.VUE_APP_VERSION : '0.0.1-dev'
        commit(types.SET_VERSION_NUMBER, number)
      }
    }
  }
}

export default actions
