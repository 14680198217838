export default {
  namespaced: true,
  state: {
    errors: require('./errors'),
    messages: require('./messages'),
    descriptions: require('./descriptions')
  },
  getters: {
    errors: state => state.errors,
    messages: state => state.messages,
    descriptions: state => state.descriptions,
    message: (state) => (text, param) => {
      return (typeof text === 'string') ? text.replace('{param}', param) : null
    },
    translateState: (state) => (text) => {
      switch (text) {
        case 'created':
          return 'gestartet'
        case 'interrupted':
          return 'unterbrochen'
        case 'finished':
          return 'beendet'
        case 'abgeschlossen':
          return 'completed'
        default:
          return text
      }
    }
  }
}
