<template>
  <div class="tier w-100">
    <b-row>
      <b-col>
        <h5>{{ messages.tier.headline }}</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="secondary" class="text-left">
                {{ sumAdded }} / {{ sumTotal }} {{ (sumAdded !== sumTotal) ? messages.tier.added : messages.tier.addedDone }}
                <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" :visible="(sumAdded === sumTotal)">
              <b-list-group flush>
                <b-list-group-item v-for="tier in getTiereByControlId" :key="tier.id" @click="show(tier)" class="d-flex align-items-center" button>
                  {{ tier.name }}
                  <span class="ml-auto mr-1">einsehen</span>
                  <span class="h5 mb-0" v-html="getIndikatorState(tier)"></span>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </b-card>
        </div>

        <b-card no-body v-if="sumAdded < sumTotal">
          <b-list-group flush>
            <b-list-group-item>
              <b-form-group>
                <h6>
                  {{ messages.tier.name }}
                  <b-btn variant="link" @click="openInfoModal(infoUuidTier)">
                    <i class="icon-circle-info"></i>
                  </b-btn>
                </h6>
                <b-form-input v-model="name"
                              type="text"
                              trim>
                </b-form-input>
                <b-form-invalid-feedback :state="$v.name.$dirty ? !$v.name.$error : null">
                  <div v-if="!$v.name.required">{{ messages.tier.nameRequiredError }}</div>
                  <div v-if="!$v.name.isUnique">{{ messages.tier.nameUniqueError }}</div>
                </b-form-invalid-feedback>
              </b-form-group>
              <p class="text-center">
                <b-btn @click="add" size="sm" class="ml-auto" :disabled="sumAdded === sumTotal">
                  {{ messages.tier.add }}
                </b-btn>
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <i v-html="getHint()"></i>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Tier from '@/store/modules/api/models/Tier'
import { SET_CURRENT_TIER } from '@/store/modules/api/mutation-types'
import mixins from '@/mixins'
import { sleep, KONTROL_VALIDATION_SLEEP_TIME, CONTROL_TYPE_FLEXIBLE, INFO_UUID_TIER } from '@/store/utils'

export default {
  name: 'Tier',
  mixins: [mixins],
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      name: null,
      infoUuidTier: INFO_UUID_TIER,
      viewMode: false
    }
  },
  validations: {
    name: {
      required,
      isUnique: (value, vm) => {
        return new Promise((resolve, reject) => {
          const isUnique = Tier.query()
            .where('name', value)
            .where('kontrolle_id', vm.kontrolle.id)
            .first() === null

          const valid = isUnique || (!isUnique && vm.viewMode)
          resolve(valid)
        })
      }
    }
  },
  activated () {
    this.viewMode = false
    this.$store.commit(`api/${SET_CURRENT_TIER}`, null)
    this.name = null
    this.$v.$reset()
  },
  computed: {
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ])
  },
  methods: {
    /**
     * Add new tier
     */
    async add () {
      if (!this.isValid()) {
        return false
      }

      const model = new Tier()
      model.name = this.name
      model.kontrolle_id = this.kontrolle.id
      this.$emit('next')
      await sleep(KONTROL_VALIDATION_SLEEP_TIME)
      this.$store.dispatch('api/insertOrUpdateTier', model)
    },
    /**
     * Set tier as current & emit next event
     */
    show (tier) {
      this.viewMode = true
      this.name = tier.name
      this.$store.commit(`api/${SET_CURRENT_TIER}`, tier)
      this.$emit('next')
    },
    /**
     * Is view valid
     * @return {boolean}
     */
    isValid () {
      this.$v.$touch()
      return !this.$v.$error
    },
    /**
     * Get "Tier Indikator" state
     * @param tier
     * @return {string|string}
     */
    getIndikatorState (tier) {
      const state = this.getEinzelkuhIndikatorState(tier)
      return (state.sumAntworten !== state.sumFragen)
        ? `<span class="text-danger">${state.sumAntworten} / ${state.sumFragen}</span>`
        : '<span class="icon-checkmark"></span>'
    },
    /**
     * Get hint, depending on "kontrolle.typ"
     * @return {string}
     */
    getHint () {
      return (this.kontrolle && this.kontrolle.typ === CONTROL_TYPE_FLEXIBLE)
        ? this.messages.tier.hintFlexible
        : this.messages.tier.hint + '<br><br>' + this.messages.tier.hintFlexible
    }
  }
}
</script>
