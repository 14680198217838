<template>
  <div class="score">
    <b-badge class="p-2" :variant="state">
      <div v-html="value"></div>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'Score',
  props: {
    value: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    }
  }
}
</script>
