<template>
  <b-navbar fixed="top"
            type="light"
            variant="default"
            class="header-bar">

    <b-navbar-brand class="py-0" :href="baseUrl" target="_blank" v-if="isDesktopMode">
      <img class="logo" src="../assets/logo.svg" alt="">
    </b-navbar-brand>

    <b-btn to="/einstellungen"
           variant="default-outline"
           class="btn-settings pl-3 pr-2 pb-0 border-0"
           v-if="$route.name !== 'einstellungen' && $route.name !== 'login'">
      <b-icon icon="person" font-scale="1"></b-icon>
    </b-btn>

    <b-navbar-nav class="w-100 d-block p-0 text-center" v-if="$route.name !== 'einstellungen' && $route.name !== 'login'">
      <span class="title">
        {{ $route.meta.title }}
      </span>
      <b-nav-item-dropdown class="w-100" v-if="allFinishedControls.length">
        <span slot="button-content" class="w-100 text-center d-block">
          <span v-if="kontrolle">
            {{ getHerdeName(kontrolle) }} / {{ kontrolle.name }} ({{ getLocalDate(kontrolle.benutzer_erstellt_am) }})
          </span>
          <span v-else>
            {{ messages.headerBar.select }}
          </span>
          <b-icon icon="chevron-down"></b-icon>
        </span>
        <b-dropdown-item-button @click="change(control)"
                                v-for="control in allFinishedControls"
                                :key="control.id">
          <b-icon icon="check2" v-if="kontrolle && kontrolle.id === control.id"></b-icon>
          <span class="d-inline pl-4" v-else>&nbsp;</span>
          {{ getHerdeName(control) }} / {{ control.name }} ({{ getLocalDate(control.benutzer_erstellt_am) }})
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { SET_SELECTED_KONTROLLE_ID } from '@/store/modules/api/mutation-types'
import mixins from '@/mixins'
import { CONTROL_STATE_FINISHED } from '@/store/utils'

export default {
  name: 'HeaderBar',
  mixins: [mixins],
  data () {
    return {
      baseUrl: this.$store.state.api.apiBaseUrl
    }
  },
  computed: {
    /**
     * All finished controls
     * @return {Collection<InstanceOf<Kontrolle>>}
     */
    allFinishedControls () {
      return this.kontrollen.filter(control => control.status === CONTROL_STATE_FINISHED)
    }
  },
  methods: {
    /**
     * Change control
     * @param control
     */
    change (control) {
      this.$store.commit(`api/${SET_SELECTED_KONTROLLE_ID}`, control.id)
      if (this.$route.name !== 'ergebnisse') {
        this.$router.push('/ergebnisse')
      }
    }
  }
}
</script>
