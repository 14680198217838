<template>
  <b-modal :id="id"
           modal-class="timer-modal"
           @show="show"
           @hide="hide"
           size="sm"
           no-fade
           centered>
    <template v-slot:modal-title>
      {{ title }}
    </template>
    <h4 class="desc" v-if="desc">
      <i class="icon-cow"></i> {{ desc }}
    </h4>
    <p class="time">
      <span v-if="time">{{ time }}</span>
    </p>
    <template v-slot:modal-footer="{ close }">
      <b-btn variant="link" @click="close">Schließen</b-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'TimerModal',
  props: {
    id: {
      type: String,
      default: 'timer-modal'
    },
    title: {
      type: String,
      default: 'Timer'
    },
    desc: {
      type: String,
      default: 'Description'
    },
    duration: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      time: null,
      interval: null
    }
  },
  methods: {
    /**
     * Modal show handler
     */
    show () {
      this.time = this.getTime(this.duration)
      this.startTimer(this.duration)
    },
    /**
     * Modal hide handler
     */
    hide () {
      if (this.interval) {
        this.time = null
        clearInterval(this.interval)
      }
    },
    /**
     * Start timer
     * @param duration
     */
    startTimer (duration) {
      this.interval = setInterval(() => {
        this.time = this.getTime(duration)
        if (--duration < 0) {
          clearInterval(this.interval)
          navigator.notification.beep(2)
        }
      }, 1000)
    },
    /**
     * Get minutes & seconds as string
     * @param duration
     * @return {string}
     */
    getTime (duration) {
      let minutes = 0
      let seconds = 0

      minutes = parseInt(duration / 60, 10)
      seconds = parseInt(duration % 60, 10)

      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      return `${minutes}:${seconds}`
    }
  }
}
</script>
