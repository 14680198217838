<template>
  <div class="indikator-auswahl w-100">
    <b-row>
      <b-col>
        <h5>{{ this.messages.indikatorSelection.headline }}</h5>
        <p>{{ this.messages.indikatorSelection.subline }}</p>
        <p v-if="isNachkontrolle(kontrolle)">{{ this.messages.indikatorSelection.sublineNachkontrolle }}</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <template v-slot:label>
            <b-form-checkbox v-model="allSelected"
                             :indeterminate="indeterminate"
                             @change="toggleAll"
                             :disabled="kontrolle.hauptkontrolle_id !== null">
              {{ allSelected ? messages.indikatorSelection.deselectAll : messages.indikatorSelection.selectAll }}
            </b-form-checkbox>
          </template>

          <b-form-group v-for="gruppe in indikatorGruppen" :key="gruppe.id" :label="gruppe.name">
            <b-form-checkbox-group v-model="selected"
                                   :options="gruppe.indikatoren"
                                   :id="'indikatorGruppe-' + gruppe.id"
                                   :name="'indikatorGruppe-' + gruppe.id"
                                   text-field="name"
                                   value-field="id"
                                   disabled-field="disabled"
                                   @change="changeHandler"
                                   stacked>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-group>
        <b-form-invalid-feedback :state="$v.selected.$dirty ? !$v.selected.$error : null">
          {{ messages.indikatorSelection.selectedError }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_SELECTED_INDIKATOREN, SET_SELECTED_NK_INDIKATOREN } from '@/store/modules/api/mutation-types'

import {
  CONTROL_STATE_CREATED,
  INDIKATOR_UUID_KOTKONSISTENZ,
  INDIKATOR_UUID_TIERVERSCHMUTZUNG
} from '@/store/utils'

import { minLength, required } from 'vuelidate/lib/validators'
import mixins from '@/mixins'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import { mapState } from 'vuex'

export default {
  name: 'IndikatorAuswahl',
  mixins: [mixins],
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      allSelected: false,
      indeterminate: false
    }
  },
  validations: {
    selected: {
      required,
      minLength: minLength(1)
    }
  },
  created () {
    if (this.kontrolle.status === CONTROL_STATE_CREATED) {
      this.$store.commit(`api/${SET_SELECTED_INDIKATOREN}`, [])
    }
    this.$store.commit(`api/${SET_SELECTED_NK_INDIKATOREN}`, this.initialSelectedNKIndikatoren())
  },
  activated () {
    this.allSelected = (this.selected && this.indikatoren && this.selected.length === this.indikatoren.length)
    this.indeterminate = (this.selected && this.indikatoren && this.selected.length !== this.indikatoren.length && this.selected.length !== 0)
  },
  watch: {
    /**
     * Handle changes in individual indikatoren checkboxes
     * @param newVal
     * @param oldVal
     */
    selected (newVal, oldVal) {
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.indikatoren.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  computed: {
    /**
     * Map api state
     */
    ...mapState('api', [
      'selectedIndikatoren',
      'selectedNKIndikatoren'
    ]),
    /**
     * Indikatoren Gruppen
     */
    indikatorGruppen () {
      const indikatorGruppen = this.data.indikatorGruppen
      const initial = this.initialSelectedNKIndikatoren()
      const ergebnisse = this.getHauptkontrolle().ergebnisse

      if (this.isNachkontrolle(this.kontrolle)) {
        indikatorGruppen.forEach((gruppe) => {
          gruppe.indikatoren.forEach((indikator) => {
            const disabled = this.hasDangerState(ergebnisse, indikator.id)
            indikator.disabled = initial.includes(indikator.id) ? disabled : true
          })
        })
      } else {
        indikatorGruppen.forEach((gruppe) => {
          gruppe.indikatoren.forEach((indikator) => {
            indikator.disabled = indikator.id === INDIKATOR_UUID_KOTKONSISTENZ
          })
        })
      }

      return indikatorGruppen
    },
    /**
     * Getter / setter for "selectedIndikatoren" state property
     */
    selected: {
      get () {
        if (this.isNachkontrolle(this.kontrolle)) {
          return this.selectedNKIndikatoren
        } else {
          return this.selectedIndikatoren
        }
      },
      set (value) {
        if (this.isNachkontrolle(this.kontrolle)) {
          this.$store.commit(`api/${SET_SELECTED_NK_INDIKATOREN}`, value)
        } else {
          this.$store.commit(`api/${SET_SELECTED_INDIKATOREN}`, value)
        }
      }
    },
    /**
     * Get all "Indikatoren" as flat array
     * @return {*|FlatArray<*, 1>[]|any[]}
     */
    indikatoren () {
      return this.indikatorGruppen
        .map(gruppe => gruppe.indikatoren.map(child => ({ ...child })))
        .flat()
    }
  },
  methods: {
    hasDangerState (ergebnisse, indikatorId) {
      let indikatorState
      ergebnisse.forEach((indikatorData) => {
        if (indikatorData.indikatorId === indikatorId) {
          indikatorState = indikatorData.state
        }
      })
      return indikatorState === 'danger'
    },
    getHauptkontrolle () {
      const hauptkontrolle = Kontrolle.query()
        .whereId(this.kontrolle.hauptkontrolle_id)
        .first()

      if (!hauptkontrolle) {
        return false
      }

      return hauptkontrolle
    },
    initialSelectedNKIndikatoren () {
      const hauptkontrolle = this.getHauptkontrolle()

      if (!hauptkontrolle) {
        return false
      }

      const selected = []

      const ergebnisse = hauptkontrolle.ergebnisse
      ergebnisse.forEach((indikatorData) => {
        if (indikatorData.state === 'danger' || indikatorData.state === 'warning') {
          selected.push(indikatorData.indikatorId)
        }
      })

      return selected
    },
    /**
     * Toggle all handler
     * @param checked
     */
    toggleAll (checked) {
      this.selected = checked ? this.indikatoren.map(item => item.id) : []
    },
    /**
     * Is view valid
     * @return {boolean}
     */
    isValid () {
      this.$v.$touch()
      return !this.$v.$error
    },
    /**
     * Add depending "Indikator"
     * @param selected
     * @return {boolean|*[]}
     */
    addDependingIndikator (selected) {
      const depends = selected.some(value => value === INDIKATOR_UUID_TIERVERSCHMUTZUNG)

      if (depends && !selected.includes(INDIKATOR_UUID_KOTKONSISTENZ)) {
        return [...selected, INDIKATOR_UUID_KOTKONSISTENZ]
      } else if (!depends && selected.includes(INDIKATOR_UUID_KOTKONSISTENZ)) {
        return selected.filter(value => value !== INDIKATOR_UUID_KOTKONSISTENZ)
      }

      return false
    },
    /**
     * Checkbox group change handler
     * @param value
     */
    changeHandler (value) {
      this.selected = this.addDependingIndikator(value) || value
    }
  }
}
</script>
