<template>
  <b-modal :id="id"
           no-fade
           centered
           size="sm"
           hide-header
           @show="show"
           no-close-on-esc
           no-close-on-backdrop
           modal-class="herde-modal">

    <h6 class="text-center">
      {{ messages.herde.change }}
    </h6>

    <p class="text-center my-2">
      {{ messages.herde.changeText }}
    </p>

    <b-form-group class="herde-options" v-if="herdenOptions">
      <b-form-radio v-for="option in herdenOptions"
                    :key="option.value.id"
                    v-model="herde"
                    :value="option"
                    name="herden">
        {{ option.name }}
        <b-btn size="sm"
               v-if="option.value.$id"
               variant="outline-primary"
               @click="deleteHerde(option.value)">
          <b-icon icon="trash"></b-icon>
        </b-btn>
      </b-form-radio>
    </b-form-group>

    <b-row>
      <b-col cols="12" class="mb-1">
        <label for="name">{{ messages.herde.name }}</label>
        <b-form-input id="name"
                      v-model="name"
                      type="text">
        </b-form-input>
        <b-form-invalid-feedback :state="$v.name.$dirty ? !$v.name.$error : null">
          <div v-if="!$v.name.required">{{ messages.herde.nameRequiredError }}</div>
          <div v-if="!$v.name.isUnique">{{ messages.herde.nameUniqueError }}</div>
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ close }">
      <b-btn variant="link" @click="saveHerde">
        {{ messages.herde.save }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Herde from '@/store/modules/api/models/Herde'
import HerdePreset from '@/store/modules/api/models/HerdePreset'

export default {
  name: 'HerdeModal',
  mixins: [mixins],
  props: {
    id: {
      type: String,
      default: 'herde-modal'
    }
  },
  data () {
    return {
      herde: null,
      name: null
    }
  },
  created () {
    this.init()
  },
  validations: {
    name: {
      required,
      isUnique: (value, vm) => {
        return new Promise((resolve, reject) => {
          const isUnique = HerdePreset.query().where('name', value).first() === null
          // const isExisting = vm.herde.value.$id !== null
          resolve(isUnique)
        })
      }
    }
  },
  watch: {
    herde (after, before) {
      if (after) {
        this.name = after.value.name
        if (after.value.$id) {
          this.$emit('set-preset', after.value)
        }
      }
    }
  },
  computed: {
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    exists () {
      return HerdePreset.exists()
    },
    herdenOptions () {
      const arr = []
      arr.push({ value: new HerdePreset(), name: this.messages.herde.addNew })

      if (this.exists) {
        const herden = HerdePreset.all().map(herde => {
          return { value: herde, name: herde.name }
        })
        arr.push(...herden)
      }
      return arr.reverse()
    }
  },
  methods: {
    /**
     * Initialize modal
     */
    init () {
      setTimeout(() => {
        const element = document.querySelector('#herde-modal input[type=text]')
        if (element) {
          element.focus()
        }
      }, 100)

      this.herde = (this.kontrolle && this.kontrolle.herde_id)
        ? this.herdenOptions.find(herde => herde.value.name === Herde.find(this.kontrolle.herde_id).name)
        : this.herdenOptions[0]
    },
    show () {
      this.init()
    },
    /**
     * Save selected "HerdePreset" & dispatch changes
     */
    saveHerde () {
      if (!this.herde.value.$id) {
        this.$v.$touch()
        if (this.$v.$error) {
          return false
        }
      }

      if (this.name) {
        this.herde.value.name = this.name
      }

      this.$emit('save-preset', this.herde.value)
    },
    /**
     * Delete herde
     * @param herde
     */
    deleteHerde (herde) {
      const title = this.messages.herde.deleteTitle
      const text = this.messages.herde.deleteText
      const okTitle = this.messages.herde.okBtn
      const cancelTitle = this.messages.herde.cancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle)
        .then(response => {
          if (response) {
            this.$store.dispatch('api/deleteHerdePreset', herde)
          }
        })
    }
  }
}
</script>
