import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class Massnahme extends Model {
  static entity = 'massnahmen'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      frage_id: this.uid(() => uuid()),
      text: this.string(''),
      begruendung: this.string('')
    }
  }
}
