<template>
  <div class="einstellungen">
    <b-card no-body v-if="loggedIn">
      <b-card-body>
        <b-row>
          <b-col cols="4" class="d-flex justify-content-center align-items-center">
            <b-icon icon="person-circle" font-scale="3"></b-icon>
          </b-col>
          <b-col cols="8">
            <b-card-title title-tag="h5" class="font-weight-normal mb-0 mt-0">
              {{ username }}
            </b-card-title>
            <b-card-text>
              <b-link @click="openSystemBrowser">Profil ändern</b-link>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>

      <b-list-group flush>
        <b-list-group-item class="d-flex align-items-center px-3">
          <small>{{ messages.forms.stayLoggedIn }}</small>
          <b-form-checkbox v-model="loginState" class="ml-auto" @change="changeLoginState" switch></b-form-checkbox>
        </b-list-group-item>

        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.name }}</label>
          <label class="mb-0" v-if="profile && profile.name">{{ profile.name }}</label>
        </b-list-group-item>
        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.forename }}</label>
          <label class="mb-0" v-if="profile && profile.forename">{{ profile.forename }}</label>
        </b-list-group-item>
        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.company }}</label>
          <label class="mb-0" v-if="profile && profile.company">{{ profile.company }}</label>
        </b-list-group-item>
        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.street }}</label>
          <label class="mb-0" v-if="profile && profile.street">{{ profile.street }}</label>
        </b-list-group-item>
        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.zip }}</label>
          <label class="mb-0" v-if="profile && profile.zip">{{ profile.zip }}</label>
        </b-list-group-item>
        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.location }}</label>
          <label class="mb-0" v-if="profile && profile.location">{{ profile.location }}</label>
        </b-list-group-item>
        <b-list-group-item class="px-3 small">
          <label class="mb-0 mr-2 font-weight-bold">{{ messages.settings.county }}</label>
          <label class="mb-0" v-if="profile && profile.county">{{ profile.county }}</label>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card class="my-3" v-if="loggedIn" no-body>
      <h6 class="mb-0 p-3">{{ messages.settings.userData }}</h6>
      <b-list-group flush>
        <b-list-group-item class="d-flex align-items-center px-3">
          <small>{{ messages.settings.fetchTitle }}</small>
          <b-btn id="update-user-data" @click="updateUserData" size="sm" class="ml-auto">
            <b-spinner small v-if="syncing.userData"></b-spinner>
            <b-icon icon="arrow-repeat" v-else></b-icon>
          </b-btn>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card class="my-3" no-body>
      <h6 class="mb-0 p-3">{{ messages.settings.baseData }}</h6>
      <b-list-group flush>
        <b-list-group-item class="d-flex align-items-center px-3">
          <small>{{ messages.settings.updatedAt }} {{ updatedAt }}</small>
          <b-btn @click="updateBaseData" size="sm" class="ml-auto">
            <b-spinner small v-if="syncing.baseData"></b-spinner>
            <b-icon icon="arrow-repeat" v-else></b-icon>
          </b-btn>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card class="my-3" v-if="loggedIn && isOnDevice()" no-body>
      <h6 class="mb-0 p-3">{{ messages.settings.mediaFiles }}</h6>
      <b-list-group flush>
        <b-list-group-item class="d-flex align-items-center px-3">
          <small>{{ messages.settings.updatedAt }} {{ mediaFilesUpdatedAt }}</small>
          <b-btn id="update-media-data" @click="updateMediaData" size="sm" class="ml-auto">
            <b-spinner small v-if="syncing.mediaData"></b-spinner>
            <b-icon icon="arrow-repeat" v-else></b-icon>
          </b-btn>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card no-body v-if="!loggedIn">
      <b-list-group flush>
        <b-list-group-item class="d-flex align-items-center px-3">
          <b-btn to="/" block>{{ messages.settings.loginRegister }}</b-btn>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <p class="text-center small mx-3 my-3 realized">
      <img src="../assets/dmstr-32-dark-cropped.png" class="img-fluid mr-1" alt="">
      Realized by <a href="https://www.herzogkommunikation.de" @click.prevent="open">herzog kommunikation</a> GmbH, Stuttgart
    </p>

    <hr>

    <p class="text-center small mb-3">Pro-Q BW - Version: {{ versionNumber }} (Build {{ versionCode }})</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import mixins from '@/mixins'

export default {
  name: 'Einstellungen',
  mixins: [mixins],
  data () {
    return {
      syncing: {
        baseData: false,
        userData: false,
        mediaData: false
      },
      loginState: false
    }
  },
  created () {
    this.loginState = this.loggedIn
  },
  watch: {
    loggedIn (value) {
      this.loginState = value
    }
  },
  computed: {
    /**
     * Map base state
     */
    ...mapState([
      'versionCode',
      'versionNumber'
    ]),
    /**
     * Map base state
     */
    ...mapState('api', [
      'updatedAt',
      'isLoggedIn',
      'user',
      'apiBaseUrl',
      'mediaFilesUpdatedAt',
      'profile'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'userProfileUrl'
    ]),
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * Login state
     */
    loggedIn: {
      get () {
        return this.isLoggedIn
      },
      set (value) {
        this.$store.commit('api/SET_LOGIN_STATE', { value: value, reset: true })
      }
    },
    /**
     * Username from vuex api module
     * @return {null}
     */
    username () {
      return this.user.username || this.user.email
    }
  },
  methods: {
    /**
     * Open website link
     * @param event
     */
    open (event) {
      this.openInAppBrowser(event.target.href, '_system')
    },
    /**
     * Update base data handler
     */
    updateBaseData () {
      const title = this.messages.settings.updateTitle
      const text = this.messages.settings.updateText
      const okTitle = this.messages.settings.updateOkBtn
      const cancelTitle = this.messages.settings.cancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle, 'sm', 'sm', 'secondary', 'danger')
        .then(response => {
          if (response) {
            this.syncing.baseData = true
            this.fetchBaseData()
              .then(() => {
                this.syncing.baseData = false
              })
          }
        })
    },
    /**
     * Update user data handler
     */
    updateUserData () {
      const title = this.messages.settings.fetchTitle
      const text = this.messages.settings.fetchText
      const okTitle = this.messages.settings.fetchOkBtn
      const cancelTitle = this.messages.settings.cancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle, 'sm', 'sm', 'secondary', 'danger')
        .then(response => {
          if (response) {
            this.syncing.userData = true
            this.fetchUserData()
              .then(() => {
                this.syncing.userData = false
              })
          }
        })
    },
    /**
     * Update media data handler
     */
    updateMediaData () {
      this.syncing.mediaData = true
      this.fetchMediaFiles()
        .then(() => {
          this.syncing.mediaData = false
        })
    },
    /**
     * Open system browser
     */
    openSystemBrowser () {
      this.openInAppBrowser(this.userProfileUrl, '_system')
    },
    /**
     * Log in|out handler
     * @param value
     */
    changeLoginState (value) {
      const title = this.messages.settings.logoutTitle
      const text = this.messages.settings.logoutText
      const okTitle = this.messages.settings.logoutOkBtn
      const cancelTitle = this.messages.settings.cancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle)
        .then(response => {
          if (response) {
            this.loggedIn = false
          } else {
            this.loginState = this.loggedIn
          }
        })
    }
  }
}
</script>
