import { b64utoutf8, KEYUTIL, KJUR } from 'jsrsasign'
import marked from 'marked'
import Herde from '@/store/modules/api/models/Herde'
import Ergebnis from '@/store/modules/api/models/Ergebnis'
import Tier from '@/store/modules/api/models/Tier'

/**
 * Get formatted date string
 * @desc
 * - Get locale from "navigator.language" if available otherwise set fallback "de-DE"
 * @returns {string}
 */
export const today = () => {
  const d = new Date()
  const locale = (navigator.language && navigator.language === 'de-DE') ? navigator.language : 'de-DE'
  return d.toLocaleString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export const KONTROL_VALIDATION_SLEEP_TIME = 100

/**
 * Control states
 * @desc
 * - 'gestartet' => CONTROL_STATE_CREATED
 * - 'unterbrochen' => CONTROL_STATE_INTERRUPTED
 * - 'beendet' => CONTROL_STATE_FINISHED
 * @type {string}
 */
export const CONTROL_STATE_CREATED = 'created'
export const CONTROL_STATE_INTERRUPTED = 'interrupted'
export const CONTROL_STATE_FINISHED = 'finished'

/**
 * Control types => "Art der Kontrolle"
 * @desc
 * - "Eigenkontrolle mit Tierschutzindikatoren" => CONTROL_TYPE_STATIC
 * - "Eigenkontrolle mit Ursachenanalyse" => CONTROL_TYPE_FLEXIBLE
 * - "Zitzenkontrolle" => CONTROL_TYPE_TEATS
 * - "Nachkontrolle" => CONTROL_TYPE_FOLLOW_UP
 * @type {string}
 */
export const CONTROL_TYPE_STATIC = 'static'
export const CONTROL_TYPE_FLEXIBLE = 'flexible'
export const CONTROL_TYPE_TEATS = 'teats'
export const CONTROL_TYPE_FOLLOW_UP = 'follow-up'

/**
 * Herde "Haltungssysteme"
 * @type {string}
 */
export const HALTUNGSSYSTEM_LAUFSTALL_LIEGEBOXEN = 'L'
export const HALTUNGSSYSTEM_LAUFSTALL_FREIE_LIEGEFLAECHE = 'LF'
export const HALTUNGSSYSTEM_ANBINDESTALL = 'A'

/**
 * Static UUID'S from table "indikator_gruppe"
 * @type {string}
 */
export const INDIKATOR_GRUPPE_UUID_HERDE = '804f90e0-2030-4e0a-97d9-90aeaa41b46b'
export const INDIKATOR_GRUPPE_UUID_EINZELKUH = 'b78aeaff-5401-42e4-814b-1136d4bcbd58'
export const INDIKATOR_GRUPPE_UUID_HERDE2 = 'b625e01d-a663-4c9b-8140-631d7fb2879e'
export const INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE = '64ee5255-6eae-45d7-9ffc-bd48c50e7028'
export const INDIKATOR_GRUPPE_UUID_URSACHENANALYSE = '965e4578-002b-49bd-91cd-c645d297b401'
export const INDIKATOR_GRUPPE_UUID_PRODUKTIONSSYSTEM = '1d010283-1b78-4331-be30-173cb178f18a'

/**
 * Static UUID'S from table "indikator"
 * @type {string}
 */
export const INDIKATOR_UUID_HORNSTOSSVERLETZUNGEN = '1267e37b-b92f-425a-a2e3-a06f0b12ceb3'
export const INDIKATOR_UUID_ZITZENKONDITION = '01c0626c-5593-47b6-92b6-41397fb3ea84'
export const INDIKATOR_UUID_LIEGE_STEHVERHALTEN = 'a9023fb7-2a2e-4cae-a92c-3b8553fff931'
export const INDIKATOR_UUID_ERGAENZENDE_INDIKATOREN = '8595d550-c3d1-481e-8d58-e47161727236'
export const INDIKATOR_UUID_WASSERVERSORGUNG = '4a7604d2-bda9-419a-b7df-fcc9c5d30905'
export const INDIKATOR_UUID_LAHMHEITEN = 'ac32837d-b6e4-4f2b-aa6e-6efd72b912c1'
export const INDIKATOR_UUID_ZELLZAHLEN = 'abd0202a-6c32-40e6-b8e0-f351668a8574'
export const INDIKATOR_UUID_TIERVERSCHMUTZUNG = '62400455-b8e2-4d3d-a1ab-a6931923d828'
export const INDIKATOR_UUID_BODY_CONDITION_SCORE = '7e5759d5-93e6-411c-8519-cc8de8ecfc34'
export const INDIKATOR_UUID_KOTKONSISTENZ = '07e14102-bff6-4086-8c16-961bf076d4b0'

/**
 * Static UUID'S from table "frage"
 * @type {string}
 */
export const FRAGE_UUID_BELEGUNG_LAKTIERENDE_TIERE = 'c7362260-3041-493b-9a06-ba73ea721070'
export const FRAGE_UUID_BELEGUNG_LEISTUNGSGRUPPE = '35645d86-62e9-4fc4-bae1-7e2c73bef89c'
export const FRAGE_UUID_BELEGUNG_TROCKENSTEHER = '9d3d7748-76c4-40bb-9c10-33739acb1b99'
export const FRAGE_UUID_WASSERVERSORGUNG = 'f7db577a-6d6e-4290-967f-19ab613dfd6d'
export const FRAGE_UUID_WASSERVERSORGUNG_LEISTUNGSGRUPPE = 'e09bcf2f-5e1b-4242-a606-cb2353e2ae4a'
export const FRAGE_UUID_AUSWEICHDISTANZ = 'a2f06ccc-3b73-4268-88aa-3ce62145c944'
export const FRAGE_UUID_HITZESTRESS = 'ffa2b7bd-3b3e-42e6-9d0f-087cf6c3b017'
export const FRAGE_UUID_MASSNAHMENVORSCHLAEGE = '08ad36bd-1d59-4369-81e5-92c6e90c323c'
export const FRAGE_UUID_MASSNAHMENPLAN = '734faeb8-4ca0-4a36-a97a-64c4a2dfb2f9'

/**
 * Static UUID'S from table "frage_typ"
 * @type {string}
 */
export const FRAGE_TYP_UUID_TEST = '6e3b3c11-2d9e-41a4-95b5-376387602325'
export const FRAGE_TYP_UUID_STANDARD = '94254021-e745-48aa-b85e-9845c984c5e0'

/**
 * Static UUID'S from table "info"
 * @type {string}
 */
export const INFO_UUID_HERDENDATEN_MILCHKUEHE_GESAMT = '05b9cf72-d38c-4c10-ad38-2243c134ba7c'
export const INFO_UUID_HERDENDATEN_LAKTIERENDE_TIERE = 'a58f0cf7-be99-4403-8b72-7e4edde75a69'
export const INFO_UUID_HERDENDATEN_FOKUSGRUPPE = 'f54b74dd-7d9f-440d-b6fc-778ace72754d'
export const INFO_UUID_NAME_DER_FOKUSGRUPPE = 'c75adc29-8103-4508-a125-ad743c3b8220'
export const INFO_UUID_GROESSE_STICHPROBE = '7f1e9d4b-ee93-4a70-868d-f7a7beddba7a'
export const INFO_UUID_TIER = 'f9d255a5-b4da-4000-8677-14630d102270'
export const INFO_UUID_PRODUKTIONSSYSTEM = '17861d65-bd12-42d1-8008-0ad9762ee17e'
export const INFO_UUID_URSACHENANALYSE = '964e43c7-ecc6-4f66-a613-eb27206bc532'
export const INFO_UUID_URSACHENANALYSE_UNREGISTERED = '2022e756-86d8-4972-9eae-f4d1121bb6b0'
export const INFO_UUID_URSACHENANALYSE_DESKTOP = '2276f7b2-12e3-4eb1-9be4-f6295022ece6'
export const INFO_UUID_MASSNAHMENPLAN = '75e36d6e-7401-4e38-bcfc-b02221d78250'
export const INFO_UUID_MASSNAHMENVORSCHLAEGE = '0b128475-a311-4669-aed1-00b353f9cd88'
export const INFO_UUID_ZITZENKONTROLLE = 'c23d7e80-d9a6-4c7e-b2eb-b2369b3e9e81'

/**
 * Status sync states
 * @desc
 * - 0 => initial code if "Kontrolle" is created
 * - 10 => "Kontrolle" view is finished & data stored on server
 * - 20 => "Produktionssystem" view is finished & data stored on server
 * - 30 => "Ursachenanalyse" view is finished & data stored on server
 * @type {number}
 */
export const STATUS_SYNC_INITIAL = 0
export const STATUS_SYNC_KONTROLLE_FINISHED = 10
export const STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED = 20
export const STATUS_SYNC_URSACHENANALYSE_DESKTOP_FINISHED = 30
export const STATUS_SYNC_URSACHENANALYSE_APP_FINISHED = 35
export const STATUS_SYNC_MASSNAHMENVORSCHLAEGE_FINISHED = 40
export const STATUS_SYNC_MASSNAHMENPLAN_FINISHED = 50
export const STATUS_SYNC_DELETED = 80

/**
 * Local storage prefix
 * @type {string}
 */
const STORAGE_PREFIX = 'pro-q'

/**
 * Format bytes to unit
 * @param bytes
 * @param decimals
 * @returns {string}
 */
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Local storage wrapper
 * @type {{fetch: localStorage.fetch, save: localStorage.save, remove: localStorage.remove}}
 */
export const localStorage = {
  fetch: (key) => {
    const value = window.localStorage.getItem(STORAGE_PREFIX + '.' + key)
    try {
      return JSON.parse(value)
    } catch (error) {
      if (error.name === 'SyntaxError') {
        return value
      } else {
        console.error('localStorage fetch error: ', error)
      }
    }
  },
  save: (key, value) => {
    try {
      const data = typeof value === 'object' ? JSON.stringify(value) : value
      window.localStorage.setItem(STORAGE_PREFIX + '.' + key, data)
    } catch (error) {
      console.error('localStorage save error: ', error)
    }
  },
  remove: (key) => {
    try {
      window.localStorage.removeItem(STORAGE_PREFIX + '.' + key)
    } catch (error) {
      console.error('localStorage remove error: ', error)
    }
  },
  size: () => {
    let key
    let length
    let total = 0
    for (key in window.localStorage) {
      if (!window.localStorage.hasOwnProperty(key)) {
        continue
      }
      length = ((window.localStorage[key].length + key.length) * 2)
      total += length
      // console.log(key.substr(0, 50) + ' = ' + formatBytes(length))
    }

    // console.log('Total = ' + formatBytes(total))
    return formatBytes(total)
  }
}

/**
 * JWT verification via JWK
 * @param jwt
 * @param state
 * @return {boolean|*}
 */
export const verifyJwt = async (jwt, state) => {
  // console.log('*** jwk: ', state.jwk)
  const pubKey = await KEYUTIL.getKey(state.jwk)
  // console.log('*** pubKey: ', pubKey)

  const jwtHeader = await KJUR.jws.JWS.readSafeJSONString(b64utoutf8(jwt.split('.')[0]))
  // console.log('*** jwtHeader: ', jwtHeader)

  const isValid = await KJUR.jws.JWS.verifyJWT(jwt, pubKey, { alg: [jwtHeader.alg] })
  // console.log('*** jwt: ', jwt)

  // console.log('*** verifyJwt: ', isValid)
  return isValid
}

/**
 * Get formatted date String for MySQL usage
 * @returns {string}
 */
export const getMySQLDateString = () => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  return (new Date(Date.now() - tzoffset)).toISOString().slice(0, 19).replace('T', ' ')
}

/**
 * Parse a link header and returns an object keyed by the rel property that contains information about each link
 * @param linkHeader
 * @returns {{}}
 */
const parseLinkHeader = (linkHeader) => {
  const regExp = /<([^?]+\?[a-z]+=([\d]+))>;[\s]*rel=([a-z]+)/g
  let matches = []
  const obj = {}
  while ((matches = regExp.exec(linkHeader)) !== null) {
    obj[matches[3]] = {
      url: matches[1],
      page: matches[2],
      rel: matches[3]
    }
  }
  return obj
}

/**
 * Fetch API wrapper with logging option if enabled
 * @param url
 * @param options
 * @param data
 * @param logging
 * @returns {Promise<Response>}
 */
export const fetchData = async (url, options, data = [], logging = false) => {
  if (logging) {
    console.log('fetchData: ', url, options)
  }

  /**
   * Validate response from fetch method
   * @desc If response is NOT ok output console warning
   * @param response
   * @returns {Promise<{ok}|*>}
   */
  const validateResponse = (response) => {
    if (!response.ok) {
      if (logging) {
        console.warn('validateResponse: ', response)
      }
      throw response
    }
    return response
  }

  /**
   * Log response from fetch method
   * @param response
   * @returns {*}
   */
  const logResponse = (response) => {
    if (logging) {
      console.log('logResponse: ', response)
    }
    return response
  }

  /**
   * Log response headers from fetch method
   * @desc "response.headers" is just the prototype, with headers we have to use the iterators!
   * @param response
   * @returns {*}
   */
  const logResponseHeaders = (response) => {
    if (logging) {
      for (const key of response.headers.keys()) {
        console.log('logResponseHeaders key: ' + key + ' value: ' + response.headers.get(key))
      }
    }
    return response
  }

  /**
   * Log response as JSON
   * @desc Clone response to execute "json()" otherwise it will throw an TypeError: "Failed to execute 'json' on 'Response': body stream is locked"
   * @param response
   * @returns {*|Promise<any>}
   */
  const logResponseAsJSON = async (response) => {
    if (logging) {
      const json = await response.clone().json()
      console.log('getResponseAsJSON: ', json)
    }
    return response
  }

  /**
   * Log result from fetch method
   * @param result
   * @returns {*}
   */
  const logResult = (result) => {
    if (logging) {
      console.log('logResult: ', result)
    }
    return result
  }

  /**
   * If error outputs console warning & throw error
   * @param error
   */
  const errorHandler = (error) => {
    if (logging) {
      console.warn('logError: ', error)
    }
    throw error
  }

  const response = await fetch(url, options)
    .then(validateResponse)
    .then(logResponse)
    .then(logResponseHeaders)
    .then(logResponseAsJSON)
    .then(logResult)
    .catch(errorHandler)

  const json = await response.json()
  if (Array.isArray(json)) {
    data.push(...json)
  } else {
    data = json
  }

  const paginationPageCount = response.headers.get('x-pagination-page-count')
  const paginationCurrentPage = response.headers.get('x-pagination-current-page')
  if (paginationPageCount && paginationPageCount > 1) {
    const linkHeader = response.headers.get('link')
    if (linkHeader) {
      const links = parseLinkHeader(linkHeader)
      if (links.next) {
        await fetchData(links.next.url, options, data, false)
      }
    }
  }

  const success = (paginationPageCount < 2) || (paginationPageCount > 1 && paginationCurrentPage === '1')

  return {
    data: data,
    success: success
  }
}

/**
 * Parse markdown properties
 * @param obj
 */
export const parseMarkdownProperties = (obj) => {
  Object.entries(obj)
    .forEach(([key, value]) => {
      if (key === 'begruendung' || key === 'text') {
        obj[key] = (value) ? marked(value) : ''
      }
    })
}

/**
 * Get media file list
 * @param infos
 * @param state
 * @return {any[]}
 */
export const getMediaFileList = (infos, state) => {
  // console.log('GET_MEDIA_FILE_LIST', infos)
  const descriptions = infos.map(info => {
    if (info.json) {
      const json = JSON.parse(info.json)
      if (json && json.descriptions) {
        return json.descriptions
      }
    }
  })

  const images = new Set()
  descriptions.forEach(description => {
    if (Array.isArray(description) && description.length) {
      description.forEach(item => {
        if (Array.isArray(item.images) && item.images.length) {
          item.images.forEach(image => {
            if (image.file) {
              images.add(image.file)
            }
          })
        }
      })
    }
  })

  return [...images].map(file => {
    return {
      name: file.split('/').pop(),
      path: file,
      url: `${state.apiBaseUrl}${state.fileflyApiEndpoint}${file}`
    }
  })
}

/**
 * Fetch media file
 * @param url
 * @return {Promise<Blob>}
 */
export const fetchMediaFile = (url) => {
  return fetch(url)
    .then(response => response.blob())
}

/**
 * Write media file to local file system
 * @desc
 * - On iOS: localURL => cdvfile://localhost/library-nosync/media-files/FILENAME.EXT
 * - On Android: localURL => cdvfile://localhost/files/media-files/FILENAME.EXT
 * @param filename
 * @param directory
 * @param blob
 * @return {Promise<unknown>}
 */
export const writeMediaFile = (filename, directory, blob) => {
  return new Promise((resolve, reject) => {
    /* global cordova */
    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (entry) => {
      entry.getDirectory(directory, { create: true }, (dir) => {
        dir.getFile(filename, { create: true, exclusive: false }, (fileEntry) => {
          fileEntry.createWriter((fileWriter) => {
            fileWriter.onwriteend = resolve
            fileWriter.onerror = reject
            fileWriter.write(blob)
          })
        }, reject)
      }, reject)
    }, reject)
  })
}

/**
 * Create media file directory
 * @param directory
 * @return {Promise<unknown>}
 */
export const createMediaFileDirectory = (directory) => {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (entry) => {
      entry.getDirectory(directory, { create: true }, (dir) => {
        resolve(dir)
      }, reject)
    }, reject)
  })
}

/**
 * Filter "Antworten" by "indikator_gruppe_id"
 * @return {*}
 */
const filterAntwortenByIndikatorGruppeId = (antworten) => {
  return antworten.filter(antwort => {
    const indikatorGruppen = [INDIKATOR_GRUPPE_UUID_HERDE, INDIKATOR_GRUPPE_UUID_EINZELKUH, INDIKATOR_GRUPPE_UUID_HERDE2]
    return indikatorGruppen.includes(antwort.frage.indikator.indikator_gruppe_id)
  })
}

/**
 * Returns antworten grouped by indikator and sorted by indikator rang
 * @return []
 */
const sortAndGroupAntwortenByIndikatoren = (antworten) => {
  const indikatoren = []
  antworten.forEach(antwort => {
    if (!indikatoren[antwort.frage.indikator.id]) {
      indikatoren[antwort.frage.indikator.id] = {
        indikatorId: antwort.frage.indikator.id,
        rang: antwort.frage.indikator.rang,
        antworten: []
      }
    }

    const tier = Tier.find(antwort.tier_id)
    const tierName = (tier) ? tier.name : null
    const assigned = Object.assign({}, antwort, { tierName: tierName })

    indikatoren[antwort.frage.indikator.id].antworten.push(assigned)
  })

  return Object.keys(indikatoren)
    .sort((a, b) => indikatoren[a].rang - indikatoren[b].rang)
    .map(key => indikatoren[key])
}

/**
 * Check if some of the answers have a defined Tier property
 * @param antworten
 * @return {*}
 */
const hasAnswersWithTier = (antworten) => {
  return antworten.some((antwort) => {
    return antwort.tier_id
  })
}

/**
 * Get grouped "Antworten" by "frage_id"
 * @return {*}
 */
const groupAntwortenByFrageId = (antworten) => {
  const groupedAntworten = {}

  antworten.forEach((antwort) => {
    if (!groupedAntworten[antwort.frage_id]) {
      groupedAntworten[antwort.frage_id] = []
    }
    groupedAntworten[antwort.frage_id].push(antwort)
  })
  return groupedAntworten
}

/**
 * Get "Ergebnis" function by "frage_id"
 * @param id
 * @return {string|*}
 */
const getFunktionByFrageId = (id) => {
  const ergebnis = Ergebnis.query()
    .where('frage_id', id)
    .first()

  if (!ergebnis) {
    return `function () { return [{ state: 'light', value: '?' }] }`
  }

  return ergebnis.funktion
}

/**
 * Process and returns ergebnisse data of indikator
 * @param antworten
 * @param herde
 * @return {[]}
 */
const getIndikatorErgebnisse = (antworten, herde, kontrolle) => {
  const indikatorErgebnise = []

  // sort antworten by frage rang
  const sortedAntworten = antworten.sort((a, b) => {
    return a.frage.rang - b.frage.rang
  })

  let calculate

  if (hasAnswersWithTier(sortedAntworten)) {
    const antwortenGroupedByFrageId = groupAntwortenByFrageId(sortedAntworten)
    for (let key in antwortenGroupedByFrageId) {
      if (Object.hasOwnProperty.call(antwortenGroupedByFrageId, key)) {
        const frageId = key
        const antworten = antwortenGroupedByFrageId[key]
        const parsedAntworten = antworten.map(antwort => {
          return {
            json: JSON.parse(antwort.json),
            tierName: antwort.tierName
          }
        })
        const item = {}
        item.frageId = frageId
        const funktionString = getFunktionByFrageId(item.frageId)
        eval('calculate = ' + funktionString) // eslint-disable-line
        item.scores = calculate(parsedAntworten, herde, kontrolle)
        indikatorErgebnise.push(item)
      }
    }
  } else {
    for (let key in sortedAntworten) {
      if (Object.hasOwnProperty.call(sortedAntworten, key)) {
        const antwort = sortedAntworten[key]
        const parsedAntwort = JSON.parse(antwort.json)
        const item = {}
        item.frageId = antwort.frage.id
        const funktionString = getFunktionByFrageId(item.frageId)
        eval('calculate = ' + funktionString) // eslint-disable-line
        item.scores = calculate([parsedAntwort], herde, kontrolle)
        indikatorErgebnise.push(item)
      }
    }
  }

  return indikatorErgebnise
}

/**
 * Returns the indikator state based on it ergebnisse scores
 * @param indikatorErgebnisse
 * @return string
 */
const getIndikatorStateFromScores = (indikatorErgebnisse) => {
  let indicatorState = 'light'
  indikatorErgebnisse.forEach((ergebnisse) => {
    ergebnisse.scores.forEach((score) => {
      if (
        (['light'].includes(indicatorState) && score.state === 'success') ||
        (['light', 'success'].includes(indicatorState) && score.state === 'warning') ||
        (['light', 'success', 'warning'].includes(indicatorState) && score.state === 'danger')
      ) {
        indicatorState = score.state
      }
    })
  })
  return indicatorState
}

/**
 * Calculate control "ergebnisse"
 * @param kontrolle
 * @param antworten
 * @return {[]}
 */
export const calculateKontrolleErgebnis = (kontrolle, antworten) => {
  const filteredAntworten = filterAntwortenByIndikatorGruppeId(antworten)
  const groupedAntworten = sortAndGroupAntwortenByIndikatoren(filteredAntworten)

  const herde = Herde.find(kontrolle.herde_id)

  return groupedAntworten.map((group) => {
    group.antworten.sort((a, b) => {
      return (new Date(a.benutzer_erstellt_am) - new Date(b.benutzer_erstellt_am))
    })

    const ergebnisse = getIndikatorErgebnisse(group.antworten, herde, kontrolle)
    const state = getIndikatorStateFromScores(ergebnisse)
    return {
      indikatorId: group.indikatorId,
      ergebnisse: ergebnisse,
      state: state
    }
  })
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
