<template>
  <div class="loading-wrapper w-100 h-100" v-show="show">
    <div class="loading">
      <b-spinner variant="secondary"/>
      <p class="loading-message">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataProcessOverlay',
  props: {
    message: {
      type: String,
      default: 'Processing data...'
    }
  },
  data () {
    return {
      show: false
    }
  },
  created () {
    this.$eventHub.$on('DATA_PROCESS_EVENT', this.onDataProcessEvent)
    this.$eventHub.$on('DATA_PROCESS_EVENT_CANCELED', this.onDataProcessEventCanceled)
  },
  beforeDestroy () {
    this.$eventHub.$off('DATA_PROCESS_EVENT')
    this.$eventHub.$off('DATA_PROCESS_EVENT_CANCELED')
  },
  methods: {
    /**
     * Data process event handler
     * @param processing
     */
    onDataProcessEvent (processing) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (processing) {
        this.show = true
      } else {
        this.timeout = setTimeout(() => {
          this.show = false
        }, 1000)
      }
    },
    /**
     * Data process event canceled handler
     */
    onDataProcessEventCanceled () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.show = false
    }
  }
}
</script>
