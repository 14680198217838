import { JSONEditor } from '@json-editor/json-editor'

export const massnahmenvorschlag = (schema, value, path) => {
  const errors = []
  if (schema.options && schema.options.custom_validation && schema.options.custom_validation === 'massnahmenvorschlag') {
    // if phase is equals '1' or '2' then "umsetzung_bis" and "nachkontrolle_bis" can not be empty

    if (['1', '2'].includes(value.phase)) {
      if (value.umsetzung_bis.trim().length < 10) {
        errors.push({
          path: path + '.umsetzung_bis',
          property: 'format',
          message: JSONEditor.defaults.languages.de.error_notempty
        })
      }

      if (value.nachkontrolle_bis.trim().length < 10) {
        errors.push({
          path: path + '.nachkontrolle_bis',
          property: 'format',
          message: JSONEditor.defaults.languages.de.error_notempty
        })
      }
    }

    // "nachkontrolle_bis" can not be smaller than "umsetzung_bis"
    const dateDiffInDays = (a, b) => {
      // Discard the time and time-zone information.
      const _MS_PER_DAY = 1000 * 60 * 60 * 24
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
      return Math.floor((utc2 - utc1) / _MS_PER_DAY)
    }

    const umsetzungBisDate = new Date(value.umsetzung_bis)
    const nachkontrolleBisDate = new Date(value.nachkontrolle_bis)
    const today = new Date(Date.now())
    const diffTime = dateDiffInDays(umsetzungBisDate, nachkontrolleBisDate)
    const umsetzungTodayOffset = dateDiffInDays(today, umsetzungBisDate)
    const nachkontrolleTodayOffset = dateDiffInDays(today, nachkontrolleBisDate)

    if (diffTime < 0) {
      errors.push({
        path: path + '.nachkontrolle_bis',
        property: 'format',
        message: JSONEditor.defaults.languages.de.error_date_before
      })
    }

    if (umsetzungTodayOffset < 0) {
      errors.push({
        path: path + '.umsetzung_bis',
        property: 'format',
        message: JSONEditor.defaults.languages.de.error_date_before_today
      })
    }

    if (nachkontrolleTodayOffset < 0) {
      errors.push({
        path: path + '.nachkontrolle_bis',
        property: 'format',
        message: JSONEditor.defaults.languages.de.error_date_before_today
      })
    }
  }

  return errors
}

export const geburtenUndLanglebigkeit = (schema, value, path) => {
  const errors = []
  if (schema.options && schema.options.custom_validation && schema.options.custom_validation === 'geburten_und_langlebigkeit') {
    if (value.SGR > value.anz_geb) {
      errors.push({
        path: path + '.SGR',
        property: 'format',
        message: JSONEditor.defaults.languages.de.lessOrEqualThanGeburten
      })
    }

    if (value.TV_tot > value.anz_geb) {
      errors.push({
        path: path + '.TV_tot',
        property: 'format',
        message: JSONEditor.defaults.languages.de.lessOrEqualThanGeburten
      })
    }

    if (value.TV_adult > value.anz_alt) {
      errors.push({
        path: path + '.TV_adult',
        property: 'format',
        message: JSONEditor.defaults.languages.de.lessOrEqualThanVerendet
      })
    }

    if (value.anz_alt < JSONEditor.defaults.proq.herde.gesamt) {
      errors.push({
        path: path + '.anz_alt',
        property: 'format',
        message: JSONEditor.defaults.languages.de.greaterOrEqualThanGesamt
      })
    }

    if (value.LL > JSONEditor.defaults.proq.herde.gesamt) {
      errors.push({
        path: path + '.LL',
        property: 'format',
        message: JSONEditor.defaults.languages.de.lessOrEqualThanGesamt
      })
    }
  }

  return errors
}

export const liegeUndSteheverhaltenLiegeboxen = (schema, value, path) => {
  const errors = []
  if (schema.options && schema.options.custom_validation && schema.options.custom_validation === 'liege_und_steheverhalten_liegeboxen') {
    if (value && typeof value.LV_0 === 'number' && typeof value.LV_1 === 'number' && typeof value.LV_2 === 'number' && typeof value.LV_Gang === 'number') {
      const sum = value.LV_0 + value.LV_1 + value.LV_2 + value.LV_Gang
      const herde = JSONEditor.defaults.proq.herde
      const kontrolle = JSONEditor.defaults.proq.kontrolle
      let total
      if (kontrolle.typ === 'flexible') {
        total = (herde.leistungsgruppe) ? herde.fokusgruppe : herde.laktierend
      } else {
        total = herde.gesamt
      }
      if (!isNaN(sum) && sum > total) {
        errors.push({
          path: path,
          property: 'format',
          message: JSONEditor.defaults.languages.de.sumLessOrEqualThanStichprobeLiegeboxen
        })
      }
    }
  }

  return errors
}

export const liegeUndSteheverhaltenFreieflaeche = (schema, value, path) => {
  const errors = []
  if (schema.options && schema.options.custom_validation && schema.options.custom_validation === 'liege_und_steheverhalten_freieflaeche') {
    if (value && typeof value.liegend === 'number' && typeof value.liegend_laufweg === 'number') {
      const herde = JSONEditor.defaults.proq.herde
      const kontrolle = JSONEditor.defaults.proq.kontrolle
      let total
      if (kontrolle.typ === 'flexible') {
        total = (herde.leistungsgruppe) ? herde.fokusgruppe : herde.laktierend
      } else {
        total = herde.gesamt
      }
      const sum = value.liegend + value.liegend_laufweg
      if (!isNaN(sum) && sum > total) {
        errors.push({
          path: path,
          property: 'format',
          message: JSONEditor.defaults.languages.de.sumLessOrEqualThanStichprobeAnbindestallFreieflaeche
        })
      }
    }
  }

  return errors
}

export const liegeUndSteheverhaltenAnbindestall = (schema, value, path) => {
  const errors = []
  if (schema.options && schema.options.custom_validation && schema.options.custom_validation === 'liege_und_steheverhalten_anbindestall') {
    if (value && value.anbindestall && typeof value.anbindestall === 'number') {
      const herde = JSONEditor.defaults.proq.herde
      const kontrolle = JSONEditor.defaults.proq.kontrolle
      let total
      if (kontrolle.typ === 'flexible') {
        total = (herde.leistungsgruppe) ? herde.fokusgruppe : herde.laktierend
      } else {
        total = herde.gesamt
      }
      if (!isNaN(value.anbindestall) && value.anbindestall > total) {
        errors.push({
          path: path,
          property: 'format',
          message: JSONEditor.defaults.languages.de.sumLessOrEqualThanStichprobeAnbindestallFreieflaeche
        })
      }
    }
  }

  return errors
}
