import Vue from 'vue'
import App from './App.vue'

import router from './router'

import store from './store'
import { mapActions, mapGetters, mapState } from 'vuex'

import mixins from './mixins'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import Vuelidate from 'vuelidate'

import { localStorage } from './store/utils'

import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Herde from '@/store/modules/api/models/Herde'
import HerdePreset from '@/store/modules/api/models/HerdePreset'
import IndikatorGruppe from '@/store/modules/api/models/IndikatorGruppe'
import Indikator from '@/store/modules/api/models/Indikator'
import Frage from '@/store/modules/api/models/Frage'
import FrageXInfo from '@/store/modules/api/models/FrageXInfo'
import Antwort from '@/store/modules/api/models/Antwort'
import Ergebnis from './store/modules/api/models/Ergebnis'
import Tier from '@/store/modules/api/models/Tier'
import Info from '@/store/modules/api/models/Info'
import IndikatorXInfo from '@/store/modules/api/models/IndikatorXInfo'
import Datei from '@/store/modules/api/models/Datei'
import Massnahme from '@/store/modules/api/models/Massnahme'
import ErgebnisXInfo from '@/store/modules/api/models/ErgebnisXInfo'
import Ursachenanalyse from '@/store/modules/api/models/Ursachenanalyse'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

const LOG_SENTRY_EVENTS = process.env.VUE_APP_LOG_SENTRY_EVENTS

Sentry.init({
  beforeSend (event) {
    if (LOG_SENTRY_EVENTS === 'false') {
      console.log('* SENTRY_EVENT_LOG:', event)
      return null
    }
    return event
  },
  release: process.env.VUE_APP_PKG_NAME + '@' + process.env.VUE_APP_PKG_VERSION,
  dsn: 'https://693cf25452234d2dbf43139da3b819bc@sentry.hrzg.de/5',
  integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })]
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)

Vue.config.productionTip = true

/**
 * Global event bus
 * @type {Vue}
 */
Vue.prototype.$eventHub = new Vue()

/* global cordova IRoot device StatusBar AndroidFullScreen */
const app = new Vue({
  router,
  store,
  mixins: [mixins],
  data () {
    return {
      activeElement: null
    }
  },
  watch: {
    versionCode () {
      console.log('* VERSION_CODE: ', this.versionCode)
    },
    versionNumber () {
      console.log('* VERSION_NUMBER: ', this.versionNumber)
    }
  },
  computed: {
    /**
     * Map base state
     */
    ...mapState([
      'versionCode',
      'versionNumber',
      'connectionType',
      'connectionState'
    ]),
    /**
     * Map api state
     */
    ...mapState('api', [
      'apiUpdated'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'isJwtExpired',
      'isJwtRefreshable',
      'isAuthenticated',
      'hasInitialData'
    ]),
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * Detect online state, check connection type & state
     * @return {boolean|boolean}
     */
    isOnline () {
      return (this.connectionType !== 'none' && this.connectionState !== 'error')
    }
  },
  created () {
    this.init()
  },
  mounted () {
    window.addEventListener('storage', this.onStorageUpdate)
  },
  beforeDestroy () {
    window.removeEventListener('storage', this.onStorageUpdate)
  },
  methods: {
    /**
     * Map api actions
     */
    ...mapActions('api', [
      'getProfile'
    ]),
    /**
     * Vue app init, triggered from lifecycle hook "created"
     */
    async init () {
      console.log('** initializing...')
      console.log('* isOnline: ', this.isOnline)
      console.log('* isJwtExpired: ', this.isJwtExpired())
      console.log('* isJwtRefreshable: ', this.isJwtRefreshable())
      console.log('* isAuthenticated: ', this.isAuthenticated)
      console.log('* LOG_SENTRY_EVENTS', LOG_SENTRY_EVENTS)
      console.log('* VUE_APP_APP_MODE', this.$store.state.appMode)

      Sentry.captureMessage('Application Version: ' + this.versionNumber + ' (Build ' + this.versionCode + ') startup & initializing...', 'info')

      if (this.isOnline) {
        if (this.isJwtRefreshable()) {
          await this.$store.dispatch('api/refreshJwt')
        }

        await this.$store.dispatch('api/getChanges')
        console.log('API_UPDATED', this.apiUpdated)

        this.initStoreModels()

        console.log('HAS_INITIAL_DATA', this.hasInitialData)
        if (!this.hasInitialData) {
          const title = this.messages.global.fetchDataTitle
          const text = this.messages.global.fetchDataMessage
          const okTitle = this.messages.global.fetchDataOkBtn
          const cancelTitle = this.messages.global.fetchDataCancelBtn
          this.showConfirmModal(title, text, okTitle, cancelTitle)
            .then(async (response) => {
              if (response) {
                await this.fetchData()
                this.initStoreModels()
              }
            })
        } else {
          await this.fetchData()
          this.initStoreModels()
        }
      } else {
        this.showConnectionAlert()
      }

      // Add body class for app mode, app & web
      document.body.classList.add(this.$store.state.appMode)
    },
    /**
     * Initialize vuex-orm store models with persistent data from local storage
     */
    initStoreModels () {
      Kontrolle.insert({ data: localStorage.fetch('kontrolle') || [] })
      Herde.insert({ data: localStorage.fetch('herde') || [] })
      HerdePreset.insert({ data: localStorage.fetch('herdePreset') || [] })
      IndikatorGruppe.insert({ data: localStorage.fetch('indikatorGruppe') || [] })
      Indikator.insert({ data: localStorage.fetch('indikator') || [] })
      Frage.insert({ data: localStorage.fetch('frage') || [] })
      FrageXInfo.insert({ data: localStorage.fetch('frageXInfo') || [] })
      Antwort.insert({ data: localStorage.fetch('antwort') || [] })
      Ergebnis.insert({ data: localStorage.fetch('ergebnis') || [] })
      Tier.insert({ data: localStorage.fetch('tier') || [] })
      Info.insert({ data: localStorage.fetch('info') || [] })
      IndikatorXInfo.insert({ data: localStorage.fetch('indikatorXInfo') || [] })
      Datei.insert({ data: localStorage.fetch('datei') || [] })
      Massnahme.insert({ data: localStorage.fetch('massnahme') || [] })
      ErgebnisXInfo.insert({ data: localStorage.fetch('ergebnisXInfo') || [] })
      Ursachenanalyse.insert({ data: localStorage.fetch('ursachenanalyse') || [] })
    },
    /**
     * Fetch base, user & media data
     * @return {Promise<void>}
     */
    async fetchData () {
      if (this.$route.name !== 'kontrollen' && this.apiUpdated) {
        this.$router.push('kontrollen')
      }

      if (!this.hasInitialData || this.apiUpdated) {
        await this.fetchBaseData()
      }

      if (this.isAuthenticated && this.apiUpdated) {
        await this.fetchUserData()
      }

      if (this.isAuthenticated && !this.isJwtExpired()) {
        await this.getProfile()
      }

      if (Datei.all().length <= 1 && 'device' in window && device.platform !== 'browser') {
        this.fetchMediaFiles()
      }
    },
    /**
     * Cordova on device ready handler
     * @desc Initialize cordova & device specific plugins / features
     */
    onDeviceReady () {
      this.isDeviceRooted()
      this.addiOSCssClass()
      this.updateConnectionType()
      this.setAndroidSafeAreaInset()

      if ('StatusBar' in window) {
        StatusBar.hide()
      }

      this.$store.dispatch('setVersionCode')
      this.$store.dispatch('setVersionNumber')

      this.addDeviceEventListener()
    },
    /**
     * Add device event listener for focusin, pause / resume, online / offline & keyboard handling
     */
    addDeviceEventListener () {
      document.addEventListener('click', this.globalClickHandler)
      document.addEventListener('focusin', this.focusChanged)

      document.addEventListener('pause', this.onPause, false)
      document.addEventListener('resume', this.onResume, false)
      document.addEventListener('online', this.updateConnectionType, false)
      document.addEventListener('offline', this.updateConnectionType, false)

      if ('Keyboard' in window) {
        window.addEventListener('keyboardWillShow', this.keyboardWillShow)
        window.addEventListener('keyboardWillHide', this.keyboardWillHide)
      }

      // Only android device specific event handlers
      if (this.isAndroidDevice()) {
        // @see forceSystemUiToHide method and App.vue file
        window.addEventListener('APP_CONTENT_CLICKED', this.forceSystemUiToHide)
      }
    },
    /**
     * Show connection alert, depending on initial data available
     */
    showConnectionAlert () {
      if (this.hasInitialData) {
        this.showAlertModal(this.messages.alerts.attention, this.messages.alerts.offlineMsg, this.messages.alerts.ok, 'lg')
      } else {
        this.showAlertModal(this.messages.alerts.attention, this.messages.alerts.offlineAndNoDataMsg, this.messages.alerts.ok, 'lg')
      }
    },
    /**
     * Pause handler
     */
    onPause () {
      // console.log('onPause...', event)
    },
    /**
     * Resume handler
     */
    onResume () {
      setTimeout(async () => {
        console.log('*** ON_RESUME...')
        console.log('* isJwtExpired: ', this.isJwtExpired())
        console.log('* isJwtRefreshable: ', this.isJwtRefreshable())
        this.updateConnectionType()

        if (this.isOnline) {
          if (this.isJwtRefreshable()) {
            await this.$store.dispatch('api/refreshJwt')
          }

          if (this.isAuthenticated && !this.isJwtExpired()) {
            await this.getProfile()
          }

          // await this.$store.dispatch('api/getChanges')
          // console.log('API_UPDATED', this.apiUpdated)
          // this.fetchData()
        } else {
          this.showConnectionAlert()
        }
      }, 0)
    },
    /**
     * Updates the "connectionType" state & handle online / offline events
     */
    updateConnectionType () {
      const type = navigator.connection.type
      this.$store.commit('UPDATE_CONNECTION_TYPE', { type: type })

      if (this.connectionType === 'none' && this.connectionState === 'error') {
        this.showConnectionAlert()
      }
    },
    /**
     * This event fires before keyboard will be shown
     * Update content element padding and show system UI
     */
    keyboardWillShow () {
      // const content = this.$el.querySelector('.content')
      // content.style.padding = '0 16px 250px 16px'

      if (this.isAndroidDevice() && 'AndroidFullScreen' in window) {
        this.showSystemUI()
      }
    },
    /**
     * This event fires before keyboard will be closed
     * Reset content element padding and hide system UI
     */
    keyboardWillHide () {
      // const content = this.$el.querySelector('.content')
      // content.style.padding = '0 16px'

      if (this.isAndroidDevice() && 'AndroidFullScreen' in window) {
        this.hideSystemUI()
      }
    },
    /**
     * Force the android system ui (statusbar and navigation bar) to hide
     * @see Global content click event handler, in App.vue file
     */
    forceSystemUiToHide () {
      if ('AndroidFullScreen' in window) {
        this.hideSystemUI()
      }
      // @see https://github.com/apache/cordova-plugin-statusbar/issues/154#issuecomment-533282563
      if ('StatusBar' in window && window.StatusBar.isVisible) {
        window.StatusBar.show()
        setTimeout(() => {
          window.StatusBar.hide()
        }, 50)
      }
    },
    /**
     * Hides the system UI
     * Set the IMMERSIVE flag and the content to appear under the system bars so that the content doesn't resize when the system bars hide and show.
     */
    hideSystemUI () {
      AndroidFullScreen.setSystemUiVisibility(
        AndroidFullScreen.SYSTEM_UI_FLAG_LAYOUT_STABLE |
        AndroidFullScreen.SYSTEM_UI_FLAG_LAYOUT_HIDE_NAVIGATION |
        AndroidFullScreen.SYSTEM_UI_FLAG_LAYOUT_FULLSCREEN |
        AndroidFullScreen.SYSTEM_UI_FLAG_HIDE_NAVIGATION | // hide nav bar
        AndroidFullScreen.SYSTEM_UI_FLAG_FULLSCREEN | // hide status bar
        AndroidFullScreen.SYSTEM_UI_FLAG_IMMERSIVE)
    },
    /**
     * Shows the system UI
     * Removing all the flags except for the ones that make the content appear under the system bars.
     */
    showSystemUI () {
      AndroidFullScreen.setSystemUiVisibility(
        AndroidFullScreen.SYSTEM_UI_FLAG_LAYOUT_STABLE |
        AndroidFullScreen.SYSTEM_UI_FLAG_LAYOUT_HIDE_NAVIGATION |
        AndroidFullScreen.SYSTEM_UI_FLAG_LAYOUT_FULLSCREEN)
    },
    /**
     * Add "ios" class to body for specific css
     */
    addiOSCssClass () {
      if ('device' in window && device.platform === 'iOS') {
        document.body.classList.add('ios')
      }
    },
    /**
     * Detecting if the device was rooted (Android) or jailbreaked (iOS)
     */
    isDeviceRooted () {
      if ('IRoot' in window && 'device' in window && device.platform !== 'browser') {
        IRoot.isRooted(
          (result) => {
            if (result) {
              this.notifyAlert(this.messages.alerts.attention, this.messages.alerts.isRootedMsg, 'OK', () => {
                cordova.plugins.exit()
              })
            }
          },
          (error) => {
            console.error('IRoot: ' + error)
          }
        )
      }
    },
    /**
     * Set active element & scroll in view
     * @param event
     */
    focusChanged (event) {
      this.activeElement = event.target
      this.scrollIntoView()
    },
    /**
     * Scroll active element into view, depending on input type
     */
    scrollIntoView () {
      const inputs = ['text', 'email', 'password', 'number']
      if (inputs.includes(this.activeElement.type)) {
        setTimeout(() => {
          this.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }, 300)
      }
    },
    /**
     * Global click event handler
     * @desc handle click events for normal anchors in HTML content and opens it with "inAppBrowser" plugin
     * @param event
     */
    globalClickHandler (event) {
      if (event.target && (event.target.target === '_system' || event.target.target === '_blank')) {
        event.preventDefault()
        this.openInAppBrowser(event.target.href, '_system')
      }
    },
    /**
     * Local storage event handler
     * @param event
     */
    onStorageUpdate (event) {
      if (event.key === 'pro-q.jwt') {
        if (event.newValue === null) {
          this.$store.commit('api/SET_LOGIN_STATE', { value: false, reset: true })
          if (this.$route.name !== 'login') {
            this.$router.push('/')
          }
        } else {
          this.$router.go(0)
        }
      }
    },
    /**
     * Set css "--safe-area-top" variable, fix issue with css safe-area-insets in cordova-android >= 11
     * @see https://github.com/apache/cordova-android/issues/1465
     */
    setAndroidSafeAreaInset () {
      if ('device' in window && device.platform === 'Android' && 'AndroidNotch' in window) {
        window.AndroidNotch.hasCutout((cutout) => {
          if (cutout) {
            window.AndroidNotch.getInsetTop((insetSize) => {
              document.documentElement.style.setProperty('--safe-area-top', (insetSize - 10) + 'px')
            })
          }
        })
      }
    }
  },
  render: h => h(App)
}).$mount('#app')

document.addEventListener(
  'deviceready',
  app.onDeviceReady
)

window.app = app
