import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class ErgebnisXInfo extends Model {
  static entity = 'ergebnis-x-infos'

  static primaryKey = ['ergebnis_id', 'info_id']

  static fields () {
    return {
      ergebnis_id: this.uid(() => uuid()),
      info_id: this.uid(() => uuid())
    }
  }
}
