import { Model } from '@vuex-orm/core'
import uuid from 'uuid'
import Antwort from '@/store/modules/api/models/Antwort'
import Indikator from '@/store/modules/api/models/Indikator'
import Info from '@/store/modules/api/models/Info'
import FrageXInfo from '@/store/modules/api/models/FrageXInfo'

export default class Frage extends Model {
  static entity = 'fragen'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      indikator_id: this.uid(() => uuid()),
      frage_typ_id: this.uid(() => uuid()),
      text: this.string(''),
      antwort_schema: this.string(''),
      rang: this.number(null),
      meta_data: this.string(''),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      antworten: this.hasMany(Antwort, 'frage_id'),
      indikator: this.belongsTo(Indikator, 'indikator_id'),
      infos: this.belongsToMany(Info, FrageXInfo, 'frage_id', 'info_id')
    }
  }
}
