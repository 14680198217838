<template>
  <div class="produktionssystem">
    <b-row>
      <b-col>
        <h5>{{ messages.produktionssystem.headline }}</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="accordion">
          <b-button class="text-left mb-3" v-b-toggle.collapse-info variant="primary" block>
            {{ messages.produktionssystem.subline }}
            <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
          </b-button>
          <b-collapse id="collapse-info" class="mb-3">
            <b-card>
              <div v-for="(description, index) in getDescriptions()" :key="index">
                <div v-html="description.text"></div>
                <div v-for="(image, index) in description.images" :key="index">
                  <b-img :src="image.file" fluid center></b-img>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </b-col>
    </b-row>

    <b-btn v-if="showSyncBtn" size="sm" @click="prefill" class="mb-5" block>
      {{ this.messages.global.prefillBtn }}
    </b-btn>

    <b-row v-for="frage in produktionssystemFragen" :key="frage.id">
      <b-col>
        <div class="d-flex justify-content-between">
          <h6>{{ frage.text }}</h6>
        </div>
        <antwort :data="frage" ref="editors"></antwort>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <b-btn size="sm" @click="send" v-if="showSyncBtn" class="mb-5" block variant="success">
          {{ this.messages.results.transferToServer }} <b-icon icon="upload" class="ml-2"></b-icon>
        </b-btn>
        <b-btn size="sm" @click="next" v-if="!showSyncBtn" class="mb-5" block>
          {{ this.messages.results.nextStep }} <b-icon icon="arrow-right"></b-icon>
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { INFO_UUID_PRODUKTIONSSYSTEM, STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED } from '@/store/utils'
import Antwort from '@/components/kontrolle/Antwort'
import { mapGetters } from 'vuex'
import mixins from '@/mixins'
import Info from '@/store/modules/api/models/Info'

export default {
  name: 'Produktionssystem',
  mixins: [mixins],
  components: {
    Antwort
  },
  mounted () {
    this.setDisableState()
  },
  watch: {
    'kontrolle.status_sync' () {
      this.setDisableState()
    }
  },
  computed: {
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'produktionssystemFragen'
    ]),
    /**
     * Show / hide sync button
     * @return {boolean}
     */
    showSyncBtn () {
      return this.kontrolle.status_sync < STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED
    }
  },
  methods: {
    prefill () {
      const refEditors = this.$refs['editors']
      for (const ref in refEditors) {
        if (Object.prototype.hasOwnProperty.call(refEditors, ref)) {
          refEditors[ref].useLastFrageAntwortModel()
        }
      }
    },
    /**
     * Validation for all "Fragen / Antworten"
     * @return {boolean}
     */
    isValid () {
      const refEditors = this.$refs['editors']
      for (const ref in refEditors) {
        if (Object.prototype.hasOwnProperty.call(refEditors, ref)) {
          const editorComponent = refEditors[ref]
          console.log('value', editorComponent.getValue(), 'isValid', editorComponent.isValid())
          if (!editorComponent.isValid()) {
            return false
          }
        }
      }

      return true
    },
    /**
     * Send data to server via vuex api module action
     */
    send () {
      if (this.isValid()) {
        this.sendDataToServer(
          {
            kontrolle: this.kontrolle
          },
          STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED
        )
      } else {
        this.showAlertModal(this.messages.alerts.error, this.messages.alerts.fieldsToFill, this.messages.alerts.correction)
      }
    },
    next () {
      this.$router.push('/ursachenanalyse')
    },
    /**
     * Set entire json-editors form disable state
     */
    setDisableState () {
      if (this.kontrolle.status_sync >= STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED) {
        const refEditors = this.$refs['editors']
        for (const ref in refEditors) {
          if (Object.prototype.hasOwnProperty.call(refEditors, ref)) {
            if (refEditors[ref]) {
              refEditors[ref].disable()
            }
          }
        }
      }
    },
    /**
     * Get info descriptions
     * @return {Array|null}
     */
    getDescriptions () {
      const info = Info.query().whereId(INFO_UUID_PRODUKTIONSSYSTEM).first()
      return (info && info.json) ? this.parseAndMapDescriptions(info.json) : null
    }
  }
}
</script>
