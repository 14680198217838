<template>
  <div class="fertig w-100">
    <div class="text-center" v-if="showDoneView">
      <p class="checkmark mt-4">
        <i class="icon-circle-checkmark"></i>
      </p>

      <h1>{{ (!isNachkontrolle(kontrolle)) ? this.messages.done.headline : this.messages.done.recheckHeadline }}</h1>

      <p v-if="isAuthenticated">
        {{ (!isNachkontrolle(kontrolle)) ? this.messages.done.subline : this.messages.done.recheckSubline }}
      </p>
      <div v-else>
        <p>{{ this.messages.done.sublineUnregistered }}</p>
        <div class="accordion">
          <b-button class="text-left mb-3" v-b-toggle.collapse-info variant="primary" block>
            {{ getTitle() }}
            <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
          </b-button>
          <b-collapse id="collapse-info" class="mb-3">
            <b-card class="text-left">
              <div v-for="(description, index) in getDescriptions()" :key="index">
                <div v-html="description.text"></div>
                <div v-for="(image, index) in description.images" :key="index">
                  <b-img :src="image.file" fluid center></b-img>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </div>

      <p>
        <b-btn @click="show" size="sm" >
          {{ this.messages.done.result }}
        </b-btn>
      </p>

      <p v-if="isAuthenticated">oder</p>

      <p v-if="isAuthenticated">
        <b-btn @click="send" size="sm" variant="success">
          {{ this.messages.done.send }}
        </b-btn>
      </p>

    </div>

    <div class="text-center" v-else>
      <h1>{{ this.messages.done.headline2 }}</h1>

      <p class="my-3">
        <span class="icon-udder"></span>
      </p>

      <p>
        <b-badge pill :variant="result.state" class="py-2 px-3 rounded-lg">
          {{ result.headline }}
        </b-badge>
      </p>

      <p>
        {{ result.text }}
      </p>

      <b-btn @click="goto" size="sm">
        {{ this.messages.done.overview }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import {
  calculateKontrolleErgebnis,
  CONTROL_STATE_FINISHED,
  CONTROL_TYPE_TEATS,
  INFO_UUID_URSACHENANALYSE_UNREGISTERED,
  STATUS_SYNC_KONTROLLE_FINISHED
} from '@/store/utils'

import Antwort from '@/store/modules/api/models/Antwort'
import mixins from '@/mixins'
import { mapGetters } from 'vuex'
import { SET_SELECTED_KONTROLLE_ID } from '@/store/modules/api/mutation-types'
import Info from '@/store/modules/api/models/Info'

export default {
  name: 'Fertig',
  mixins: [mixins],
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {}
  },
  created () {
    this.finishControl()
  },
  computed: {
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'isAuthenticated',
      'antworten'
    ]),
    /**
     * Show if kontrolle "typ" is not "CONTROL_TYPE_TEATS" => "Zitzenkontrolle", otherwise show result view
     * @return {boolean}
     */
    showDoneView () {
      return this.kontrolle.typ !== CONTROL_TYPE_TEATS
    },
    /**
     * Show "Zitzenkontrolle" results
     * @return {{}}
     */
    result () {
      if (!this.kontrolle) {
        return false
      }

      const antwort = Antwort.query()
        .where('kontrolle_id', this.kontrolle.id)
        .first()

      if (!antwort) {
        return false
      }

      const data = JSON.parse(antwort.json)
      const sum = Object.values(data).filter(value => value === 'ZK_1').length

      const percent = (sum / 0.1)
      const isPositive = percent <= 20
      const state = (isPositive) ? 'success' : 'danger'
      const headline = (isPositive) ? this.messages.done.headlinePositive : this.messages.done.headlineNegative
      const text = (isPositive) ? this.messages.done.textPositive : this.messages.done.textNegative
      return {
        percent: percent,
        state: state,
        headline: headline,
        text: text
      }
    }
  },
  methods: {
    /**
     * Set kontrolle state to "FINISHED" & commit
     */
    finishControl () {
      this.kontrolle.status = CONTROL_STATE_FINISHED
      this.kontrolle.ergebnisse = calculateKontrolleErgebnis(this.kontrolle, this.antworten)
      this.$store.dispatch('api/updateKontrolle', this.kontrolle)
    },
    /**
     * Go to result view
     */
    show () {
      this.$router.push('/ergebnisse')
    },
    /**
     * Send data to server
     */
    send () {
      this.sendDataToServer(
        {
          kontrolle: this.kontrolle
        },
        STATUS_SYNC_KONTROLLE_FINISHED)
    },
    /**
     * Go to "/kontrollen" & reset selected kontrolle
     */
    goto () {
      this.$store.commit(`api/${SET_SELECTED_KONTROLLE_ID}`, null)
      this.$router.push('/kontrollen')
    },
    /**
     * Get description(s) from info's
     * @return {Array|null}
     */
    getDescriptions () {
      const info = Info.query()
        .whereId(INFO_UUID_URSACHENANALYSE_UNREGISTERED)
        .first()

      return (info && info.json) ? this.parseAndMapDescriptions(info.json) : null
    },
    /**
     * Get first title from description(s)
     * @return {*|string}
     */
    getTitle () {
      const descriptions = this.getDescriptions()
      return (descriptions[0] && descriptions[0].title) ? descriptions[0].title : ''
    }
  }
}
</script>
