import { Model } from '@vuex-orm/core'
import uuid from 'uuid'
import {
  CONTROL_STATE_CREATED,
  CONTROL_TYPE_STATIC,
  STATUS_SYNC_INITIAL
} from '@/store/utils'
import Antwort from '@/store/modules/api/models/Antwort'
import Tier from '@/store/modules/api/models/Tier'

export default class Kontrolle extends Model {
  static entity = 'kontrollen'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      hauptkontrolle_id: this.string(null).nullable(),
      herde_id: this.string(null).nullable(),
      name: this.string(''),
      typ: this.string(CONTROL_TYPE_STATIC),
      status: this.string(CONTROL_STATE_CREATED),
      status_sync: this.number(STATUS_SYNC_INITIAL),
      benutzer_erstellt_am: this.string(null).nullable(),
      benutzer_bearbeitet_am: this.string(null).nullable(),
      benutzer_synchronisiert_am: this.string(null).nullable(),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      antworten: this.hasMany(Antwort, 'kontrolle_id'),
      tiere: this.hasMany(Tier, 'kontrolle_id'),
      interruptedAt: this.attr({}),
      finished: this.attr([]),
      ergebnisse: this.attr([])
    }
  }
}
