<template>
  <div class="indikator-container">
    <b-button class="mb-3 text-left" block v-b-toggle="collapseId" :variant="indikatorData.state">
      {{ indikator.name }}
      <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
    </b-button>
    <b-collapse :id="collapseId" role="tabpanel">
      <div v-for="(antwort, index) in indikatorData.ergebnisse" :key="index">
        <div class="d-flex align-items-center">
          <b>{{ getFrageTextById(antwort.frageId) }}</b>
          <b-btn variant="link"
                 v-if="getInfoByFrageId(antwort.frageId)"
                 @click="openInfoModal(getInfoByFrageId(antwort.frageId), {})">
            <i class="icon-circle-info"></i>
          </b-btn>
        </div>
        <div v-for="(score, index) in antwort.scores" :key="index">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="m-1 font-italic">{{ score.label }}</p>
            </div>
            <div class="d-flex">
              <score class="mb-2" :value="score.value" :state="score.state"></score>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Score from '@/components/ergebnisse/Score.vue'
import Ergebnis from '../../store/modules/api/models/Ergebnis'
import ErgebnisXInfo from '../../store/modules/api/models/ErgebnisXInfo'
import IndikatorModel from '@/store/modules/api/models/Indikator'
import Frage from '../../store/modules/api/models/Frage'
import mixins from '@/mixins'
import uuid from 'uuid'

export default {
  name: 'Indikator',
  mixins: [mixins],
  components: {
    Score
  },
  props: {
    indikatorData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      collapseId: uuid()
    }
  },
  computed: {
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'herde'
    ]),
    indikator () {
      const model = IndikatorModel.find(this.indikatorData.indikatorId)

      if (!model) {
        return false
      }

      return model
    }
  },
  methods: {
    /**
     * Returns the Frage text by Frage Id
     * @param frageId
     * @return {*}
     */
    getFrageTextById (frageId) {
      return Frage.find(frageId).text
    },
    getInfoByFrageId (frageId) {
      const ergebnis = Ergebnis.query()
        .where('frage_id', frageId)
        .first()

      if (!ergebnis) {
        return false
      }

      const exi = ErgebnisXInfo.query()
        .where('ergebnis_id', ergebnis.id)
        .first()

      if (!exi) {
        return false
      }

      return exi.info_id
    }
  }
}
</script>
