import { Model } from '@vuex-orm/core'
import Frage from './Frage'
import Info from '@/store/modules/api/models/Info'
import IndikatorXInfo from './IndikatorXInfo'
import uuid from 'uuid'

export default class Indikator extends Model {
  static entity = 'indikatoren'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      indikator_gruppe_id: this.uid(() => uuid()),
      name: this.string(''),
      rang: this.number(0),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      fragen: this.hasMany(Frage, 'indikator_id'),
      infos: this.belongsToMany(Info, IndikatorXInfo, 'indikator_id', 'info_id')
    }
  }
}
