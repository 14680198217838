import * as types from './mutation-types'

import {
  calculateKontrolleErgebnis,
  CONTROL_STATE_FINISHED,
  CONTROL_TYPE_TEATS,
  fetchData,
  getMySQLDateString,
  STATUS_SYNC_DELETED,
  STATUS_SYNC_KONTROLLE_FINISHED
} from '../../utils'

import Herde from '@/store/modules/api/models/Herde'
import HerdePreset from '@/store/modules/api/models/HerdePreset'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Antwort from '@/store/modules/api/models/Antwort'
import Tier from '@/store/modules/api/models/Tier'

/* import {
  verifyJwt,
  localStorage
} from '../../utils' */

/**
 * Handler auth request result
 * @param response
 * @param commit
 * @returns {{token}|*}
 */
const handleAuthRequestResult = (response, commit) => {
  // console.log('HANDLE_AUTH_REQUEST_RESULT', response)
  if (response.data.success || response.data.access_token) {
    commit(types.AUTH_SUCCESS, response.data.message || response.data.access_token)
    return response.data
  } else {
    commit(types.AUTH_ERROR, response.data)
  }
}

/**
 * Get auth headers & additional if needed
 * @param state
 * @param additional
 * @returns {{Authorization: string}}
 */
const getHeaders = (state, additional = {}) => {
  return Object.assign({}, {
    Authorization: `Bearer ${state.jwt}`
  }, additional)
}

/**
 * Map / rename key "antworts" with "antworten"
 * @param obj
 * @return {{}}
 */
const mapKeys = (obj) => {
  let object = {}
  Object.keys(obj).forEach(key => {
    if (key === 'antworts') {
      let newKeyVal = { 'antworten': obj['antworts'] }
      object = { ...object, ...newKeyVal }
    } else if (key === 'tiers') {
      let newKeyVal = { 'tiere': obj['tiers'] }
      object = { ...object, ...newKeyVal }
    } else {
      object = { ...object, [key]: obj[key] }
    }
  })
  return object
}

/**
 * Generate "Kontrolle" name depending on "typ" & "hauptkontrolle_id"
 * @param kontrolle
 * @return {string}
 */
const generateKontrolleName = (kontrolle) => {
  const hash = kontrolle.id.substring(0, 4).toUpperCase()
  switch (kontrolle.typ) {
    case CONTROL_TYPE_TEATS:
      return `Zitzenkontrolle #${hash}`
    default:
      if (kontrolle.hauptkontrolle_id) {
        return `Nachkontrolle #${hash}`
      }
      return `Kontrolle #${hash}`
  }
}

const actions = {
  /**
   * API login action
   * @param commit
   * @param getters
   * @param state
   * @param rootGetters
   * @param credentials
   * @return {boolean}
   */
  async login ({ commit, getters, state }, credentials) {
    commit(types.AUTH_REQUEST)

    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('password', credentials.password)

    const options = {
      method: 'POST',
      body: formData
    }

    try {
      const response = await fetchData(getters.loginUrl, options)
      const result = handleAuthRequestResult(response, commit)
      if (result.access_token) {
        commit(types.SET_LOGIN_STATE, { value: true, reset: false })
        commit(types.SET_JWT_STATE, result.access_token)
        commit(types.SET_USER_STATE, getters.jwtClaims)
        return true
      }
    } catch (error) {
      commit(types.AUTH_ERROR, error)
    }
  },
  /**
   * API register action
   * @param commit
   * @param getters
   * @param state
   * @param data
   * @return {boolean}
   */
  async register ({ commit, getters, state }, data) {
    commit(types.AUTH_REQUEST)

    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('forename', data.forename)
    formData.append('company', data.company)
    formData.append('street', data.street)
    formData.append('zip', data.zip)
    formData.append('location', data.location)
    formData.append('county', data.county)
    formData.append('email', data.email)
    formData.append('username', data.username)
    formData.append('password', data.password)

    const options = {
      method: 'POST',
      body: formData
    }

    try {
      const response = await fetchData(getters.registerUrl, options)
      const result = handleAuthRequestResult(response, commit)
      if (result) {
        return result
      }
    } catch (error) {
      commit(types.AUTH_ERROR, error)
    }
  },
  /**
   * Refresh JWT
   * @param commit
   * @param getters
   * @param state
   * @returns {Promise<{token}|any>}
   */
  async refreshJwt ({ commit, getters, state }) {
    commit(types.AUTH_REQUEST)

    const options = {
      method: 'POST',
      headers: getHeaders(state)
    }

    try {
      const response = await fetchData(getters.refreshUrl, options)
      const result = handleAuthRequestResult(response, commit)
      if (result.access_token) {
        commit(types.SET_LOGIN_STATE, { value: true, reset: false })
        commit(types.SET_JWT_STATE, result.access_token)
        commit(types.SET_USER_STATE, getters.jwtClaims)
        return true
      }
    } catch (error) {
      commit(types.AUTH_ERROR, error)
    }
  },
  /**
   * Get user profile
   * @param commit
   * @param getters
   * @param state
   * @returns {Promise<void>}
   */
  async getProfile ({ commit, getters, state }) {
    commit(types.AUTH_REQUEST)

    const options = {
      method: 'POST',
      headers: getHeaders(state)
    }

    try {
      const response = await fetchData(getters.profileUrl, options)
      const result = handleAuthRequestResult(response, commit)
      if (result.profile) {
        commit(types.SET_PROFILE_STATE, result.profile)
        return result.profile
      }
    } catch (error) {
      commit(types.AUTH_ERROR, error)
    }
  },
  /**
   * Get data from public endpoint via api request, if "x-pagination-page-count" is set fetch next page(s)
   * @param commit
   * @param state
   * @param payload
   * @returns {Promise<any>}
   */
  async fetchEndpoint ({ commit, state }, payload) {
    commit(types.REQUEST_LOADING)

    const url = payload.url || this.getters['api/' + payload.endpoint + 'Url']
    const options = {
      method: 'GET'
    }

    try {
      const response = await fetchData(url, options)
      commit(types.REQUEST_SUCCESS, { type: payload.endpoint, data: response.data, refresh: payload.refresh || false, success: !response.data.length || response.success })
    } catch (error) {
      commit(types.REQUEST_ERROR, error)
    }
  },
  /**
   * Get api changes
   * @param commit
   * @param getters
   * @param state
   * @return {Promise<void>}
   */
  async getChanges ({ commit, getters, state }) {
    commit(types.REQUEST_LOADING)

    const options = {
      method: 'GET'
    }

    try {
      const response = await fetchData(getters.changedUrl, options)
      commit(types.REQUEST_SUCCESS, { type: 'changed', data: response.data, success: response.success })
    } catch (error) {
      commit(types.REQUEST_ERROR, error)
    }
  },
  /**
   * Create new "Kontrolle" via vuex-orm insert method
   * @param commit
   * @param getters
   * @param payload
   */
  createKontrolle ({ commit, getters }, payload) {
    const kontrolle = new Kontrolle()
    kontrolle.benutzer_erstellt_am = getMySQLDateString()

    if (payload && payload.type) {
      kontrolle.typ = payload.type
    }

    if (payload && payload.hauptkontrolleId) {
      kontrolle.hauptkontrolle_id = payload.hauptkontrolleId
      kontrolle.herde_id = Kontrolle.query().find(payload.hauptkontrolleId).herde_id
    }

    kontrolle.name = generateKontrolleName(kontrolle)

    return Kontrolle.insert({ data: kontrolle })
      .then((result) => {
        console.log('KONTROLLE_CREATED', result)
        commit(types.SAVE_KONTROLLE_STATE)
        commit(types.SET_SELECTED_KONTROLLE_ID, kontrolle.id)
      })
  },
  /**
   * Update "Kontrolle" via vuex-orm insertOrUpdate method
   * @param commit
   * @param kontrolle
   */
  updateKontrolle ({ commit }, kontrolle) {
    kontrolle.benutzer_bearbeitet_am = getMySQLDateString()

    // Note: clone model & set "antworten" & "tiere" to empty array to prevent inserting / updating nested relations
    const model = Object.assign({}, kontrolle)
    model.antworten = []
    model.tiere = []

    Kontrolle.update({ data: model })
      .then((result) => {
        console.log('KONTROLLE_UPDATED', result)
        commit(types.SAVE_KONTROLLE_STATE)
        commit(types.SET_SELECTED_KONTROLLE_ID, model.id)
      })
  },
  /**
   * Delete "Kontrolle" via vuex-orm delete method
   * @param dispatch
   * @param commit
   * @param kontrolle
   */
  async deleteKontrolle ({ dispatch, commit }, kontrolle) {
    if (kontrolle.benutzer_synchronisiert_am === null) {
      return Kontrolle.delete(kontrolle.id)
        .then((result) => {
          console.log('KONTROLLE_DELETED', result)
          return dispatch('deleteKontrolleRelations', kontrolle)
        })
        .then(() => {
          commit(types.SAVE_KONTROLLE_STATE)
          commit(types.SET_SELECTED_KONTROLLE_ID, null)
        })
    } else {
      try {
        await dispatch('deleteKontrolleRelations')
        await dispatch('pushControl', { id: kontrolle.id, statusSync: STATUS_SYNC_DELETED })

        const nachkontrolle = Kontrolle.query().where('hauptkontrolle_id', kontrolle.id).withAll().first()
        if (nachkontrolle) {
          await dispatch('deleteKontrolleRelations', nachkontrolle)
          await dispatch('pushControl', { id: nachkontrolle.id, statusSync: STATUS_SYNC_DELETED })
        }

        commit(types.SAVE_KONTROLLE_STATE)
      } catch (error) {
        console.error(error)
      } finally {
        commit(types.SET_SELECTED_KONTROLLE_ID, null)
      }
    }
  },
  /**
   * Delete "Kontrolle" model relations
   * @param dispatch
   * @param commit
   * @param getters
   * @param state
   * @return {Promise<void>}
   */
  async deleteKontrolleRelations ({ dispatch, commit, getters, state }) {
    console.log('DELETE_KONTROLLE_RELATIONS', state.kontrolleId, getters.antworten, getters.tiere)

    if (getters.antworten.length) {
      const antwortIds = getters.antworten.map(antwort => antwort.id)
      await Antwort.delete((antwort) => antwortIds.includes(antwort.id))
    }

    if (getters.tiere.length) {
      const tierIds = getters.tiere.map(tier => tier.id)
      await Tier.delete((tier) => tierIds.includes(tier.id))
    }

    commit(types.SAVE_ANTWORT_STATE)
    commit(types.SAVE_TIER_STATE)
  },
  /**
   * Insert or update "HerdePreset" via vuex-orm insertOrUpdate method
   * @param commit
   * @param preset
   */
  insertOrUpdateHerdePreset ({ commit }, preset) {
    HerdePreset.insertOrUpdate({ data: preset })
      .then((result) => {
        console.log('HERDE_PRESET_INSERTED_OR_UPDATED', result)
        commit(types.SAVE_HERDE_PRESET_STATE)
      })
  },
  /**
   * Delete "HerdePreset" via vuex-orm delete method
   * @param commit
   * @param herde
   */
  deleteHerdePreset ({ commit }, herde) {
    HerdePreset.delete(herde.id)
      .then((result) => {
        console.log('HERDE_PRESET_DELETED', result)
        commit(types.SAVE_HERDE_PRESET_STATE)
      })
  },
  /**
   * Insert or update "Herde" via vuex-orm insertOrUpdate method
   * @param commit
   * @param herde
   */
  insertOrUpdateHerde ({ commit }, herde) {
    if (!herde.benutzer_erstellt_am) {
      herde.benutzer_erstellt_am = getMySQLDateString()
    }

    herde.benutzer_bearbeitet_am = getMySQLDateString()

    Herde.insertOrUpdate({ data: herde })
      .then((result) => {
        console.log('HERDE_INSERTED_OR_UPDATED', result)
        commit(types.SAVE_HERDE_STATE)
      })
  },
  /**
   * Insert or update "Antwort" via vuex-orm insertOrUpdate method
   * @param commit
   * @param antwort
   */
  insertOrUpdateAntwort ({ commit }, antwort) {
    // const t0 = performance.now()
    if (!antwort.benutzer_erstellt_am) {
      antwort.benutzer_erstellt_am = getMySQLDateString()
    }

    antwort.benutzer_bearbeitet_am = getMySQLDateString()

    Antwort.insertOrUpdate({ data: antwort })
      .then((result) => {
        console.log('ANTWORT_INSERTED_OR_UPDATED', result)
        // const t1 = performance.now()
        // console.log('initJsonEditor', 'Took', (t1 - t0).toFixed(4), 'milliseconds')
        commit(types.SAVE_ANTWORT_STATE)
      })
  },
  /**
   * Delete "Antwort" via vuex-orm delete method
   * @param commit
   * @param antwort
   */
  deleteAntwort ({ commit }, antwort) {
    Antwort.delete(antwort.id)
      .then((result) => {
        console.log('ANTWORT_DELETED', result)
        commit(types.SAVE_ANTWORT_STATE)
      })
  },
  /**
   * Insert or update "Tier" via vuex-orm insertOrUpdate method
   * @param commit
   * @param tier
   */
  insertOrUpdateTier ({ commit }, tier) {
    Tier.insertOrUpdate({ data: tier })
      .then((result) => {
        console.log('TIER_INSERTED_OR_UPDATED', result)
        commit(types.SAVE_TIER_STATE)
        commit(types.SET_CURRENT_TIER, result.tiere[0])
      })
  },
  /**
   * Delete "Tier"
   * @param commit
   * @param tier
   */
  deleteTier ({ commit }, tier) {
    Tier.delete(tier.id)
      .then((result) => {
        console.log('TIER_DELETED', result)
        commit(types.SAVE_TIER_STATE)
      })
  },
  /**
   * Push "Herde" to server
   * @param dispatch
   * @param commit
   * @param getters
   * @param state
   * @param id
   */
  async pushHerde ({ dispatch, commit, getters, state }, id) {
    // console.log('pushHerde', id)
    const herde = Herde.query().whereId(id).first()
    herde.user_uuid = getters.jwtClaims.uuid
    herde.benutzer_synchronisiert_am = getMySQLDateString()

    commit(types.REQUEST_LOADING)

    let options = {
      method: 'POST',
      headers: getHeaders(state, { 'Content-Type': 'application/json' }),
      body: JSON.stringify(herde)
    }

    try {
      const response = await fetchData(getters.herdeUrl, options)
      commit(types.REQUEST_SUCCESS, { type: 'push', data: response, refresh: false, success: response.success })
      dispatch('insertOrUpdateHerde', herde)
    } catch (error) {
      commit(types.REQUEST_ERROR, error)
    }
  },
  /**
   * Push "Kontrolle" to server
   * @param dispatch
   * @param commit
   * @param getters
   * @param state
   * @param payload
   */
  async pushControl ({ dispatch, commit, getters, state }, payload) {
    // console.log('pushControl', payload.id, payload.statusSync)
    const kontrolle = Kontrolle.query()
      .with('antworten|tiere')
      .whereId(payload.id)
      .first()

    kontrolle.benutzer_synchronisiert_am = getMySQLDateString()
    kontrolle.status_sync = payload.statusSync

    commit(types.REQUEST_LOADING)

    let url = getters.kontrolleUrl
    const options = {
      method: 'POST',
      headers: getHeaders(state, { 'Content-Type': 'application/json' }),
      body: JSON.stringify(kontrolle)
    }

    if (kontrolle.status_sync > STATUS_SYNC_KONTROLLE_FINISHED) {
      url += `/${kontrolle.id}`
      options.method = 'PUT'
    }

    try {
      const response = await fetchData(url, options)
      commit(types.REQUEST_SUCCESS, { type: 'push', data: response, refresh: false, success: response.success })
      dispatch('updateKontrolle', kontrolle)
    } catch (error) {
      commit(types.REQUEST_ERROR, error)
    }
  },
  /**
   * Fetch user data
   * @param commit
   * @param getters
   * @param state
   * @return {Promise<void>}
   */
  async fetchUserData ({ commit, getters, state }) {
    const uuid = getters.jwtClaims.uuid
    const url = `${getters.herdeUrl}?filter[user_uuid]=${uuid}`
    const options = {
      method: 'GET',
      headers: getHeaders(state, { 'Content-Type': 'application/json' })
    }

    try {
      commit(types.REQUEST_LOADING)

      const response = await fetchData(url, options)
      commit(types.SET_SYNC_PROGRESS_STEPS_TOTAL, response.data)

      for (const item of response.data) {
        const url = `${getters.kontrolleUrl}?filter[herde_id]=${item.id}&expand=antworts.frage.indikator,tiers`

        const response = await fetchData(url, options)
        state.syncProgressSteps++

        const kontrollen = response.data
          .filter(kontrolle => kontrolle.status_sync !== 80)
          .map(kontrolle => mapKeys(kontrolle))

        if (kontrollen.length) {
          const herde = new Herde(item)
          herde.kontrollen.push(...kontrollen)
          await Herde.insertOrUpdate({ data: herde })

          if (!HerdePreset.exists()) {
            const preset = new HerdePreset(herde)
            await HerdePreset.insertOrUpdate({ data: preset })
          }

          kontrollen.forEach(kontrolle => {
            if (kontrolle.status === CONTROL_STATE_FINISHED && kontrolle.status_sync !== STATUS_SYNC_DELETED) {
              kontrolle.ergebnisse = calculateKontrolleErgebnis(kontrolle, kontrolle.antworten)
              Kontrolle.insertOrUpdate({ data: kontrolle })
            }
          })
        }

        const deleted = response.data
          .filter(kontrolle => kontrolle.status_sync === 80)
          .map(kontrolle => kontrolle.id)

        if (deleted.length) {
          Kontrolle.delete(kontrolle => deleted.includes(kontrolle.id))
          Antwort.delete(antwort => deleted.includes(antwort.kontrolle_id))
          Tier.delete(tier => deleted.includes(tier.kontrolle_id))
        }
      }

      commit(types.SAVE_HERDE_STATE)
      commit(types.SAVE_HERDE_PRESET_STATE)
      commit(types.SAVE_KONTROLLE_STATE)
      commit(types.SAVE_ANTWORT_STATE)
      commit(types.SAVE_TIER_STATE)

      commit(types.SET_SELECTED_KONTROLLE_ID, null)

      commit(types.REQUEST_SUCCESS, { type: 'fetch', data: response, refresh: false, success: response.success })
      commit(types.SET_SYNC_PROGRESS_STEPS_TOTAL, [])
    } catch (error) {
      commit(types.REQUEST_ERROR, error)
    }
  }
}

export default actions
