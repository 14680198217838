<template>
  <div class="indikator w-100">
    <b-row>
      <b-col>
        <h5>{{ title }}</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="accordion" v-if="infos">
          <div v-for="info in infos" :key="info.id">
            <b-button class="text-left mb-3" v-b-toggle="'collapse-' + info.id" variant="primary" block>
              {{ getTitle(info) }}
              <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
            </b-button>
            <b-collapse :id="'collapse-' + info.id" class="mb-3">
              <b-card no-body>
                <b-row v-for="(description, index) in getDescriptions(info)" :key="index" no-gutters>
                  <b-col cols="4" class="p-2" v-if="description.images && description.images.length">
                    <div v-for="(image, index) in description.images" :key="index">
                      <b-card-img :src="image.file"></b-card-img>
                    </div>
                  </b-col>
                  <b-col :cols="description.images && description.images.length ? '8' : '12'" class="p-2" v-html="description.text">
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-for="frage in data.fragen" :key="frage.id">
      <b-col>
        <div class="d-flex justify-content-between">
          <h6>
            {{ frage.text }}
            <b-btn variant="link" @click="showInfo(frage)" v-if="hasInfos(frage)">
              <i class="icon-circle-info"></i>
            </b-btn>
          </h6>
          <div v-if="hasTimerOption(frage)">
            <timer-button :frage="frage"
                          label="Timer starten"/>
          </div>
        </div>
        <antwort :ref="frage.id" :data="frage"></antwort>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TimerButton from '@/components/kontrolle/TimerButton'
import Antwort from '@/components/kontrolle/Antwort'
import mixins from '@/mixins'

export default {
  name: 'Indikator',
  mixins: [mixins],
  components: {
    TimerButton,
    Antwort
  },
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {}
  },
  computed: {
    title () {
      return (this.$store.state.api.tier === null) ? this.data.name : `${this.data.name} - ${this.$store.state.api.tier.name}`
    },
    infos () {
      return (this.data.infos.length) ? this.data.infos : false
    }
  },
  methods: {
    /**
     * Is view valid
     * @return {boolean}
     */
    isValid () {
      let valid = true
      for (const el in this.$refs) {
        if (this.$refs[el][0] && !this.$refs[el][0].isValid()) {
          valid = false
        }
      }
      return valid
    },
    /**
     * Get info title
     * @param info
     * @return {string}
     */
    getTitle (info) {
      const json = JSON.parse(info.json)
      return json.title
    },
    /**
     * Get info descriptions
     * @param info
     * @return {string}
     */
    getDescriptions (info) {
      return this.parseAndMapDescriptions(info.json)
    },
    /**
     * Has antwort schema timer option
     * @param data
     * @return {boolean}
     */
    hasTimerOption (data) {
      let schema = JSON.parse(data.antwort_schema)
      return (schema.options && schema.options.timer)
    },
    /**
     * Has "Info" relations
     * @param frage
     * @return {boolean}
     */
    hasInfos (frage) {
      return (Array.isArray(frage.infos) && frage.infos[0])
    },
    /**
     * Opens the info modal
     * @param frage
     */
    showInfo (frage) {
      if (this.hasInfos(frage)) {
        this.openInfoModal(frage.infos[0])
      }
    }
  }
}
</script>
