<template>
  <div class="kontrolle">
    <info @start-control="startControl"/>

    <div class="control-header mb-3 d-flex align-items-center" v-if="showControlHeader">
      <indikator-progress-bar :active-component="componentData"/>
    </div>

    <div class="mb-3 d-flex justify-content-end" v-if="showControlHeader">
      <b-btn to="/kontrollen" router-tag="button" size="sm" class="d-flex align-items-center">
        <span class="icon-pause"></span> {{ messages.control.interruptBtn }}
      </b-btn>
    </div>

    <b-card no-body v-if="!showInfo">
      <b-list-group flush>
        <b-list-group-item class="d-flex align-items-center px-3">
          <keep-alive>
            <component ref="currentComponentName"
                       v-bind:is="currentComponentName"
                       :data="componentData"
                       @next="next">
            </component>
          </keep-alive>
        </b-list-group-item>

        <b-list-group-item class="text-danger" v-if="errorSummary.length">
          <label>{{ messages.control.errors }}</label>
          <ul>
            <li v-for="(error, index) in errorSummary" :key="index">{{ error }}</li>
          </ul>
        </b-list-group-item>

        <b-list-group-item class="d-flex align-content-center px-3">
          <b-btn @click="back" size="sm" class="mr-auto" v-if="showPrevButton" variant="light">
            <b-icon icon="chevron-left"></b-icon> {{ messages.control.backBtn }}
          </b-btn>
          <b-btn @click="finish" size="sm" block v-if="showFinishButton">
            {{ messages.control.finishBtn }}
          </b-btn>
          <b-btn @click="next" size="sm" class="ml-auto" v-if="showNextButton && currentComponentName !== tierComponentName">
            {{ messages.control.nextBtn }} <b-icon icon="chevron-right"></b-icon>
          </b-btn>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import {
  sleep,
  KONTROL_VALIDATION_SLEEP_TIME,
  CONTROL_STATE_FINISHED,
  CONTROL_STATE_INTERRUPTED,
  CONTROL_TYPE_FLEXIBLE,
  CONTROL_TYPE_STATIC,
  CONTROL_TYPE_TEATS,
  INDIKATOR_GRUPPE_UUID_EINZELKUH,
  INDIKATOR_GRUPPE_UUID_HERDE,
  INDIKATOR_GRUPPE_UUID_HERDE2,
  INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE
} from '@/store/utils'

import {
  EventBus,
  EVENT_ON_JSONEDITOR_CHANGE
} from '@/event-bus'

import Info from '@/components/kontrolle/Info'
import Herde from '@/components/kontrolle/Herde'
import IndikatorProgressBar from '@/components/kontrolle/IndikatorProgressBar'
import IndikatorAuswahl from '@/components/kontrolle/IndikatorAuswahl'
import Indikator from '@/components/kontrolle/Indikator'
import Tier from '@/components/kontrolle/Tier'
import Fertig from '@/components/kontrolle/Fertig'

import IndikatorGruppe from '@/store/modules/api/models/IndikatorGruppe'
import IndikatorModel from '@/store/modules/api/models/Indikator'
import TierModel from '@/store/modules/api/models/Tier'
import Antwort from '@/store/modules/api/models/Antwort'

import { SET_CURRENT_TIER, SAVE_ANTWORT_STATE, SAVE_TIER_STATE } from '@/store/modules/api/mutation-types'
import mixins from '@/mixins'

export default {
  name: 'Kontrolle',
  mixins: [mixins],
  components: {
    Info,
    Herde,
    IndikatorProgressBar,
    IndikatorAuswahl,
    Indikator,
    Tier,
    Fertig
  },
  data () {
    return {
      showInfo: this.$store.state.api.showInfoAgain !== false,
      currentIndex: 0,
      errorSummary: [],
      observer: null
    }
  },
  /**
   * Component route guard
   * @desc handle view before leave & show confirm window if "kontrolle" started
   * @param to
   * @param from
   * @param next
   */
  beforeRouteLeave (to, from, next) {
    if (this.kontrolle && this.kontrolle.status !== CONTROL_STATE_FINISHED) {
      if (this.kontrolle.typ === CONTROL_TYPE_TEATS) {
        this.interruptControl()
        next()
        return false
      }

      const title = this.messages.control.blcTitle
      const text = this.messages.control.blcText
      const okTitle = this.messages.control.blcOkBtn
      const cancelTitle = this.messages.control.blcCancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle, 'lg')
        .then(response => {
          if (response) {
            this.interruptControl()
            next()
          }
        })
    } else {
      if (this.observer) {
        this.observerDisconnect()
      }
      next()
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$eventHub.$emit('DATA_PROCESS_EVENT', false)
    })
  },
  watch: {
    currentIndex () {
      this.viewScrollTop()

      if (this.observer) {
        this.observerDisconnect()
      }
      if (this.currentComponentName === 'Indikator') {
        this.observerObserve()
      }
    }
  },
  created () {
    EventBus.$on(EVENT_ON_JSONEDITOR_CHANGE, this.refreshErrorSummary)
    if (this.kontrolle) {
      if (this.kontrolle.typ === CONTROL_TYPE_TEATS) {
        if (this.kontrolle.status === CONTROL_STATE_FINISHED) {
          this.currentIndex = this.controlComponents.findIndex(component => component.name === 'Fertig')
        } else {
          this.currentIndex = 3
        }
      }
      if (this.isNachkontrolle(this.kontrolle)) {
        this.currentIndex = 1
      }
      if (this.kontrolle.interruptedAt.index) {
        this.currentIndex = this.kontrolle.interruptedAt.index
      }
    }
  },
  computed: {
    /**
     * Map api module state
     */
    ...mapState('api', [
      'tier'
    ]),
    /**
     * Map help module getters
     */
    ...mapGetters('help', [
      'messages',
      'message'
    ]),
    ...mapGetters('api', [
      'tiere',
      'tierComponentName',
      'antworten'
    ]),
    /**
     * Dynamic components
     * @return {*[]}
     */
    controlComponents () {
      return [
        {
          name: 'Herde'
        },
        {
          name: 'IndikatorAuswahl',
          indikatorGruppen: this.indikatorGruppen.filter(gruppe => gruppe && gruppe.id !== INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE)
        },
        ...this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_HERDE),
        {
          name: this.tierComponentName,
          indikatoren: [...this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_EINZELKUH)]
        },
        ...this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_EINZELKUH),
        ...this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_HERDE2),
        ...this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE),
        {
          name: 'Fertig'
        }
      ]
    },
    /**
     * Current component name
     * @desc if dynamic component is instance of 'Indikator' model use 'Indikator' component otherwise use component name
     * @return {string|*}
     */
    currentComponentName () {
      const component = this.controlComponents[this.currentIndex]
      return (component instanceof IndikatorModel) ? 'Indikator' : component.name
    },
    /**
     * Get dynamic component data by "currentIndex"
     * @return {*}
     */
    componentData () {
      return this.controlComponents[this.currentIndex]
    },
    /**
     * Prev button visible state
     * @return {boolean}
     */
    showPrevButton () {
      // Is the "Einzeltiererhebung" not finished, disable button to prevent navigate back to "Tier" input component
      const isEinzeltiererhebungNotFinished = !(this.isEinzeltiererhebung() &&
                                              !this.isEinzelkuhControlFinished() &&
                                              (this.controlComponents.findIndex(component => component.name === this.tierComponentName) + 1) === this.currentIndex)

      return isEinzeltiererhebungNotFinished &&
             this.kontrolle && this.kontrolle.typ !== CONTROL_TYPE_TEATS &&
             ((this.kontrolle.typ === CONTROL_TYPE_FLEXIBLE && this.currentIndex > 1) || (this.kontrolle.typ === CONTROL_TYPE_STATIC && this.currentIndex > 2)) &&
             this.showNextButton
    },
    /**
     * Next button visible state
     * @return {boolean}
     */
    showNextButton () {
      return this.kontrolle &&
             this.kontrolle.typ !== CONTROL_TYPE_TEATS &&
             this.currentIndex < this.controlComponents.length - 1
    },
    /**
     * Show kontrolle header only in "Hauptkontrolle" views
     * @return {boolean}
     */
    showControlHeader () {
      return this.kontrolle &&
             this.kontrolle.typ !== CONTROL_TYPE_TEATS &&
             this.currentComponentName !== 'Fertig' &&
             !this.showInfo
    },
    /**
     * Show finish kontrolle button only on "Zitzenkontrolle" view
     * @return {boolean}
     */
    showFinishButton () {
      return this.kontrolle &&
             this.kontrolle.typ === CONTROL_TYPE_TEATS &&
             this.currentComponentName !== 'Fertig'
    }
  },
  methods: {
    refreshErrorSummary () {
      if (this.showNextButton && this?.$refs['currentComponentName']?.isValid()) {
        this.errorSummary = []
      } else {
        this.errorSummary = this.getErrors()
      }
    },
    /**
     * Start kontrolle & hide info component
     */
    startControl () {
      this.showInfo = false
      this.viewScrollTop()
    },
    /**
     * Commit "SET_CURRENT_TIER" mutation & dispatch "updateKontrolle" action
     */
    updateControl () {
      if (this.tier !== null) {
        this.$store.commit(`api/${SET_CURRENT_TIER}`, null)
      }

      this.$store.dispatch('api/updateKontrolle', this.kontrolle)
    },
    /**
     * Set kontrolle state to "INTERRUPTED" & update kontrolle
     */
    async interruptControl () {
      this.kontrolle.status = CONTROL_STATE_INTERRUPTED
      this.kontrolle.interruptedAt = {
        index: this.currentIndex,
        tierId: null
      }

      if (this.isEinzeltiererhebung() && this.tier) {
        await Antwort.delete((antwort) => antwort.tier_id === this.tier.id)
        await TierModel.delete((tier) => tier.id === this.tier.id)

        this.kontrolle.finished = this.kontrolle.finished.filter(value => value !== this.tier.id)

        this.kontrolle.interruptedAt = {
          index: this.controlComponents.findIndex(component => component.name === this.tierComponentName),
          tierId: null
        }

        this.$store.commit(`api/${SAVE_ANTWORT_STATE}`)
        this.$store.commit(`api/${SAVE_TIER_STATE}`)
        this.$store.commit(`api/${SET_CURRENT_TIER}`, null)
      }

      if (this.observer) {
        this.observerDisconnect()
      }

      this.updateControl()
    },
    /**
     * Set kontrolle state to "FINISHED" & update kontrolle
     */
    finishControl () {
      this.kontrolle.status = CONTROL_STATE_FINISHED
      this.updateControl()
    },
    /**
     * Decrement "currentIndex"
     */
    back () {
      (this.kontrolle && this.kontrolle.typ === CONTROL_TYPE_STATIC && this.currentIndex === 2)
        ? this.currentIndex -= 2
        : this.currentIndex--

      const component = this.controlComponents[this.currentIndex]

      // Skip "Einzelkuh" view if "Indikatoren" not selected
      if (component.name === this.tierComponentName && !component.indikatoren.length) {
        this.currentIndex -= 1
      }

      if (component.indikator_gruppe_id === INDIKATOR_GRUPPE_UUID_EINZELKUH && this.isLastIndikatorOfGroup() && this.tier === null) {
        const tier = TierModel.query().whereIdIn(this.kontrolle.finished).last()
        this.$store.commit(`api/${SET_CURRENT_TIER}`, tier)
      }
    },
    /**
     * Increment "currentIndex"
     */
    async next () {
      await sleep(KONTROL_VALIDATION_SLEEP_TIME)
      if (this.showNextButton && this.$refs['currentComponentName'].isValid()) {
        // console.log('NEXT:', this.currentIndex, this.controlComponents.length, 'IS_LAST_INDIKATOR_OF_GROUP:', this.isLastIndikatorOfGroup())

        const component = this.controlComponents[this.currentIndex]
        if (this.isLastIndikatorOfGroup() && !this.isIndikatorGroupFinished()) {
          const name = IndikatorGruppe.find(component.indikator_gruppe_id).name
          const param = (this.tier !== null) ? `${name} - ${this.tier.name}` : name
          const title = this.message(this.messages.control.confirmTitle, param)
          const text = this.messages.control.confirmText
          const okTitle = this.messages.control.confirmOkBtn
          const cancelTitle = this.messages.control.confirmCancelBtn
          this.showConfirmModal(title, text, okTitle, cancelTitle, 'lg')
            .then(response => {
              if (response) {
                this.showDataProcessOverlay()

                this.kontrolle.finished.push((this.tier !== null) ? this.tier.id : component.indikator_gruppe_id)
                this.$store.commit(`api/${SET_CURRENT_TIER}`, null)

                this.$store.dispatch('api/updateKontrolle', this.kontrolle)
                this.updateCurrentIndex()
              }
            })
        } else {
          this.showDataProcessOverlay()

          if (component.indikator_gruppe_id === INDIKATOR_GRUPPE_UUID_EINZELKUH && this.isLastIndikatorOfGroup() && this.isIndikatorGroupFinished() && this.tier !== null) {
            this.$store.commit(`api/${SET_CURRENT_TIER}`, null)
          }

          this.updateCurrentIndex()
        }
      } else {
        setTimeout(() => {
          const nodes = document.querySelectorAll('.invalid-feedback')
          const el = [...nodes].find(node => node.style.display === 'block')
          if (el) {
            el.scrollIntoView({ block: 'center', behavior: 'smooth' })
          }
        }, 100)
      }
    },
    /**
     * Update current index
     * @desc increment / decrement current index, depending on "Indikator" group & state
     */
    updateCurrentIndex () {
      if (this.canNotLeaveControlGroup()) {
        this.currentIndex -= this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_EINZELKUH).length
      } else {
        // Skip "Indikator" selection if kontrolle type is static
        (this.kontrolle && this.kontrolle.typ === CONTROL_TYPE_STATIC && this.currentIndex === 0)
          ? this.currentIndex += 2
          : this.currentIndex++

        // Skip "Einzelkuh" view if "Indikatoren" not selected
        if (this.controlComponents[this.currentIndex].name === this.tierComponentName && !this.controlComponents[this.currentIndex].indikatoren.length) {
          this.currentIndex += 1
        }
      }
    },
    /**
     * Can't leave kontrolle group if not "isEinzelkuhControlFinished"
     * @return {boolean}
     */
    canNotLeaveControlGroup () {
      const indexAfterHerde = this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_HERDE).length + 1
      const indexAfterEinzelkuh = indexAfterHerde + this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_EINZELKUH).length + 1
      return indexAfterEinzelkuh === this.currentIndex && !this.isEinzelkuhControlFinished()
    },
    /**
     * Finish "Zitzenkontrolle" button handler
     */
    finish () {
      if (this.$refs['currentComponentName'].isValid()) {
        this.currentIndex = this.controlComponents.findIndex(component => component.name === 'Fertig')
      } else {
        this.errorSummary = this.getErrors()
      }
    },
    /**
     * Check if current "Indikator" index is last in group
     * @return {boolean}
     */
    isLastIndikatorOfGroup () {
      const component = this.controlComponents[this.currentIndex]
      if (component.indikator_gruppe_id) {
        const indikatoren = this.getIndikatorenByGroupId(component.indikator_gruppe_id)
        const lastIndexOf = indikatoren.length - 1
        const index = indikatoren.findIndex(i => i.name === component.name)
        // console.log('isLastIndikatorOfGroup', lastIndexOf, index)
        return (lastIndexOf === index)
      }
      return false
    },
    /**
     * Is kontrolle group finished
     * @return {boolean}
     */
    isIndikatorGroupFinished () {
      const component = this.controlComponents[this.currentIndex]
      if (component.indikator_gruppe_id) {
        const finished = this.kontrolle.finished.includes((this.tier !== null) ? this.tier.id : component.indikator_gruppe_id)
        // console.log('isIndikatorGroupFinished', finished)
        return finished
      }
      return false
    },
    /**
     * Get error messages from dom nodes
     * @return {[]}
     */
    getErrors () {
      const errorNodes = document.querySelectorAll('.invalid-feedback')
      let errors = []
      if (errorNodes.length) {
        errors = [...errorNodes].reduce((acc, node) => {
          if (node.style.display === 'block') {
            acc.push(node.previousSibling.previousSibling.textContent)
          }
          return acc
        }, [])
      }

      return errors
    },
    /**
     * Initialize observer to observe dom changes in "Indikator" view
     */
    observerObserve () {
      const target = this.$refs['currentComponentName'].$el
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.target.className === 'invalid-feedback' && this.errorSummary.length) {
            this.errorSummary = this.getErrors()
          }
        })
      })

      const config = { attributes: true, childList: true, characterData: true, subtree: true }
      this.observer.observe(target, config)
    },
    /**
     * Disconnect observer
     */
    observerDisconnect () {
      this.observer.disconnect()
      this.observer = null
    },
    /**
     * Is kontrolle in "Einzeltiererhebung" view
     * @return {boolean}
     */
    isEinzeltiererhebung () {
      const component = this.controlComponents[this.currentIndex]
      return component.name === this.tierComponentName || (component.indikator_gruppe_id === INDIKATOR_GRUPPE_UUID_EINZELKUH)
    },
    /**
     * Scroll current view to top if "scrollTop" position is not 0
     * @desc is wrapped in "setTimeout" method to fix issue with delayed component transition
     */
    viewScrollTop () {
      setTimeout(() => {
        const content = document.querySelector('.content')
        if (content.scrollTop !== 0) {
          content.scrollTo(0, 0)
        }
      }, 250)
    },
    /**
     * Show "DataProcessOverlay" component for specific components / views
     */
    showDataProcessOverlay () {
      if (this.currentComponentName === 'Indikator' ||
          this.currentComponentName === this.tierComponentName ||
          this.currentComponentName === 'Herde') {
        this.$eventHub.$emit('DATA_PROCESS_EVENT', true)
      }
    }
  }
}
</script>
