<template>
  <div class="kontrollen">
    <b-row>
      <b-col cols="12" v-if="isAuthenticated">
        <!-- Sync Kontrollen -->
        <b-btn @click="updateUserData" variant="light" class="mb-3 mt-1" block>
          <b-icon icon="arrow-repeat" font-scale="1"></b-icon>
          {{ messages.controls.syncControls }}
        </b-btn>
        <!-- Sync Kontrollen -->
      </b-col>
      <b-col cols="12" v-if="isAppMode">
        <!-- Start Hauptkontrolle -->
        <b-btn @click="startNewControl('flexible')" class="mb-3 mt-1" block>
          <b-icon icon="plus" font-scale="1"></b-icon>
          {{ messages.controls.newControl }}
        </b-btn>
        <!-- Start Hauptkontrolle -->
      </b-col>
    </b-row>

    <!-- Start Hauptkontrolle List -->
    <b-list-group v-if="kontrollen.length" flush>
      <b-list-group-item v-for="control in kontrollen"
                         :key="control.id"
                         :class="{'mb-3 border-0 rounded-lg': true, 'active-control': isActiveControl(control) }"
                         variant="secondary">
        <h6>
          {{ control.name }}
          <span>({{ translateState(control.status) }})</span>
        </h6>

        <p class="small m-0" v-if="herdeExists(control)">{{ messages.controls.herdeName }} {{ getHerdeName(control) }}</p>

        <div class="d-flex justify-content-between align-items-center">
          <label class="small m-0" v-html="getStateLabel(control)"></label>
          <div v-if="isInterrupted(control.status)">
            <b-btn @click="continueControl(control)"
                   variant="link"
                   :disabled="isControlDisabled(control)"
                   size="sm">
              {{ messages.controls.continueBtn }}
            </b-btn>
          </div>
          <div v-else>
            <b-btn @click="showControl(control)"
                   variant="link"
                   size="sm">
              {{ messages.controls.showBtn }}
            </b-btn>
          </div>
        </div>

        <p class="small hint text-danger" v-if="isZitzenkontrolleRequired(control)">
          {{ messages.controls.required }}
        </p>

        <label class="small m-0" v-html="getSyncLabel(control)" v-if="!isInterrupted(control.status)"></label>

        <div class="d-flex justify-content-end">
          <b-btn @click="deleteControl(control)"
                 class="my-2"
                 variant="link"
                 size="sm">
            {{ messages.controls.deleteBtn }}
          </b-btn>
        </div>

        <b-badge class="my-1 mr-1" :variant="indikatorData.state" v-for="indikatorData in control.ergebnisse" :key="indikatorData.indikatorId">
          {{ getIndikatorById(indikatorData.indikatorId).name }}
        </b-badge>

        <hr>

        <!-- Start Nachkontrolle Item -->
        <div v-if="getNachkontrolle(control)" :class="{ 'active-control': isActiveControl(getNachkontrolle(control)) }">
          <div class="d-flex justify-content-between align-items-center">
            <label class="small m-0" v-html="getStateLabel(getNachkontrolle(control))"></label>

            <div v-if="isInterrupted(getNachkontrolle(control).status)">
              <b-btn @click="continueControl(getNachkontrolle(control))"
                     variant="link"
                     :disabled="isControlDisabled(getNachkontrolle(control))"
                     size="sm">
                {{ messages.controls.continueBtn }}
              </b-btn>
            </div>
            <div v-else>
              <b-btn @click="showControl(getNachkontrolle(control))"
                     variant="link"
                     size="sm">
                {{ messages.controls.showBtn }}
              </b-btn>
            </div>
          </div>

          <p class="small hint text-danger" v-if="isZitzenkontrolleRequired(getNachkontrolle(control))">
            {{ messages.controls.required }}
          </p>

          <label class="small m-0"
                 v-html="getSyncLabel(getNachkontrolle(control))"
                 v-if="!isInterrupted(getNachkontrolle(control).status)">
          </label>

          <div class="d-flex justify-content-end">
            <b-btn @click="deleteControl(getNachkontrolle(control))"
                   class="my-2"
                   variant="link"
                   size="sm">
              {{ messages.controls.deleteBtn }}
            </b-btn>
          </div>

          <b-badge class="my-1 mr-1" :variant="indikatorData.state" v-for="indikatorData in getNachkontrolle(control).ergebnisse" :key="indikatorData.indikatorId">
            {{ getIndikatorById(indikatorData.indikatorId).name }}
          </b-badge>
        </div>
        <!-- End Nachkontrolle Item -->

        <b-btn v-if="showNachkontrolleBtn(control)"
               @click="startNewControl('flexible', control.id)"
               class="mt-1"
               size="sm"
               block>
          <b-icon icon="plus" font-scale="1"></b-icon>
          {{ messages.controls.recheckBtn }}
        </b-btn>

      </b-list-group-item>
    </b-list-group>
    <!-- End Hauptkontrolle List -->

    <!-- Start Zitzenkontrolle -->
    <b-button-group class="w-100" v-if="isAppMode && isAuthenticated && showZitzenkontrolleButton">
      <b-btn @click="startNewControl('teats')" class="mb-3 mt-1" block>
        <b-icon icon="plus" font-scale="1"></b-icon>
        {{ messages.controls.newZitzenControl }}
      </b-btn>
      <b-btn @click="openInfo" variant="outline-primary" class="mb-3 mt-1 justify-content-center d-flex">
        <i class="icon-circle-info"></i>
      </b-btn>
    </b-button-group>
    <!-- Start Zitzenkontrolle -->

    <!-- Start Zitzenkontrolle List -->
    <b-list-group v-if="teatsControls.length" flush>
      <b-list-group-item v-for="control in teatsControls"
                         :key="control.id"
                         class="mb-3 border-0 rounded-lg"
                         variant="secondary" >
        <h6>
          {{ control.name }}
          <span>({{ translateState(control.status) }})</span>
        </h6>

        <b-btn v-if="isInterrupted(control.status)"
               @click="continueControl(control)"
               class="my-2 ml-auto d-flex"
               variant="link"
               size="sm">
          {{ messages.controls.continueBtn }}
        </b-btn>

        <b-btn v-else
               @click="showControl(control)"
               class="my-2 ml-auto d-flex"
               variant="link"
               size="sm">
          {{ messages.controls.showBtn }}
        </b-btn>

        <div class="d-flex flex-row justify-content-between align-items-center">
          <label class="small m-0" v-html="getStateLabel(control)"></label>
          <b-btn @click="deleteControl(control)"
                 variant="link"
                 size="sm">
            {{ messages.controls.deleteBtn }}
          </b-btn>
        </div>

      </b-list-group-item>
    </b-list-group>
    <!-- End Zitzenkontrolle List -->

  </div>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters } from 'vuex'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import { SET_SELECTED_KONTROLLE_ID } from '@/store/modules/api/mutation-types'

import {
  CONTROL_STATE_CREATED,
  CONTROL_STATE_FINISHED,
  CONTROL_STATE_INTERRUPTED,
  CONTROL_TYPE_FLEXIBLE,
  CONTROL_TYPE_STATIC,
  CONTROL_TYPE_TEATS,
  INDIKATOR_UUID_ZELLZAHLEN,
  INFO_UUID_ZITZENKONTROLLE,
  STATUS_SYNC_DELETED
} from '@/store/utils'

export default {
  name: 'Kontrollen',
  mixins: [mixins],
  data () {
    return {}
  },
  computed: {
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'isAuthenticated'
    ]),
    /**
     * Show "Zitzenkontrolle" button, depending on "Zellzahlen => ergebnis.state"
     * @return {boolean}
     */
    showZitzenkontrolleButton () {
      const result = Kontrolle.query()
        .where('typ', CONTROL_TYPE_FLEXIBLE)
        .where('status', CONTROL_STATE_FINISHED)
        .where('status_sync', (value) => value !== STATUS_SYNC_DELETED)
        .where('ergebnisse', (ergebnisse) => {
          return ergebnisse.find((ergebnis) => ergebnis.indikatorId === INDIKATOR_UUID_ZELLZAHLEN && ergebnis.state !== 'success')
        })
        .first()

      return result !== null
    }
  },
  methods: {
    /**
     * Is active control check
     * @param control
     * @return {null|boolean}
     */
    isActiveControl (control) {
      return this.kontrolle && this.kontrolle.id === control.id
    },
    /**
     * Start new control handler
     * @desc
     * - if no interrupted control exists, start new control
     * - otherwise show confirm dialog and continue with interrupted control or delete & start new control
     * @param type
     * @param hauptkontrolleId
     */
    startNewControl (type = CONTROL_TYPE_STATIC, hauptkontrolleId = null) {
      if (type === CONTROL_TYPE_TEATS || !this.interruptedControl) {
        this.$store.dispatch('api/createKontrolle', {
          type: (!this.isAuthenticated && type !== CONTROL_TYPE_TEATS) ? CONTROL_TYPE_STATIC : type,
          hauptkontrolleId: hauptkontrolleId
        })
          .then(() => {
            this.$router.push('/kontrolle')
          })
      } else {
        const title = this.messages.controls.icTitle
        const text = this.messages.controls.icText
        const okTitle = this.messages.controls.icOkBtn
        const cancelTitle = this.messages.controls.icCancelBtn
        this.showConfirmModal(title, text, okTitle, cancelTitle, 'lg')
          .then((response) => {
            this.confirmCallback(response, type, hauptkontrolleId)
          })
      }
    },
    /**
     * Confirm callback
     */
    confirmCallback (response, type, hauptkontrolleId) {
      switch (response) {
        case true:
          this.$store.commit(`api/${SET_SELECTED_KONTROLLE_ID}`, this.interruptedControl.id)
          this.$router.push('/kontrolle')
          break
        case false:
          this.$store.dispatch('api/deleteKontrolle', this.interruptedControl)
            .then(() => {
              this.$store.dispatch('api/createKontrolle', {
                type: (!this.isAuthenticated && type !== CONTROL_TYPE_TEATS) ? CONTROL_TYPE_STATIC : type,
                hauptkontrolleId: hauptkontrolleId
              })
                .then(() => {
                  this.$router.push('/kontrolle')
                })
            })
          break
      }
    },
    /**
     * Get control state label
     * @param control
     * @return {string}
     */
    getStateLabel (control) {
      const createdAt = this.getLocalDateTime(control.benutzer_erstellt_am)

      if (control.typ === CONTROL_TYPE_TEATS) {
        return `${this.messages.controls.teatsControl} ${createdAt}`
      }

      const isNachkontrolle = this.isNachkontrolle(control)
      const controlStartedMsg = isNachkontrolle
        ? `${control.name} ${this.messages.controls.recheckControlStarted}`
        : this.messages.controls.mainControlStarted

      const controlMsg = isNachkontrolle
        ? `${control.name}: `
        : this.messages.controls.mainControl

      switch (control.status) {
        case CONTROL_STATE_CREATED:
        case CONTROL_STATE_INTERRUPTED:
          const days = this.getDaysUntilDeletion(createdAt)
          if (days) {
            return `${controlStartedMsg} ${createdAt}<p class="hint text-danger">${this.messages.controls.daysUntil1} ${days} ${this.messages.controls.daysUntil2}</p>`
          }
          return `${controlStartedMsg} ${createdAt}<p class="hint text-danger">${this.messages.controls.daysUntil3}</p>`
        default:
          return `${controlMsg} ${createdAt}`
      }
    },
    /**
     * Get control sync date
     * @param control
     * @return {string}
     */
    getSyncLabel (control) {
      const date = control.benutzer_synchronisiert_am
      return date
        ? `${this.messages.controls.syncedAt} ${this.getLocalDateTime(date)}`
        : `${this.messages.controls.syncedAt} <span class="hint text-danger">${this.messages.controls.notSynced}</span>`
    },
    /**
     * Is control state interrupted
     * @param state
     * @return {boolean}
     */
    isInterrupted (state) {
      return state === CONTROL_STATE_CREATED || state === CONTROL_STATE_INTERRUPTED
    },
    /**
     * Is control state finished
     * @param state
     * @return {boolean}
     */
    isFinished (state) {
      return state === CONTROL_STATE_FINISHED
    },
    /**
     * Show finished or completed control
     * @param control
     */
    showControl (control) {
      this.$store.commit(`api/${SET_SELECTED_KONTROLLE_ID}`, control.id)
      if (control.typ !== CONTROL_TYPE_TEATS) {
        this.$router.push('/ergebnisse')
      } else {
        this.$router.push('/kontrolle')
      }
    },
    /**
     * Continue control
     * @param control
     */
    continueControl (control) {
      this.$store.commit(`api/${SET_SELECTED_KONTROLLE_ID}`, control.id)
      this.$router.push('/kontrolle')
    },
    /**
     * Get Nachkontrolle of a control
     * @param control
     */
    getNachkontrolle (control) {
      const nachkontrolle = Kontrolle.query()
        .where('hauptkontrolle_id', control.id)
        .first()

      if (!nachkontrolle) {
        return false
      }

      return nachkontrolle
    },
    /**
     * Delete control
     * @param control
     */
    deleteControl (control) {
      const title = this.messages.controls.deleteTitle
      const text = this.messages.controls.deleteText
      const okTitle = this.messages.controls.okBtn
      const cancelTitle = this.messages.controls.cancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle)
        .then(response => {
          if (response) {
            const nachkontrolle = this.getNachkontrolle(control)
            if (nachkontrolle) {
              this.$store.dispatch('api/deleteKontrolle', nachkontrolle)
            }

            this.$store.dispatch('api/deleteKontrolle', control)
          }
        })
    },
    /**
     * Open "Zitzenkontrolle" info modal
     */
    openInfo () {
      this.openInfoModal(INFO_UUID_ZITZENKONTROLLE)
    },
    /**
     * Is "Zitzenkontrolle" required depending on "Indikator => Zellzahlen => state"
     * @param control
     * @return {boolean}
     */
    isZitzenkontrolleRequired (control) {
      if (control.ergebnisse &&
          Array.isArray(control.ergebnisse) &&
          control.typ === CONTROL_TYPE_FLEXIBLE) {
        const ergebnis = control.ergebnisse.find(ergebnis => ergebnis.indikatorId === INDIKATOR_UUID_ZELLZAHLEN)
        return !!(ergebnis && ergebnis.state !== 'success' && ergebnis.state !== 'light')
      }
      return false
    },
    /**
     * Show / hide "Nachkontrolle" button
     * @desc Show only if control
     * - "typ" is "Eigenkontrolle mit Ursachenanalyse"
     * - "status" is "finished"
     * - control is "Hauptkontrolle"
     * - "appMode" is "app"
     * @param control
     * @return {boolean}
     */
    showNachkontrolleBtn (control) {
      return (control.typ === CONTROL_TYPE_FLEXIBLE) &&
             this.isFinished(control.status) &&
             !this.getNachkontrolle(control) &&
             this.isAppMode
    },
    /**
     * Update user data handler
     */
    updateUserData () {
      const title = this.messages.settings.fetchTitle
      const text = this.messages.settings.fetchText
      const okTitle = this.messages.settings.fetchOkBtn
      const cancelTitle = this.messages.settings.cancelBtn
      this.showConfirmModal(title, text, okTitle, cancelTitle, 'sm', 'sm', 'secondary', 'danger')
        .then(response => {
          if (response) {
            this.fetchUserData()
          }
        })
    }
  }
}
</script>
