<template>
  <div class="ursachenanalyse">
    <b-row>
      <b-col>
        <h5>{{ messages.ursachenanalyse.headline }}</h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="accordion">
          <b-button class="text-left mb-3" v-b-toggle.collapse-info variant="primary" block>
            {{ messages.ursachenanalyse.subline }}
            <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
          </b-button>
          <b-collapse id="collapse-info" class="mb-3">
            <b-card>
              <div v-for="(description, index) in getDescriptions()" :key="index">
                <div v-html="description.text"></div>
                <div v-for="(image, index) in description.images" :key="index">
                  <b-img :src="image.file" fluid center></b-img>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </b-col>
    </b-row>

    <p v-if="!isAppMode && Object.keys(kontrolPunktBereiche).length === 0">{{ this.messages.global.positiveResults }}</p>

    <p v-if="isAppMode && Object.keys(kontrolPunktBereiche).length === 0">{{ this.messages.global.noInAppFragen }}</p>

    <b-row v-for="kontrollpunktbereich in kontrolPunktBereiche" :key="kontrollpunktbereich.id">
      <b-col>
        <b-button class="mt-3 text-left" block v-b-toggle="kontrollpunktbereich.id" :data-uuid="kontrollpunktbereich.id">
          {{ getIndikatorById(kontrollpunktbereich.id).name }}

          <b-badge class="mx-1 p-2" :data-uuid="indikator.id" :variant="getIndikatorData(indikator.kontrolle_indikator_id).state" v-for="indikator in kontrollpunktbereich.ursachenXkontrolle" :key="indikator.id">
            <b-badge variant="light">{{ indikator.rang.toString().charAt(0) }}</b-badge>
            {{ getIndikatorById(indikator.kontrolle_indikator_id).name }}
          </b-badge>

          <b-icon icon="chevron-down" class="float-right mt-1 mr-1"></b-icon>
        </b-button>
        <b-collapse :id="kontrollpunktbereich.id" role="tabpanel" ref="collapse">
          <b-card border-variant="secondary">
            <b-btn v-if="showSyncButton && !isAppMode" size="sm" @click="prefillKontrolpunktbereichFragen(kontrollpunktbereich.id)" class="mb-5" block>
              {{ messages.global.prefillBtn }}
            </b-btn>
            <b-row v-for="frage in kontrollpunktbereich.fragen" :key="frage.id">
              <b-col>
                <div v-if="isSynced && getFrageAntwortJson(frage)">
                  <b-badge :variant="getAntwortVariantByValue(getFrageAntwortJson(frage))" v-if="allowedBadgesValues.includes(getFrageAntwortJson(frage))">
                    {{ getFrageAntwortJson(frage) }}
                  </b-badge>
                </div>
                <antwort :data="frage" :ref="kontrollpunktbereich.id" :lazy="true" :disabled="isSynced"></antwort>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p v-if="indikatorsToCheck.length" class="mt-5">{{ this.messages.ursachenanalyse.errorMessagesHeadline }}</p>
        <ul>
          <li class="text-danger" v-for="kpb in indikatorsToCheck" :key="kpb.id">{{ kpb.name }}</li>
        </ul>
        <b-btn size="sm" @click="send" v-if="showSyncButton" class="my-5" block variant="success">
          {{ this.messages.results.transferToServer }} <b-icon icon="upload" class="ml-2"></b-icon>
        </b-btn>
        <b-btn size="sm" @click="next" v-if="!showSyncButton" class="my-5" block>
          {{ this.messages.results.nextStep }} <b-icon icon="arrow-right"></b-icon>
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import mixins from '@/mixins'
import Info from '@/store/modules/api/models/Info'
import IndikatorGruppe from '../store/modules/api/models/IndikatorGruppe'
import Antwort from '@/components/kontrolle/Antwort'

import {
  INDIKATOR_GRUPPE_UUID_URSACHENANALYSE,
  INFO_UUID_URSACHENANALYSE,
  INFO_UUID_URSACHENANALYSE_DESKTOP,
  STATUS_SYNC_URSACHENANALYSE_APP_FINISHED,
  STATUS_SYNC_URSACHENANALYSE_DESKTOP_FINISHED
} from '@/store/utils'

export default {
  name: 'Ursachenanalyse',
  mixins: [mixins],
  components: {
    Antwort
  },
  data () {
    return {
      kontrolPunktBereiche: {},
      collapses: {},
      allowedBadgesValues: ['korrekt', 'inkorrekt'],
      indikatorsToCheck: []
    }
  },
  mounted () {
    this.kontrolPunktBereiche = this.getKontrollpunktbereiche()
    this.getCollapsesIds()
    this.setDisableState()
    this.initCollapseEvents()
  },
  watch: {
    'kontrolle.status_sync' () {
      this.setDisableState()
    }
  },
  computed: {
    /**
     * Show / hide sync button
     * @return {boolean}
     */
    isSynced () {
      return (this.kontrolle.status_sync >= STATUS_SYNC_URSACHENANALYSE_DESKTOP_FINISHED && this.isDesktopMode) ||
             (this.kontrolle.status_sync >= STATUS_SYNC_URSACHENANALYSE_APP_FINISHED && this.isAppMode)
    },
    showSyncButton () {
      return !this.isSynced
    }
  },
  methods: {
    prefillKontrolpunktbereichFragen (collapseId) {
      const collapseEditors = this.$refs[collapseId]
      if (collapseEditors) {
        for (let key in collapseEditors) {
          if (collapseEditors.hasOwnProperty(key)) {
            const editor = collapseEditors[key]
            editor.useLastFrageAntwortModel()
          }
        }
      }
    },
    getFrageAntwortJson (frage) {
      if (!frage.antworten[0]) {
        return false
      } else {
        return JSON.parse(frage.antworten[0].json)
      }
    },
    getAntwortVariantByValue (value) {
      return value === 'korrekt' ? 'success' : 'danger'
    },
    /**
     * When collapse are open initialize all the editors in that collapse
     */
    initCollapseEvents () {
      this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        if (isJustShown && this.collapses.hasOwnProperty(collapseId)) {
          const collapseEditors = this.$refs[collapseId]
          if (collapseEditors) {
            for (let key in collapseEditors) {
              if (collapseEditors.hasOwnProperty(key)) {
                const editor = collapseEditors[key]
                setTimeout(() => {
                  editor.init()
                }, 0)
              }
            }
          }
        }
      })
    },
    /**
     * return an Object with the collapses ids as booleans properties.
     * If a property is true that means that the collapse is open and that the
     * json editors instances within were initialized.
     * This is needed to correctly validate the entire page.
     */
    getCollapsesIds () {
      setTimeout(() => {
        const collapses = this.$refs['collapse']
        if (collapses) {
          collapses.forEach((collapse) => {
            this.collapses[collapse.id] = false
          })
        }
      })
    },
    getKontrollpunktbereiche () {
      const gruppe = IndikatorGruppe.query()
        .whereId(INDIKATOR_GRUPPE_UUID_URSACHENANALYSE)
        .with('indikatoren.fragen', (query) => {
          query
            .with('infos')
            .with('antworten', (query) => {
              query.where('kontrolle_id', this.kontrolle.id)
            })
        })
        .first()

      if (!gruppe) {
        return false
      }

      const kontrollpunktbereiche = {}

      gruppe.indikatoren.forEach((indikator) => {
        const uniqueFragen = {}
        let fragen = indikator.fragen.flat()
        fragen = this.filterFragenByErgebnisse(fragen)
        fragen = this.filterFragenByPsk(fragen)

        if (fragen.length > 0) {
          const indikatoren = []
          fragen.forEach((frage) => {
            const frageIndikatoren = this.getFrageIndikatoren(frage)
            frageIndikatoren.forEach((indikatorId) => {
              if (!indikatoren.includes(indikatorId) && this.indikatorIsDangerOrWarning(indikatorId)) {
                indikatoren.push(indikatorId)
              }
            })

            const indikatorenArray = indikatoren.map((indikatorId) => this.getIndikatorById(indikatorId))

            indikatorenArray.sort((a, b) => {
              return a.rang - b.rang
            })

            // when in appMode "app" show only filtered fragen with antwort "app" in the
            // in the relative kontrolpunktbereich. Else show all kontrolpunktbereich
            // with all relative filtered fragen.
            if (this.isAppMode) {
              frage.antworten.forEach((antwort) => {
                if (JSON.parse(antwort.json) === 'app') {
                  uniqueFragen[frage.id] = frage
                  kontrollpunktbereiche[indikator.id] = {
                    id: indikator.id,
                    fragen: uniqueFragen,
                    indikatoren: indikatorenArray
                  }
                }
              })
            } else {
              uniqueFragen[frage.id] = frage
              kontrollpunktbereiche[indikator.id] = {
                id: indikator.id,
                fragen: uniqueFragen,
                indikatoren: indikatorenArray
              }
            }
          })
        }
      })

      const kontrollpunktbereicheArray = []
      Object.keys(kontrollpunktbereiche).forEach((ursacheIndikatorId) => {
        const kontrollpunktbereich = kontrollpunktbereiche[ursacheIndikatorId]
        kontrollpunktbereich.ursachenXkontrolle = this.getUrsachenXkontrolle(ursacheIndikatorId, kontrollpunktbereich.indikatoren.map((indikator) => indikator.id))
        kontrollpunktbereich.rang = this.getUrsachenanalyseRang(ursacheIndikatorId, kontrollpunktbereich.indikatoren.map((indikator) => indikator.id))
        kontrollpunktbereicheArray.push(kontrollpunktbereich)
      })

      kontrollpunktbereicheArray.sort(function (a, b) {
        return a.rang - b.rang
      })

      return kontrollpunktbereicheArray
    },
    getIndikatorState (indikatorId) {
      return this.controlErgebnisse[indikatorId].state
    },
    /**
     * Get info descriptions
     * @return {Array|null}
     */
    getDescriptions () {
      const uuid = (this.isAppMode) ? INFO_UUID_URSACHENANALYSE : INFO_UUID_URSACHENANALYSE_DESKTOP
      const info = Info.query().whereId(uuid).first()
      return (info && info.json) ? this.parseAndMapDescriptions(info.json) : null
    },
    getFrageIndikatoren (frage) {
      return JSON.parse(frage.meta_data).ind
    },
    getFragePks (frage) {
      const metaData = JSON.parse(frage.meta_data)
      return metaData.psk
    },
    /**
     * Get info descriptions
     * @return {string}
     */
    filterFragenByPsk (fragen) {
      const filtered = []

      fragen.forEach((frage) => {
        const fragePsk = this.getFragePks(frage)
        const allFrageKuerzeIncludedInPsk = fragePsk.every(kuerzel => this.produktionSystemKuerzeln.includes(kuerzel))
        if (allFrageKuerzeIncludedInPsk) {
          filtered.push(frage)
        }
      })

      return filtered
    },
    filterFragenByErgebnisse (fragen) {
      const filtered = []

      fragen.forEach((frage) => {
        const frageIndikatorenIds = this.getFrageIndikatoren(frage)
        frageIndikatorenIds.forEach((indikatorId) => {
          const indikatorData = this.getIndikatorData(indikatorId)
          if (indikatorData) {
            const ergebnisseIndikatorIsRed = indikatorData.state === 'danger'
            const ergebnisseIndikatorIsYellow = indikatorData.state === 'warning'
            if (ergebnisseIndikatorIsRed || ergebnisseIndikatorIsYellow) {
              filtered.push(frage)
            }
          }
        })
      })

      return filtered
    },
    /**
     * Validation for all "Fragen / Antworten"
     * @return {boolean}
     */
    isValid: function () {
      let result = true
      const toCheckObj = {}
      this.indikatorsToCheck = []
      for (let ref in this.$refs) {
        const isEditorRef = ref.length === 36
        if (isEditorRef) {
          const theRef = this.$refs[ref]
          for (let key in theRef) {
            const editor = theRef[key]
            if (!editor.jsonEditor) {
              toCheckObj[ref] = null
              result = false
              continue
            }
            // if app mode === app should not have "in app antworten" retun false
            if (typeof editor.getValue === 'function' && this.isAppMode) {
              const value = editor.getValue()
              if (value === 'app') {
                result = false
                break
              }
            }
            if (typeof editor.isValid === 'function' && !editor.isValid()) {
              toCheckObj[ref] = null
              result = false
            }
          }
        }
      }
      for (let kpbId in toCheckObj) {
        if (toCheckObj.hasOwnProperty(kpbId)) {
          this.indikatorsToCheck.push(this.getIndikatorById(kpbId))
        }
      }
      return result
    },
    /**
     * Send data to server via vuex api module action
     */
    send () {
      console.log('send isValid', this.isValid(), 'hasInAppFragen', this.hasInAppFragen())
      if (this.isValid()) {
        let statusSync = STATUS_SYNC_URSACHENANALYSE_DESKTOP_FINISHED

        if (this.isAppMode) {
          statusSync = STATUS_SYNC_URSACHENANALYSE_APP_FINISHED
        } else if (this.isDesktopMode && !this.hasInAppFragen()) {
          statusSync = STATUS_SYNC_URSACHENANALYSE_APP_FINISHED
        } else {
          statusSync = STATUS_SYNC_URSACHENANALYSE_DESKTOP_FINISHED
        }

        this.sendDataToServer(
          {
            kontrolle: this.kontrolle
          },
          statusSync
        )
      }
    },
    next () {
      if (this.isDesktopMode) {
        this.$router.push('/massnahmenvorschlaege')
      } else {
        this.$router.push('/massnahmenplan')
      }
    },
    hasInAppFragen () {
      let result = false
      for (let ref in this.$refs) {
        const isEditorRef = ref.length === 36
        if (isEditorRef) {
          const theRef = this.$refs[ref]
          for (let key in theRef) {
            const editor = theRef[key]
            if (!editor.jsonEditor) {
              result = false
              break
            }
            if (typeof editor.getValue === 'function') {
              const value = editor.getValue()
              if (value === 'app') {
                result = true
                break
              }
            }
          }
        }
      }
      return result
    },
    /**
     * Set entire json-editors form disable state
     */
    setDisableState () {
      if (this.isSynced) {
        for (const ref in this.$refs) {
          this.$refs[ref].forEach((editor) => {
            if (typeof editor.disable === 'function') {
              editor.disable()
            }
          })
        }
      }
    }
  }
}
</script>
