<template>
  <div class="loading-wrapper w-100 h-100">
    <div class="loading">
      <b-spinner variant="success" v-if="showSpinner"></b-spinner>
      <p class="mb-1 mt-3" v-else>
        <b-progress :value="value"
                    :max="100"
                    :animated="false"
                    class="w-50 mx-auto"
                    v-if="value">
        </b-progress>
      </p>
      <p v-if="message" class="loading-message">
        <span v-if="!showSpinner && value">{{percent}} - </span>{{ message }}
      </p>
      <p class="text-center mt-3" v-if="showCancelBtn">
        <b-btn variant="danger"
               @click="cancel"
               :disabled="!cancelable">
          Abbrechen
        </b-btn>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Loading',
  props: {
    message: String,
    cancelable: {
      type: Boolean,
      default: false
    },
    showCancelBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Map api module getters
     */
    ...mapState('api', [
      'syncProgressSteps',
      'syncProgressStepsTotal'
    ]),
    /**
     * Percentage sync progress value
     * @return {number}
     */
    value () {
      // console.log(this.syncProgressStepsTotal, this.syncProgressSteps, parseInt((100 / this.syncProgressStepsTotal) * this.syncProgressSteps))
      return (this.syncProgressStepsTotal) ? parseInt((100 / this.syncProgressStepsTotal) * this.syncProgressSteps) : 0
    },
    /**
     * Percentage string
     * @return {string}
     */
    percent () {
      return `${this.value}%`
    },
    /**
     * Show spinner
     * TODO: check implementation...
     * @return {boolean}
     */
    showSpinner () {
      // return this.syncProgressStepsTotal === this.syncProgressSteps
      return false // this.value === 100
    }
  },
  methods: {
    /**
     * Cancel button click handler
     */
    cancel () {
      this.$emit('cancel-event')
    }
  }
}
</script>
