<template>
  <div>
    <b-form @submit.prevent="onRegister" v-if="!responseMessage">
      <small class="text-danger">{{ messages.forms.requiredFields }}</small>
      <b-form-group id="register-input-group-1">
        <legend class="col-form-label">{{ messages.forms.name }} <span class="text-danger">* </span></legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.name"
                      :state="validateState('name')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.required }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="register-input-group-2">
        <legend class="col-form-label">{{ messages.forms.forename }} <span class="text-danger">* </span></legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.forename"
                      :state="validateState('forename')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.required }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="register-input-group-3">
        <legend class="col-form-label">{{ messages.forms.company }} <span class="text-danger">* </span></legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.company"
                      :state="validateState('company')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.required }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="register-input-group-4">
        <legend class="col-form-label">{{ messages.forms.street }}</legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.street">
        </b-form-input>
      </b-form-group>

      <b-form-group id="register-input-group-5">
        <legend class="col-form-label">{{ messages.forms.zip }}</legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.zip">
        </b-form-input>
      </b-form-group>

      <b-form-group id="register-input-group-6">
        <legend class="col-form-label">{{ messages.forms.location }}</legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.location">
        </b-form-input>
      </b-form-group>

      <b-form-group id="register-input-group-7">
        <legend class="col-form-label">{{ messages.forms.county }} <span class="text-danger">* </span></legend>
        <b-form-input type="text" trim
                      v-model.trim="registerForm.county"
                      :state="validateState('county')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.required }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="register-input-group-8">
        <legend class="col-form-label">{{ messages.forms.email }} <span class="text-danger">* </span></legend>
        <b-form-input type="email" trim
                      v-model.trim="registerForm.email"
                      :state="validateState('email')">
        </b-form-input>
        <b-form-invalid-feedback>
        <span v-if="emailExists">
          {{ authError }}
        </span>
          <span v-else>
          {{ messages.forms.requiredEmail }}
        </span>
        </b-form-invalid-feedback>
      </b-form-group>

      <!--<b-form-group id="register-input-group-9"
                    label="Benutzername:">
        <b-form-input type="text" trim
                      v-model.trim="registerForm.username"
                      :state="validateState('username')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.required }}
        </b-form-invalid-feedback>
      </b-form-group>-->

      <b-form-group id="register-input-group-10">
        <legend class="col-form-label">{{ messages.forms.password }} <span class="text-danger">* </span></legend>
        <b-form-input type="password" trim
                      v-model.trim="registerForm.password"
                      :state="validateState('password')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.requiredMinLen }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="register-input-group-11">
        <legend class="col-form-label">{{ messages.forms.passwordRepeat }} <span class="text-danger">* </span></legend>
        <b-form-input type="password" trim
                      v-model.trim="registerForm.passwordRepeat"
                      :state="validateState('passwordRepeat')">
        </b-form-input>
        <b-form-invalid-feedback>
          {{ messages.forms.requiredMinLen }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="register-input-group-12">
        <b-form-checkbox :value="true"
                         :unchecked-value="false"
                         v-model="registerForm.dsgvo"
                         :state="validateState('dsgvo')">
          <span id="dsgvo" v-html="getDsgvoMessage()"></span>
        </b-form-checkbox>
        <b-form-invalid-feedback :state="validateState('dsgvo')">
          {{ messages.forms.agree }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-invalid-feedback class="mb-3 auth-error" :state="!authError">
        {{ authError }}
      </b-form-invalid-feedback>

      <b-button type="submit" variant="primary" block>
        {{ messages.forms.register }}
      </b-button>
    </b-form>

    <p v-else>
      {{ responseMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'

import {
  email,
  minLength,
  required,
  sameAs
} from 'vuelidate/lib/validators'

export default {
  name: 'RegisterForm',
  data () {
    return {
      responseMessage: null,
      registerForm: {
        name: null,
        forename: null,
        company: null,
        street: null,
        zip: null,
        location: null,
        county: null,
        email: null,
        // username: null,
        password: null,
        passwordRepeat: null,
        dsgvo: false
      }
    }
  },
  validations: {
    registerForm: {
      name: {
        required
      },
      forename: {
        required
      },
      company: {
        required
      },
      county: {
        required
      },
      email: {
        required,
        email,
        emailExists (value, vm) {
          return this.emailExists === null
        }
      },
      /* username: {
        required
      }, */
      password: {
        required,
        minLength: minLength(6)
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('password')
      },
      dsgvo: {
        sameAs: sameAs(() => true)
      }
    }
  },
  computed: {
    /**
     * Map api state
     */
    ...mapState('api', [
      'authError'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'privacyPolicyUrl'
    ]),
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages',
      'message'
    ]),
    emailExists () {
      return (this.authError && this.authError.includes('exist')) ? true : null
    }
  },
  methods: {
    /**
     * Map api actions
     */
    ...mapActions('api', [
      'register'
    ]),
    /**
     * Register handler
     */
    onRegister () {
      this.$v.registerForm.$touch()
      if (this.$v.registerForm.$anyError) {
        return
      }

      this.register(this.registerForm)
        .then(response => {
          if (response) {
            this.responseMessage = response.message
          }
        })
    },
    /**
     * Validation handler
     * @param attr
     * @return {boolean|null}
     */
    validateState (attr) {
      const { $dirty, $error } = this.$v.registerForm[attr]
      return $dirty ? !$error : null
    },
    /**
     * Get dsgvo message with url param
     * @return {null}
     */
    getDsgvoMessage () {
      const param = this.privacyPolicyUrl
      return this.message(this.messages.forms.dsgvo, param)
    }
  }
}
</script>
