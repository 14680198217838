import * as types from './mutation-types'

const mutations = {
  /**
   * Update the network connection type
   * @param state
   * @param type
   */
  [types.UPDATE_CONNECTION_TYPE] (state, { type }) {
    // console.log('UPDATE_CONNECTION_TYPE', type)

    switch (type) {
      /* global Connection */
      case Connection.UNKNOWN:
      case Connection.CELL_4G:
      case Connection.CELL_3G:
      case Connection.CELL_2G:
      case Connection.CELL:
        state.connectionState = 'warning'
        break
      case Connection.NONE:
        state.connectionState = 'error'
        break
      default:
        state.connectionState = 'ok'
        break
    }
    state.connectionType = type

    const style = 'background: #CCC; color: #ff0000'
    console.log('%c* UPDATE_CONNECTION_TYPE connectionState: ' + state.connectionState, style)
    console.log('%c* UPDATE_CONNECTION_TYPE connectionType: ' + state.connectionType, style)
  },
  /**
   * Set "versionCode" state
   * @param state
   * @param code
   */
  [types.SET_VERSION_CODE] (state, code) {
    state.versionCode = code
  },
  /**
   * Set "versionNumber" state
   * @param state
   * @param number
   */
  [types.SET_VERSION_NUMBER] (state, number) {
    state.versionNumber = number
  }
}

export default mutations
