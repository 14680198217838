import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class Datei extends Model {
  static entity = 'dateien'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      name: this.string(''),
      size: this.number(null),
      type: this.string(''),
      url: this.string(''),
      localURL: this.string('')
    }
  }
}
