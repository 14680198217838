<template>
  <b-container @click="clicked" class="px-0" fluid>

    <b-alert :show="showAlert"
             @dismissed="onDismissed"
             @dismiss-count-down="onDismissCountDown"
             variant="danger"
             class="hint"
             dismissible
             fade>
      <div>
        <p class="mb-0">{{ dismissCountDown }} - {{ errorText }}</p>
        <p class="mb-0">{{ errorMessage }}</p>
      </div>
    </b-alert>

    <header-bar/>

    <loading :message="loadingMessage" v-if="isLoading"/>
    <data-process-overlay :message="processMessage"/>

    <transition name="fade">
      <router-view :class="{'content': true}"/>
    </transition>

    <navigation/>

  </b-container>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
import Loading from '@/components/Loading'
import Navigation from '@/components/Navigation.vue'
import DataProcessOverlay from '@/components/DataProcessOverlay.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    HeaderBar,
    Loading,
    Navigation,
    DataProcessOverlay
  },
  data () {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      loadingMessage: null,
      processMessage: null,
      errorText: null,
      successText: null
    }
  },
  created () {
    this.loadingMessage = this.messages.global.loadingMessage
    this.processMessage = this.messages.global.processMessage
    this.errorText = this.messages.global.errorText
    this.successText = this.messages.global.successText
  },
  watch: {
    errorMessage () {
      this.statusHandler()
    },
    requestStatus () {
      this.statusHandler()
    }
  },
  computed: {
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * Map api state
     */
    ...mapState('api', [
      'requestError',
      'authError',
      'requestStatus',
      'authStatus'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'isLoading'
    ]),
    /**
     * Error message for auth or request errors
     */
    errorMessage () {
      return this.authError || this.requestError
    },
    /**
     * Check "errorMessage" & "requestStatus"
     * @return {*|boolean}
     */
    hasStatusMessage () {
      return (this.errorMessage && (this.requestStatus === 'error' || this.authStatus === 'error'))
    },
    /**
     * Show alert
     * @return {boolean|number}
     */
    showAlert () {
      if (this.hasStatusMessage) {
        return this.dismissCountDown
      }
      return false
    }
  },
  methods: {
    /**
     * Global content click event, force the android system ui to hide if the click target is no textarea or input element
     * @param event
     * @returns {boolean}
     */
    clicked (event) {
      if (event.target.tagName.includes('TEXTAREA') ||
          event.target.tagName.includes('INPUT')) {
        return false
      }

      const ev = new CustomEvent('APP_CONTENT_CLICKED')
      window.dispatchEvent(ev)
    },
    /**
     * Handle "requestStatus" & "authStatus" state changes
     */
    statusHandler () {
      if (this.hasStatusMessage && this.$route.name !== 'login') {
        this.dismissCountDown = this.dismissSecs
      }

      if (this.hasStatusMessage && this.errorMessage.includes('Autorisierungsfehler')) {
        this.$store.commit('api/SET_LOGIN_STATE', { value: false, reset: false })
        if (this.$route.name !== 'login') {
          this.$router.push('/')
        }
      }
    },
    /**
     * Dismiss count down event handler
     * @param dismissCountDown
     */
    onDismissCountDown (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    /**
     * Dismissed event handler
     */
    onDismissed () {
      this.dismissCountDown = 0
      this.$store.commit('api/RESET_ERROR_STATES')
    }
  }
}
</script>

<style lang="scss">
@import "scss/main";
</style>
