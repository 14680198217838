<template>
  <div class="indikator-progress-bar">
    <ul class="group-list">
      <li v-for="(gruppe, index) in gruppen" :key="gruppe.id" :class="'item-' + index + ' ' + isActiveGroup(gruppe)">
        <span v-html="getLabel(gruppe)"></span>
        <i class="arrow-right" v-if="index < 2"></i>
      </li>
    </ul>
    <ul class="progress-list">
      <li v-for="(gruppe, index) in gruppen" :key="gruppe.id" :class="'item-'+index">
        <div v-if="isEinzelkuhIndikatorGruppe(gruppe.id)" class="d-flex">
          <div v-for="(fragen, index) in einzelkuhFragen(index)" :key="index" class="flex-row d-flex">
            <div v-for="frage in fragen" :key="frage.id"
                 :class="isActiveEinzelkuhFrage(frage, index)"
                 class="progress-item extra">
            </div>
          </div>
        </div>
        <div class="d-flex" v-else>
          <div v-for="(indikator, index) in getIndikatorenByGroupId(gruppe.id)" :key="index"
               :class="isActiveIndikator(indikator)"
               class="progress-item flex-column">
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters, mapState } from 'vuex'
import { INDIKATOR_GRUPPE_UUID_EINZELKUH, INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE } from '@/store/utils'

export default {
  name: 'IndikatorProgressBar',
  mixins: [mixins],
  props: {
    activeComponent: {
      type: Object
    }
  },
  created () {
    // console.log(this.tiere[0])
  },
  computed: {
    /**
     * Map api module getters
     */
    ...mapGetters('api', [
      'tiere',
      'antworten'
    ]),
    /**
     * Map api module state
     */
    ...mapState('api', [
      'tier'
    ]),
    gruppen () {
      return this.indikatorGruppen.filter(gruppe => gruppe && gruppe.id !== INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE)
    }
  },
  methods: {
    /**
     * Get custom group label
     * @param group
     * @return {*}
     */
    getLabel (group) {
      return (group.id !== INDIKATOR_GRUPPE_UUID_EINZELKUH) ? group.name : `${group.name} (${this.sumAdded}/${this.sumTotal})`
    },
    /**
     * Set active "IndikatorGruppe" class
     * @param group
     * @return {string}
     */
    isActiveGroup (group) {
      return (this.activeComponent.indikator_gruppe_id && this.activeComponent.indikator_gruppe_id === group.id) ? 'active' : ''
    },
    /**
     * Set active "Indikator" class
     * @param indikator
     * @return {string}
     */
    isActiveIndikator (indikator) {
      const fragen = indikator.fragen.map(frage => frage.id)
      const antworten = this.antworten.map(antwort => antwort.frage_id)
      const finished = !!fragen.find(value => antworten.includes(value))
      const current = (this.activeComponent.id && this.activeComponent.id === indikator.id)
      return current || finished ? 'active' : ''
    },
    /**
     * Set active "EinzelkuhFrage" class
     * @param frage
     * @param index
     * @return {string}
     */
    isActiveEinzelkuhFrage (frage, index) {
      const tier = this.tiere[index]
      let finished = false
      if (tier) {
        const antworten = this.getEinzelkuhIndikatorState(tier).antworten.map(a => a.tier_id)
        finished = antworten.includes(tier.id)
      }

      const current = (this.activeComponent.id && this.activeComponent.id === frage.indikator_id && tier && this.tier && tier.id === this.tier.id)
      return current || finished ? 'active' : ''
    },
    /**
     * Is "EinzelkuhIndikatorGruppe"
     * @param id
     * @return {boolean}
     */
    isEinzelkuhIndikatorGruppe (id) {
      return (id === INDIKATOR_GRUPPE_UUID_EINZELKUH)
    },
    /**
     * Get "EinzelkuhFrage" by tier
     * @param index
     * @return {*[]}
     */
    einzelkuhFragen (index) {
      const tier = (this.tiere[index]) ? this.tiere[index] : null
      const fragen = this.getEinzelkuhIndikatorState(tier).fragen
      return [ ...Array(this.sumTotal).keys() ].map(i => fragen)
    }
  }
}
</script>
