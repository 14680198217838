import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class IndikatorXInfo extends Model {
  static entity = 'indikator-x-infos'

  static primaryKey = ['indikator_id', 'info_id']

  static fields () {
    return {
      indikator_id: this.uid(() => uuid()),
      info_id: this.uid(() => uuid())
    }
  }
}
