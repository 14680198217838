import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class FrageXInfo extends Model {
  static entity = 'frage-x-infos'

  static primaryKey = ['frage_id', 'info_id']

  static fields () {
    return {
      frage_id: this.uid(() => uuid()),
      info_id: this.uid(() => uuid())
    }
  }
}
