import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class Ursachenanalyse extends Model {
  static entity = 'ursachenanalyse'

  static primaryKey = ['ursache_indikator_id', 'kontrolle_indikator_id']

  static fields () {
    return {
      ursache_indikator_id: this.uid(() => uuid()),
      kontrolle_indikator_id: this.uid(() => uuid()),
      rang: this.number(null)
    }
  }
}
