<template>
  <div class="ergebnisse">
    <b-row class="d-none d-print-block mb-5">
      <b-col>
        <h5>{{ messages.results.headline }}</h5>
        <p class="mb-0">{{ getHerdeName(kontrolle) }} / {{ kontrolle.name }} ({{ getLocalDate(kontrolle.benutzer_erstellt_am) }})</p>
        <p v-if="profile && profile.company">{{ messages.settings.company }} {{ profile.company }}</p>
      </b-col>
    </b-row>

    <div class="mb-3 d-flex justify-content-around align-items-center">
      <div class="d-flex align-items-center">
        <square class="mr-3" variant="success"></square>
        <small>{{ messages.results.targetArea }}</small>
      </div>
      <div class="d-flex align-items-center">
        <square class="mr-3" variant="warning"></square>
        <small>{{ messages.results.limitArea }}</small>
      </div>
      <div class="d-flex align-items-center">
        <square class="mr-3" variant="danger"></square>
        <small>{{ messages.results.alarmArea }}</small>
      </div>
    </div>

    <div v-if="showNav" class="sticky-nav d-flex justify-content-between align-items-center mb-3">
      <b-form-checkbox v-model="compareMode" switch>
        {{ messages.results.compareMode }}
      </b-form-checkbox>

      <b-nav align="right" pills small>
        <b-nav-item @click="switchTo(0)" :active="tabIndex === 0">
          {{ messages.results.mainControl }}
        </b-nav-item>
        <b-nav-item @click="switchTo(1)" :active="tabIndex === 1">
          {{ messages.results.recheckControl }}
        </b-nav-item>
      </b-nav>
    </div>

    <div v-for="(indikatorData, index) in kontrolleErgebnisse" :key="index" class="mb-1">
      <indikator :indikatorData="indikatorData" :visible="false"></indikator>
    </div>

    <div class="cause-analysis-text">
      <p v-if="!isDesktopMode && !isAuthenticated">{{ messages.results.causeAnalysisHowToUnregistered }}</p>
      <p v-if="!isDesktopMode && isAuthenticated && !isStaticKontrolle()">{{ messages.results.causeAnalysisHowToFlexible }}</p>
      <p v-if="!isDesktopMode && isAuthenticated && isStaticKontrolle()">{{ messages.results.causeAnalysisHowToStatic }}</p>
    </div>

    <div>
      <div class="btn-sync mb-2 text-right">
        <b-btn size="sm" @click="send" v-if="showSyncBtn && isAuthenticated" block variant="success">
          {{ this.messages.results.transferToServer }} <b-icon icon="upload"></b-icon>
        </b-btn>
        <b-btn size="sm" @click="next" v-if="!showSyncBtn && isAuthenticated && isControlTypFlexible" block>
          {{ this.messages.results.nextStep }} <b-icon icon="arrow-right"></b-icon>
        </b-btn>
      </div>
      <div class="btn-export mb-2 text-right" v-if="showExportBtn">
        <b-btn size="sm" @click="exportAsJsonFile" block variant="light">
          {{ this.messages.results.exportResult }} <b-icon icon="download"></b-icon>
        </b-btn>
      </div>
      <div class="btn-print mb-2 text-right" v-if="!isOnDevice()">
        <b-btn size="sm" @click="print" block variant="light">
          {{ this.messages.global.print }} <b-icon icon="download"></b-icon>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Square from '@/components/ergebnisse/Square.vue'
import Indikator from '@/components/ergebnisse/Indikator.vue'
import mixins from '@/mixins'
import {
  CONTROL_STATE_FINISHED,
  CONTROL_TYPE_FLEXIBLE,
  CONTROL_TYPE_STATIC,
  STATUS_SYNC_KONTROLLE_FINISHED
} from '@/store/utils'

export default {
  name: 'Ergebnisse',
  mixins: [mixins],
  components: {
    Square,
    Indikator
  },
  data () {
    return {
      tabIndex: 0,
      compareMode: false
    }
  },
  mounted () {
    if (this.kontrolle) {
      this.tabIndex = (this.kontrolle.hauptkontrolle_id) ? 1 : 0
    }
  },
  watch: {
    kontrolle (newValue, oldValue) {
      this.tabIndex = (newValue.hauptkontrolle_id) ? 1 : 0
    }
  },
  methods: {
    isStaticKontrolle () {
      return this.kontrolle.typ === CONTROL_TYPE_STATIC
    },
    /**
     * Switch between controls, index "0 => Hauptkontrolle" or "1 => Nachkontrolle"
     * @param index
     */
    switchTo (index) {
      if (index === this.tabIndex) {
        return false
      }

      this.tabIndex = index
      const kontrolle = (this.tabIndex === 0 && this.getHauptkontrolle) ? this.getHauptkontrolle : this.getNachkontrolle
      this.$store.commit('api/SET_SELECTED_KONTROLLE_ID', kontrolle.id)
    },
    /**
     * Export json as file & download
     */
    exportAsJsonFile () {
      let jsonStr = JSON.stringify(this.antworten, null, 2)
      console.warn('EXPORT_AS_JSON: ', jsonStr)

      let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonStr)
      let fileName = `kontrolle-${this.kontrolle.id.substring(0, 4)}.json`

      let linkElement = document.createElement('a')
      linkElement.setAttribute('href', dataUri)
      linkElement.setAttribute('download', fileName)
      linkElement.click()
    },
    send () {
      this.sendDataToServer(
        {
          kontrolle: this.kontrolle
        },
        STATUS_SYNC_KONTROLLE_FINISHED
      )
    },
    next () {
      if (this.isDesktopMode) {
        this.$router.push('/produktionssystem')
      } else {
        this.$router.push('/ursachenanalyse')
      }
    }
  },
  computed: {
    /**
     * Map base state
     */
    ...mapState('api', [
      'profile'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'isAuthenticated'
    ]),
    showSyncBtn () {
      return this.isAuthenticated &&
             this.kontrolle &&
             this.kontrolle.status === CONTROL_STATE_FINISHED &&
             this.kontrolle.benutzer_synchronisiert_am === null
    },
    /**
     * Is control typ = CONTROL_TYPE_FLEXIBLE => "Eigenkontrolle mit Ursachenanalyse"
     * @return {boolean}
     */
    isControlTypFlexible () {
      return (this.kontrolle && this.kontrolle.typ === CONTROL_TYPE_FLEXIBLE)
    },
    /**
     * Show export button depending on "isDebugMode" & kontrolle attribute "status" = "finished"
     * @return {boolean}
     */
    showExportBtn () {
      return this.isDebugMode &&
             this.kontrolle &&
             this.kontrolle.status === CONTROL_STATE_FINISHED
    },
    /**
     * Get "Nachkontrolle" of selected kontrolle
     * @return {Item<InstanceOf<Kontrolle>>}
     */
    getNachkontrolle () {
      if (!this.kontrolle) {
        return null
      }

      return Kontrolle.query()
        .where('hauptkontrolle_id', this.kontrolle.id)
        .first()
    },
    /**
     * Get "Hauptkontrolle" if selected kontrolle "hauptkontrolle_id" is set
     * @return {Item<InstanceOf<Kontrolle>>}
     */
    getHauptkontrolle () {
      if (!this.kontrolle || this.kontrolle.hauptkontrolle_id === null) {
        return null
      }

      return Kontrolle.query()
        .whereId(this.kontrolle.hauptkontrolle_id)
        .first()
    },
    /**
     * Show nav if selected "Hauptkontrolle" has an "Nachkontrolle"
     * @return {boolean}
     */
    showNav () {
      const hasNachkontrolle = (this.getNachkontrolle instanceof Kontrolle && this.getNachkontrolle.status === CONTROL_STATE_FINISHED)
      return ((this.kontrolle && this.kontrolle.hauptkontrolle_id !== null) || hasNachkontrolle)
    },
    kontrolleErgebnisse () {
      if (this.getNachkontrolle && !this.isNachkontrolle(this.kontrolle) && this.compareMode) {
        const nachkontrolleIndikatorIds = this.getNachkontrolle.ergebnisse.map((indikatorData) => indikatorData.indikatorId)
        return this.kontrolle.ergebnisse.filter((indikatorData) => nachkontrolleIndikatorIds.includes(indikatorData.indikatorId))
      }

      return this.kontrolle.ergebnisse
    }

  }
}
</script>
